import Layout from "../layout/layout";
import { useEffect, useState } from "react";
import { commentOnBlog, retreiveSingleBlogs } from "../../Utils/api";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import SingleComment from "./SingleComment";
import toast from "react-hot-toast";
import linkedin from "../../assets/png/link.png";
import insta from "../../assets/png/insta.png";
import twitter from "../../assets/png/twitter.png";



const ViewPost = () => {
  const { token, loggedInUser } = useSelector((state) => state.user);
  const {search} = useLocation()
 const query = search.replace("?n=", "")
  const [blog, setBlog] = useState(null);
  const [commentText, setCommentText] = useState("");
  const [loadingBlog, setLoadingBlog] = useState(false);

  useEffect(() => {
    fetchData(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  async function fetchData(isNotInitial) {
    if (isNotInitial) setLoadingBlog(true);
    await retreiveSingleBlogs({
      blogid: query,
    })
      .then(({ data }) => {
        setLoadingBlog(false);
        setBlog(data.data);
      })
      .catch((err) => {
        setLoadingBlog(false);
      });
  }

  const commentPost = () => {
    if (!token) {
      toast.error("Login is required");
      return;
    }
    if (commentText.trim() === "") {
      return;
    }

    toast.loading("Sending Comment");
    commentOnBlog(
      {
        customerid: loggedInUser?.id || loggedInUser.customer?._id,
        blogid: query,
        comment: commentText,
      },
      token
    )
      .then(() => {
        toast.success("Comment Sent✅");
        setCommentText("");
      })
      .finally(() => {
        fetchData(false);
      });
  };

  const formatTime = (time) => {
    const newTime = new Date(time);

    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(newTime);
  };

  return (
    <Layout>
      {loadingBlog ? (
        <div className="h-80 flex items-center justify-center text-base">
          <div>Loading Blog...</div>
        </div>
      ) : blog ? (
        <div className="sm:w-11/12 w-full mx-auto">
            <div className="p-4 flex gap-10 items-center mt-6">
            <div className="flex gap-4 items-center">
              <div className="w-12 h-12 rounded-full bg-gray-200"></div>
              <div>
                <div className="text-sm font-semibold capitalize">
                  {blog?.blog?.sellerid?.name}
                </div>
                <div className="text-xs">
                  {blog?.blog?.createdAt && formatTime(blog?.blog?.createdAt)}
                </div>
              </div>
            </div>
          </div>
          <div className=" mx-auto w-full">
            {blog?.blog?.media?.map((media) =>
              media.mediatype === "image" ? (
                <img
                  src={media.url}
                  key={media._id}
                  alt=""
                  className="w-full h-[300px] sm:mt-4 mb-4 object-cover"
                />
              ) : (
                <video
                  src={media.url}
                  key={media._id}
                  autoPlay={false}
                  controls
                  className="w-full h-[300px] sm:mt-4  mb-4 object-cover"
                />
              )
            )}
          </div>
        

          <div className="px-4 my-6">
            <div>{blog?.blog?.content}</div>

            {/* SOCIALS */}
            <div className="hidden text-center my-8">
              <div className="text-2xl">Share this Posts</div>
              <div className="flex gap-4 mt-2 justify-center">
                <img src={linkedin} alt="" />
                <img src={insta} alt="" />
                <img src={twitter} alt="" />
              </div>
            </div>

            {/* ADD COMMENT */}
            <div className="my-10 sm:my-20">
              <textarea
                className="w-full  max-w-2xl border border-slate-600 rounded-lg outline-none h-60 resize-none p-2"
                placeholder="Your comment comes here"
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
              />
              <div className="flex justify-start mt-4 gap-4 items-center">
                <button
                  className="text-white bg-info-700 py-3 px-6 rounded-lg"
                  onClick={commentPost}
                  disabled={commentText.trim() === ""}
                >
                  Post Comment
                </button>
              </div>
            </div>

            {/* COMMENTS */}
            <div className="my-8 sm:my-10 max-w-xl">
              {blog?.comments?.map((comment) => (
                <SingleComment
                  fetchData={fetchData}
                  key={comment._id}
                  comment={comment}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="h-80 flex items-center justify-center md:text-4xl text-2xl">
          <div className="">Blog not found</div>
        </div>
      )}
    </Layout>
  );
};

export default ViewPost;
