import React, { useMemo, useState } from "react";
import { placeOrder, fundWallet } from "../../Utils/api";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { calculateDistance } from "../../Utils/formatNumber";

export default function ConfirmOrder({ address, paymentMethod }) {
  const { token, loggedInUser } = useSelector((state) => state.user);
  const { cartItems } = useSelector((state) => state.cart);
  const [loading, setLoading] = useState(false);
  const { overallPrice, deliveryPrice } = useSelector((state) => state.cart);

  const delivery = useMemo(() => {
    const firstCartItem = cartItems[0];
    const sellerLocation = {
      lat1: firstCartItem?.latitiude,
      lng1: firstCartItem?.longitude,
    };
    const buyerLocation = {
      lat2: address?.cordinate?.latitiude,
      lng2: address?.cordinate?.longitude,
    };
    const distance = calculateDistance(
      Number(sellerLocation.lat1),
      Number(sellerLocation.lng1),
      Number(buyerLocation.lat2),
      Number(buyerLocation.lng2)
    );

    return distance * Number(deliveryPrice);
  }, [address, cartItems, deliveryPrice]);

  // console.log(address);
  const orderProduct = async () => {
    setLoading(true);
    const formattedCart = cartItems?.map((item) => {
      const { count, ...restItem } = item;

      return {
        ...restItem,
        quantity: count,
      };
    });
    // overallPrice + delivery
    await placeOrder(
      {
        customerid: loggedInUser?.id || loggedInUser.customer._id,
        shippingaddressid: address._id,
        use_wallet: paymentMethod === "Wallet" ? true : false,
        price: parseInt(overallPrice + delivery),
        cart: formattedCart,
      },
      token
    )
      .then(() => {
        if (paymentMethod === "Wallet") {
          toast.success("Product Ordered Successfully");
        } else {
          fundMyWallet();
        }

        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const fundMyWallet = async () => {
    const payload = {
      customerid: loggedInUser?.id || loggedInUser.customer._id,
      email: loggedInUser?.email || loggedInUser?.customer?.email,
      usertype: "user",
      amount: parseInt(overallPrice + delivery),
      platformNumber: 1,
      cardpayment: true,
    };
    await fundWallet(payload, token)
      .then((res) => {
        //  console.log(res.data.data);
        if (!res?.data?.data) {
          toast.error("Couldn't initiate payment. Something went wrong.");
          return;
        }
        window.open(res.data.data, "_blank");
        // window.location.href = res.data.data;
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <div className="w-full border my-2 sm:my-4 border-gray-400 space-y-5 sm:space-y-6 rounded-lg p-4">
      <p className="font-semibold text-[15px] sm:text-base">
        Order Summary <span className="text-gray-500">(1)</span>
      </p>
      <div className="flex items-center justify-between w-full font-medium">
        <span>SubTotal</span>
        <span className="font-semibold text-[15px] sm:text-base">
          {` ₦${Number(overallPrice ?? 0)?.toLocaleString()}`}
        </span>
      </div>
      <div className="flex items-center justify-between w-full font-medium">
        <span>Delivery</span>
        <span className="font-semibold text-[15px] sm:text-base">
          {`₦${Number(delivery ?? 0)?.toLocaleString()}`}
        </span>
      </div>
      <div className="flex items-center justify-between w-full font-semibold">
        <span>Total</span>
        <span className="font-semibold text-[15px] sm:text-base">
          {`₦${Number(overallPrice + delivery ?? 0)?.toLocaleString()}`}
        </span>
      </div>

      <button
        onClick={() => {
          // navigate("/order-tracker");
          orderProduct();
        }}
        className="flex w-full items-center justify-center px-4 py-2 font-semibold rounded-3xl bg-[#b847fe] sm:py-3 sm:px-8 text-white space-x-2"
        disabled={loading || !address}
      >
        {loading ? "Loading" : "Pay Now"}
      </button>
    </div>
  );
}
