import { useSelector } from "react-redux";
import OrderWidget from "../orderWidget";
import { useEffect, useState } from "react";
import { retrieveWishlist } from "../../../Utils/api";
import { Loader2Icon } from "lucide-react";

export function SavedItems() {
  const { loggedInUser, token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchData() {
    if (!loggedInUser?.id || !loggedInUser.customer?._id) {
      setData([]);
      return;
    }
    setLoading(true);
    await retrieveWishlist(token, {
      customerid: loggedInUser?.id || loggedInUser.customer?._id,
    })
      .then((res) => {
        const { data } = res?.data;
        setData(data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      {loading && (
        <div className="w-full col-span-full h-[300px] flex items-center justify-center flex-col">
          <Loader2Icon className="animate-spin text-altinsmart" />
        </div>
      )}
      {!loading && data?.cart?.length === 0 && (
        <div className="w-full h-[300px] flex col-span-full items-center justify-center flex-col">
          <p className="text-red-500">No Data</p>
        </div>
      )}
      {!loading &&
        Array.isArray(data?.cart) &&
        data?.cart?.map((item) => (
          <OrderWidget
            key={item?._id}
            isSavedItem
            seller={item?.sellerid}
            data={item}
            wishlistid={data?._id}
            name={item?.productname}
            image={item?.image}
            price={item?.price}
            refetch={fetchData}
          />
        ))}
    </>
  );
}
