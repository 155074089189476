import React from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import OrderWidget from "./orderWidget";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import empty from "../../assets/png/alt_empty.jpg"
export default function Orders() {
  const { numOfCartItems, cartItems } = useSelector((state) => state.cart);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  
  function clearAll() {
    if (cartItems?.length === 0) {
      toast.error('No item to deleted')
      return
    }
    dispatch({ type: "CLEAR_ALL" });
  }
  return (
    <>
      <div className="w-full border my-2 sm:my-4  flex items-center justify-between rounded-lg p-2 sm:p-4">
        <div className="font-semibold">
          Carts <span className="text-gray-500">{`(${numOfCartItems})`}</span>
        </div>
        <button
        onClick={clearAll}
        className="flex items-center space-x-1 px-2 py-1 hover:bg-gray-200">
         <span>Clear All</span>
         <RiDeleteBin5Line className="text-[22px]" />
        </button>
      </div>
      {Array.isArray(cartItems) && cartItems.length > 0 && cartItems.map((item, idx) => {
     
        return (
          <div key={idx}>
            <OrderWidget
              image={item?.image}
              name={item?.productname}
              price={item?.subprice}
              seller={item?.sellerid}
              count={item?.count}
              productid={item?.productid}
              data={item}
            />
          </div>
        );
      })}
      {cartItems.length === 0 &&   <div className=" w-full border space-y-5 my-2 sm:my-4 shadow h-fit flex flex-col items-center justify-center rounded-lg p-2 sm:p-4">
           
           <div className="w-[200px] h-[200px] ">
            <img src={empty} alt="" className="w-full h-full object-cover"/>

           </div>
            <p>Your cart is empty</p>
            <button
            onClick={() => {
              navigate(-1)
            }}
             className="bg-[#b847fe] text-white font-medium px-6 sm:px-8 rounded-3xl py-2">Add Item to Cart</button>
        </div>}
    </>
  );
}

/**
   <OrderWidget
          image={images[0]?.image}
          name={name}
          price={price}
          count={count}
          data={item}
          />
 */
