import React, { useState } from "react";
import { useContext } from "react";
import { DetailContext } from "../productDetail";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { FavouriteButton } from "../../Composable/favouriteButton";
export default function ProductImageWidget() {
  const [active, setactive] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  //const products = [product, product, product, product];
  const { detaildata } = useContext(DetailContext);

  const imageContainerStyle = {
    width: `${detaildata?.images.length * 100}%`,
    transform: `translateX(-${
      (currentIndex / detaildata?.images.length) * 100
    }%)`,
    transition: "transform 0.5s ease-in-out",
  };

  const settings = {
    dots: true,
    infinite: false,
    autoplay: true,
    fade: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    //prevArrow: false,
    //nextArrow: false,
  };

  return (
    <>
      <div className="block sm:hidden">
        <Slider className="slider w-full h-[280px]" {...settings}>
          {detaildata?.images.map(({ url, mediatype }, idx) => {
            return (
              <div className={`w-full h-[280px] relative`} key={idx}>

                 <div className="absolute top-2 right-2">
                 <FavouriteButton product={detaildata} />
                 </div>
           
                {mediatype === "image" ? (
                  <img
                    src={url}
                    alt=""
                    className={`w-full h-full  object-cover `}
                  />
                ) : (
                  <video
                    src={url}
                    muted
                    controls
                    autoPlay
                    className={`w-full h-full  object-cover `}
                  />
                )}

                <div className="absolute text-xs font-light text-gray-100 right-[2px] bottom-[3px]">
                  {`${idx + 1}/${detaildata?.images?.length ?? 0}`}
                </div>
              </div>
            );
          })}
        </Slider>
      </div>

      <div className="w-full hidden sm:block h-[370px] sm:h-[500px] min-[900px]:col-span-3 space-y-2 sm:space-y-4">
        <div className="w-full h-[250px] sm:h-[350px]">
          {detaildata?.images.map(({ url, mediatype }, idx) => {
            return (
              <div
                className={`w-full h-[250px] sm:h-[350px] ${
                  active === idx ? "block" : "hidden"
                }`}
                key={idx}
              >
                {mediatype === "image" ? (
                  <img
                    src={url}
                    alt=""
                    className={`w-full h-full let swipeInLeft object-cover ${
                      active === idx ? "block" : "hidden"
                    }`}
                  />
                ) : (
                  <video
                    src={url}
                    muted
                    controls
                    autoPlay
                    className={`w-full h-full let swipeInLeft object-cover ${
                      active === idx ? "block" : "hidden"
                    }`}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="relative w-full h-[100px] overflow-hidden">
          <div
            className="w-full h-[100px] flex items-center gap-2 px-4 "
            style={imageContainerStyle}
          >
            {detaildata?.images.map(({ url, mediatype }, j) => {
              return (
                <div
                  onClick={() => {
                    setactive(j);
                  }}
                  key={j}
                  className="w-[100px] relative h-full"
                >
                  {mediatype === "image" ? (
                    <img
                      src={url}
                      alt=""
                      className={`w-full h-full object-cover`}
                    />
                  ) : (
                    <video
                      src={url}
                      muted
                      autoPlay
                      className={`w-full h-full object-cover`}
                    />
                  )}
                  {active !== j && (
                    <div className="absolute inset-0 w-full h-full bg-white bg-opacity-40"></div>
                  )}
                </div>
              );
            })}
          </div>
          <button
            onClick={() => {
              setCurrentIndex((currentIndex + 1) % detaildata?.images.length);
            }}
            className="absolute bg-gray-200 shadow-md rounded-full top-[45%] p-1 flex items-center justify-center right-[-2px] "
          >
            <MdNavigateNext className=" text-[22px]" />
          </button>
          <button
            onClick={() => {
              setCurrentIndex((currentIndex - 1) % detaildata?.images.length);
            }}
            className="absolute bg-gray-200 p-1 shadow-md top-[45%] rounded-full left-[-2px] flex items-center justify-center "
          >
            <MdNavigateBefore className=" text-[22px]" />
          </button>
        </div>
      </div>
    </>
  );
}
