import React, { useEffect, useState } from "react";
import { MdNavigateBefore } from "react-icons/md";

import DeliveryAddress from "./deliveryAddress";
// import DeliveryDetail from "./deliveryDetail";
import PaymentDetail from "./paymentDetail";
import { useNavigate } from "react-router-dom";
import ConfirmOrder from "./confirmOrder";
import Layout from "../layout/layout";
import { useSelector } from "react-redux";
import { retrieveDefaultAddress } from "../../Utils/api";

export default function CheckOut() {
  const navigate = useNavigate();
  const [loadingAddress, setloadingAddress] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Card")
  const [userDefaultAdress, setuserDefaultAdress] = useState(null);
  const { token, loggedInUser } = useSelector((state) => state.user);

  useEffect(() => {
    setloadingAddress(true);
    retrieveDefaultAddress(token, {
      customerid: loggedInUser?.id || loggedInUser?.customer?._id,
    })
      .then((res) => {
        const { data } = res.data;
        setloadingAddress(false);

        setuserDefaultAdress(data);
      })
      .catch((err) => {
        setloadingAddress(false);
      });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout>
        <div className="w-full mt-4 px-4 sm:px-6 space-y-3 sm:space-y-4">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="flex items-center text-[#b847fe] space-x-2"
          >
            <MdNavigateBefore className="text-[22px]" />
            <span>Back to cart</span>
          </button>

          <div className="w-full border my-2 sm:my-4 border-gray-400 flex items-center justify-between rounded-lg p-4">
            <div className="font-semibold">Place your order</div>
          </div>
          <div className="grid w-full gap-6 items-start grid-cols-1 sm:grid-cols-5">
            <div className="w-full space-y-3 sm:col-span-3">
              <DeliveryAddress
                address={userDefaultAdress}
                loading={loadingAddress}
              />
              {/* <DeliveryDetail /> */}
              <PaymentDetail paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />
            </div>
            <div className="w-full sm:col-span-2">
              <ConfirmOrder paymentMethod={paymentMethod} address={userDefaultAdress} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
