import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/png/smart.png";

export function CategoryWidget({ title, images, id }) {
  const navigate = useNavigate();

  useEffect(() => {
    function lazyImage() {
      const lazy = document.querySelectorAll("#cats");
      lazy.forEach((im) => {
        const newurl = im.getAttribute("src-data");
        im.src = newurl;

        im.addEventListener("error", () => {
          im.src = logo;
        });
      });
    }

    lazyImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button
      onClick={() => {
        navigate(`/category-detail?n=${id}`, {
          state: {
            isCategory: true,
          },
        });
      }}
      className="cursor-pointer relative overflow-hidden w-[150px] sm:w-[200px]  flex flex-col items-center justify-center h-[160px] sm:h-[180px] rounded-lg font-medium border border-gray-300 hover:border-gray-600"
    >
      {Array.isArray(images) && (
        <img
          src={images[0].categoryurl}
          alt=""
          className="cats w-full h-full object-cover"
        />
      )}
      {/**
       <div className="w-[80px] h-[80px] sm:w-[100px] sm:h-[100px]">
      
      </div>
       */}
       <div className="w-full absolute z-10 bg-black/40 inset-0 h-full">
       <h3 className="text-ellipsis capitalize text-white absolute left-3  bottom-3 flex items-center whitespace-nowrap overflow-hidden w-full text-center">
        {title}
      </h3>
       </div>

     
    </button>
  );
}
