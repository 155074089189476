import {Link, useLocation} from 'react-router-dom'
import React from "react";

function Navs({ text, link }) {
  const {pathname} = useLocation();
  return (
    <Link
      to={link}
      className={`py-2 px-1 font-medium text-sm sm:text-base hover:text-[#B847FE] hover:border-b border-[#B847FE] ${
        pathname === link ? "border-b text-[#B847FE]" : ""
      }`}
    >
      {text}
    </Link>
  );
}

export default Navs;
