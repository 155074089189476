import React, { useEffect, useState } from "react";
import { MdNavigateBefore } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import Orders from "./orders";
import Summation from "./summation";
import { addToCart, calculateTotal } from "../../Redux/Actions/ActionCreators";
import Layout from "../layout/layout";
import { SavedItems } from "./savedItems/SavedItem";
import ShareCart from "../Composable/share/Share";
import { useDispatch, useSelector } from "react-redux";
import { retrieveSharedCart } from "../../Utils/api";
import toast from "react-hot-toast";
import { Loader2Icon } from "lucide-react";

export default function Cart() {
  const navigate = useNavigate();
  const { cartItems, deliveryPrice } = useSelector((state) => state.cart);
  const [active, setActive] = useState(1);
  const [isShare, setisShare] = useState(false);
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const dispatch = useDispatch();
  const query = search.replace("?c=", "");

  useEffect(() => {
    (async () => {
      if (!query) return;
      setLoading(true);
      await retrieveSharedCart({ bookingcode: query })
        .then((res) => {
          const { data } = res?.data;

          let carts = data?.cart?.map((item) => {
            return {
              ...item,
              count: item?.quantity,
            };
          });
          carts?.forEach((item) => {
            dispatch(addToCart(item, cartItems, deliveryPrice));
            dispatch(calculateTotal(cartItems));
          });

          /**
         
       
        console.log({cart}, data)
        
         */
          //
        })
        .catch((err) => {
          /// console.log(err)
          toast.error(err.response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout>
        {loading ? (
          <div className="w-full h-[300px] flex items-center justify-center">
            <Loader2Icon size={32} className="animate-spin text-altinsmart" />
          </div>
        ) : (
          <div className="w-full mt-4 sm:mt-8 px-4 sm:px-6 sipace-y-5 sm:space-y-6">
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="flex items-center text-[#b847fe] space-x-1 sm:space-x-2"
            >
              <MdNavigateBefore className="text-[22px]" />
              <span>continue shopping</span>
            </button>
            <div className="w-full flex gap-x-6 justify-around sm:justify-start items-center">
              <button
                onClick={() => setActive(1)}
                className={` p-2 font-medium ${
                  active === 1
                    ? "border-b-2 text-altinsmart  border-altinsmart"
                    : ""
                }`}
              >
                Cart
              </button>
              <button
                onClick={() => setActive(2)}
                className={`py-2 px-1 font-medium ${
                  active === 2
                    ? "border-b-2 text-altinsmart  border-altinsmart"
                    : ""
                }`}
              >
                Saved Items
              </button>
            </div>

            {active === 1 && (
              <div className="w-full grid grid-cols-1 lg:grid-cols-5 items-start gap-6">
                <div className="w-full lg:col-span-3">
                  <Orders />
                </div>
                <div className="w-full flex flex-col items-center justify-center lg:col-span-2">
                  <Summation />
                  {cartItems?.length > 0 && (
                    <button
                      onClick={() => setisShare(!isShare)}
                      className="my-3 font-medium text-altinsmart hover:underline"
                    >
                      Generate and Share Cart Code
                    </button>
                  )}
                  <div className="w-full flex items-center justify-center">
                    <button
                      onClick={() => {
                        navigate("/checkout");
                      }}
                      className="px-10 font-semibold sm:px-20 w-[70%] text-center h-11 sm:h-12 rounded-3xl bg-[#b847fe] text-white"
                    >
                      Checkout
                    </button>
                  </div>
                </div>
              </div>
            )}
            {active === 2 && (
              <div className="w-full grid grid-cols-1 pb-6 lg:grid-cols-2 items-start gap-6">
                <SavedItems />
              </div>
            )}
          </div>
        )}

        {isShare && (
          <ShareCart
            isShare={isShare}
            setisShare={setisShare}
            cart={cartItems}
          />
        )}
      </Layout>
    </>
  );
}
