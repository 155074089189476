import React from "react";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineWallet, AiOutlineUser } from "react-icons/ai";
import { LuLayoutDashboard } from "react-icons/lu";
import { TbFileDatabase } from "react-icons/tb";

export default function AccountSideNav() {

  return (
    <div className="min-[900px]:block hidden w-[250px] py-4  h-fit">
      <div className="w-full h-56 mb-4 px-3 pb-4 border-b border-r justify-center flex flex-col space-y-2 items-center">
        <img
        alt="inf"
          src={`https://source.unsplash.com/1600x900/?smart,home,${Math.random()}`}
          className="w-full h-full object-cover"
        />
      </div>

      <div className="flex flex-col w-full border-r items-start justify-start">
        <NavWidget
          icon={<LuLayoutDashboard className="text-[22px]" />}
          text={"Dashboard"}
          link={"/user"}
        />
        <NavWidget
          icon={<TbFileDatabase className="text-[22px]" />}
          text={"Purchase History"}
          link={"/user-purchase-history"}
        />

        {/* <NavWidget
          icon={<RiRefund2Line className="text-[22px]" />}
          text={"Refund Request"}
          link={"/user/refund-request"}
        /> */}

        {/* <NavWidget
          icon={<BsShopWindow className="text-[22px]" />}
          text={"Followed Shops"}
          link={"/user/followed-shops"}
        /> */}

        {/* <NavWidget
          icon={<BiMessageDetail className="text-[22px]" />}
          text={"Product Queries"}
          link={"/user/my-queries"}
        /> */}

        {/* <NavWidget
          icon={<AiOutlineHeart className="text-[22px]" />}
          text={"WishList"}
          link={"/user/my-wishlist"}
        /> */}

        <NavWidget
          icon={<AiOutlineWallet className="text-[22px]" />}
          text={"My Wallet"}
          link={"/user-my-wallet"}
        />
        <NavWidget
          icon={<AiOutlineUser className="text-[22px]" />}
          text={"Manage Profile"}
          link={"/user-my-profile"}
        />
      </div>
    </div>
  );
}

function NavWidget({ icon, text, link }) {
  const { pathname } = useLocation();
  return (
    <Link
      to={link}
      className={`w-full p-3 flex items-center space-x-2 hover:bg-[#b847fe] hover:text-white ${
        pathname === link ? "bg-[#b847fe] text-white" : ""
      }`}
    >
      {icon}
      <p>{text}</p>
    </Link>
  );
}
