import { AiOutlineShoppingCart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { addToCart, calculateTotal } from "../../Redux/Actions/ActionCreators";

export function AddItemToCart({ product, isVariant }) {
  const dispatch = useDispatch();
  const { cartItems, deliveryPrice } = useSelector((state) => state.cart);

  function add() {
  /**
     const currentSeller = cartItems?.some(
      ({ sellerid }) => sellerid === product?.sellerid._id
    );
    if (cartItems?.length > 0 && !currentSeller) {
      toast.error(
        "You cannot add product from different brand to your cart. Proceed to empty your cart before you continue."
      );
      return;
    }
   */
    const payload = isVariant
      ? product
      : {
          variantid: "",
          variantpoduct: false,
          price: product?.price,
          isdiscount: product?.discount?.isdiscount,
          discountprice: product?.discount?.isdiscount
            ? parseFloat(product?.discount?.discount_price)
            : 0,
          delivery_fee: parseInt(product?.vehicle_fee),
          vehicle_fee: parseInt(product?.vehicle_fee),
          subprice:
            product?.discount?.isdiscount &&
            parseInt(product?.discount?.discount_price) > 0
              ? parseInt(product?.discount?.discount_price)
              : parseInt(product?.price),
          latitiude: product?.location?.latitiude,
          longitude: product?.location?.longitude,
          count: 1,
          quantity: 1,
          image: product?.images?.filter(
            (item) => item.mediatype === "image"
          )[0]?.url,
          productname: product?.name,
          productDescription: product?.description,
          delivery_vehicle: product?.delivery_vehicle,
          productid: product?._id,
          sellerid: product?.sellerid._id,
        };
    dispatch(addToCart(payload, cartItems, deliveryPrice));
    dispatch(calculateTotal(cartItems));
    toast.success("Item added to cart");
  }

  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        add();
      }}
      className={`  font-medium  flex items-center space-x-2 justify-center ${
        isVariant
          ? "w-fit bg-white rounded-3xl h-12 px-10 text-altinsmart border border-altinsmart"
          : "text-white bg-altinsmart w-full rounded-2xl py-2 px-6 sm:px-8 "
      }`}
    >
      <AiOutlineShoppingCart className="text-[22px]" />
      <span>Add to Cart</span>
    </button>
  );
}
