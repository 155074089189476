export const formatNumber = (number) => {
  const suffixes = ["", "k", "M", "B", "T"];
  const suffixNum = Math.floor(("" + number).length / 3);
  let shortValue = parseFloat(
    (suffixNum !== 0 ? number / Math.pow(1000, suffixNum) : number).toPrecision(
      2
    )
  );
  if (shortValue % 1 !== 0) {
    shortValue = shortValue.toFixed(1);
  }

  return shortValue + suffixes[suffixNum];
};


export function formatDate(dateString) {
  const originalDate = new Date(dateString);

const formatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: '2-digit'
});

const formattedDate = formatter.format(originalDate);

return formattedDate
}

export function timeElapsedSince(timestampStr) {
  const timestamp = new Date(timestampStr);
  const currentTime = new Date();
  const elapsedMilliseconds = currentTime.getTime() - timestamp.getTime();

  const millisecondsInSecond = 1000;
  const millisecondsInMinute = 60 * millisecondsInSecond;
  const millisecondsInHour = 60 * millisecondsInMinute;
  const millisecondsInDay = 24 * millisecondsInHour;

  const days = Math.floor(elapsedMilliseconds / millisecondsInDay);
  const hours = Math.floor((elapsedMilliseconds % millisecondsInDay) / millisecondsInHour);
  const minutes = Math.floor((elapsedMilliseconds % millisecondsInHour) / millisecondsInMinute);
  const seconds = Math.floor((elapsedMilliseconds % millisecondsInMinute) / millisecondsInSecond);

  if (days > 0) {
      return days === 1 ? `${days} day ago` : `${days} days ago`;
  } else if (hours > 0) {
      return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
  } else if (minutes > 0) {
      return minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`;
  } else {
      return seconds <= 1 ? "Just now" : `${seconds} seconds ago`;
  }
}


export function formatChatDate(originalTimestamp) {
  const dateObject = new Date(originalTimestamp);

  // Format the date
  const year = dateObject.getUTCFullYear();
  const month = (dateObject.getUTCMonth() + 1)
    .toString()
    .padStart(2, "0");
  const day = dateObject.getUTCDate().toString().padStart(2, "0");
  const hours = dateObject.getUTCHours().toString().padStart(2, "0");
  const minutes = dateObject
    .getUTCMinutes()
    .toString()
    .padStart(2, "0");
  const ampm = dateObject.getUTCHours() >= 12 ? "PM" : "AM";

  return {
    date: `${year}-${day}-${month}`,
    time: `${hours}:${minutes}${ampm}`,
  };
}



export async function uploadFile(file, type) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("cloud_name", "dkdrbjfdt");
  formData.append("upload_preset", "smartbest");
  type === "video" && formData.append("resource_type", "video");

  try {
    const response = await fetch(
      `https://api.cloudinary.com/v1_1/dkdrbjfdt/${type}/upload`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (response.ok) {
      const data = await response.json();

      return data.secure_url;
    } else {
      console.error("Failed to upload image");
      return null;
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    return null;
  }
}


export function calculateDistance(lat1, lon1, lat2, lon2) {
  const earthRadiusKm = 6371;

  const dLat = degreesToRadians(lat2 - lat1);
  const dLon = degreesToRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degreesToRadians(lat1)) * Math.cos(degreesToRadians(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadiusKm * c;

  return distance;
}

function degreesToRadians(degrees) {
  return degrees * Math.PI / 180;
}
