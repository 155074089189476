import React from "react";

export default function CatSkeleton() {
    return (
        <div className="animate-pulse cursor-pointer w-full sm:w-[200px] space-y-4 sm:space-y-5 flex flex-col items-center justify-center h-[160px] sm:h-[180px] rounded-lg font-semibold ">
              <div className=" rounded-md bg-gray-200 w-full h-full">

              </div>

        </div>
    )
}