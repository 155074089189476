import { IoIosHeart } from "react-icons/io";
import { reactToReply } from "../../Utils/api";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const SingleReply = ({ reply }) => {
  const { token, loggedInUser } = useSelector((state) => state.user);

  const likeReply = () => {
    if (!token) {
      toast.error("Login is required");
      return
    }
    toast.loading("Liking Reply");
    reactToReply(
      {
        customerid: loggedInUser?.id || loggedInUser.customer?._id,
        replyid: reply._id,
      },
      token
    ).then(() => {
      toast.success("Reply Liked");
    });
  };

  return (
    <div className="flex justify-end my-2">
      <div className="bg-slate-100 p-2 rounded-md w-4/5">
        <div className="flex gap-4 items-center">
          <div className="w-12 h-12 rounded-full bg-gray-200">
            <img
              alt=""
              src={reply?.customerid?.photo}
              className="w-12 h-12 rounded-full"
            />
          </div>

          <div>
            <div className="text-sm font-semibold capitalize">
              {reply?.customerid?.name}
            </div>
            <div className="hidden text-xs">23mins ago</div>
          </div>
        </div>

        <div className="pl-[3.8rem]">{reply?.reply}</div>

        <div className="pl-[3.8rem] flex gap-6 my-3 items-center">
          <div
            className="text-sm flex gap-2 items-center cursor-pointer"
            onClick={likeReply}
          >
            <IoIosHeart className="text-red-700" />
            <div className="font-semibold">{reply?.likes?.length} Like</div>
          </div>

          {/* <div className="font-semibold">{reply?.replies?.length} Replies</div> */}
          {/* <div className="text-sm flex gap-2 items-center">
            <MdOutlineReply />
            <div>Reply</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SingleReply;
