import React from "react";
import { useContext } from "react";
import { DetailContext } from "../productDetail";

export default function ProductGallery() {
  const { detaildata } = useContext(DetailContext);
  return (
    <div className="  w-full space-y-3 sm:space-y-5 my-2 sm:my-4">
      <h2 className="px-4 sm:px-6 font-semibold text-base sm:text-xl">Product Gallery</h2>
      <div className="sm:px-6 w-full  flex flex-col gap-y-6 sm:gap-y-10 items-center mx-auto">
        {Array.isArray(detaildata?.gallerydescription) &&
          detaildata?.gallerydescription?.map(({ url }, index) => (
            <div key={index} className="w-full h-[300px] sm:h-[500px]">
              <img src={url} alt="" className="w-full h-full object-cover" />
            </div>
          ))}
      </div>
    </div>
  );
}
