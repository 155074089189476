import React, { useState } from "react";
import cart2 from "../../assets/png/bi_cart1.png";
import cart1 from "../../assets/png/bi_cart.png";
import notify from "../../assets/png/notify.png";
import { newsArrival } from "../../Utils/api";
import toast, { LoaderIcon } from "react-hot-toast";
export function EmailNotification() {
  const [loading, setloading] = useState(false);
  const [email, setEmail] = useState("");

  async function getNotified() {
    if (!email) return
    setloading(true);
    await newsArrival({email})
      .then((res) => {
        toast.success(res?.data?.message);
        setloading(false);
        setEmail("")
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setloading(false);
        setEmail("")
      });
  }
  return (
    <div className="w-full h-fit px-4 sm:px-6 my-2 sm:my-4">
      <div className="bg-[#ECF1F4] overflow-hidden relative rounded-sm px-6 py-10 text-gray-500 sm:px-8 sm:py-20 flex justify-between md:justify-center items-center w-full">
        <img src={cart1} alt="" className="absolute right-0 top-0" />
        <img src={cart2} className="absolute left-0 top-0" alt="" />
        <div className="flex flex-col space-y-8 w-full  md:w-[450px]">
          <h1 className="font-semibold text-2xl sm:text-3xl w-full flex flex-wrap justify-center md:justify-start">
            Get Notified about New Arrivals
          </h1>
          <div className="w-full relative h-10 sm:h-12">
            <input
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className="rounded-3xl outline-none w-full h-full pl-4 pr-20 bg-white border"
            />
            <button
            onClick={getNotified}
              disabled={loading}
              className="absolute inset-y-0 right-0 text-white h-full px-3 flex items-center gap-x-1 justify-center rounded-3xl bg-[#B847EF]"
            >
              {loading && <LoaderIcon className="animate-spin" />} Submit
            </button>
          </div>
        </div>
        <img className="hidden md:block" src={notify} alt="" />
      </div>
    </div>
  );
}
