import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bg from "../../../assets/svg/bg.svg";
import { ImageWidget } from "./widget";
import { retrieveAllAdvert } from "../../../Utils/api";
import { Link } from "react-router-dom";
export function Hero() {
  const [images, setImages] = useState([]);
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    fade: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    //prevArrow: false,
    //nextArrow: false,
  };

  useEffect(() => {
    (async () => {
      await retrieveAllAdvert()
        .then((res) => {
          const { data } = res?.data;
          setImages(data);
        })
        .catch((err) => {});
    })();
  }, []);

  return (
    <div className="w-full relative h-[300px] sm:h-[550px]">
      <Slider
        className="slider w-full z-[4] h-[300px] sm:h-[550px]"
        {...settings}
      >
        {images?.map(({ image, contenttype, contentid }, index) => {
          return (
            <Link
              to={
                contenttype === "seller"
                  ? `/brands-detail?n=${contentid}`
                  : `/detail-detail?n=${contentid}`
              }
              key={index}
              className="relative h-[300px]  sm:h-[550px] w-full"
            >
              <div className="w-full absolute z-10 bg-black/10 inset-0 h-full"></div>
              <ImageWidget image={image} className="" />
            </Link>
          );
        })}
      </Slider>
      <div className="absolute inset-0 w-full h-full z-[2]">
        <img src={bg} alt="" className="w-full h-full object-cover" />
      </div>
    </div>
  );
}
