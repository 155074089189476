import React from "react";
import { VscAccount } from "react-icons/vsc";
import { BiMessageRoundedDetail, BiLogOutCircle } from "react-icons/bi";
import {
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { LuPackage2 } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
export default function AccountDropdown({ close }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function logOut() {
    dispatch({ type: "LOGOUT" });
    navigate("/");
    close();
  }
  return (
    <div className=" absolute right-[6px]  top-[34px] ">
      <div onClick={close} className="fixed inset-0 w-full z-[30] h-full"></div>
      <div className="rounded-md shadow-lg list swipeUp  py-4 px-3 h-fit relative z-[50] bg-white w-[250px] ">
        <div className="flex flex-col justify-start items-start space-y-2">
          <Widgets
            icon={<VscAccount className="text-[22px]" />}
            text={"My Account"}
            link={"/user"}
            close={close}
          />
          <Widgets
            icon={<LuPackage2 className="text-[22px]" />}
            text={"Orders"}
            link={"/user-purchase-history"}
            close={close}
          />
          {/* <Widgets
            icon={<CiLocationOn className="text-[22px]" />}
            text={"Track Order"}
            link={"/order-tracker"}
            close={close}

          />
           <Widgets
            icon={<LiaAddressBookSolid className="text-[22px]" />}
            text={"Cards & Address"}
            link={"/"}
            close={close}
          />
          */}
          <Widgets
            icon={<BiMessageRoundedDetail className="text-[22px]" />}
            text={"Message"}
            link={"/messaging"}
            close={close}
          />
          <Widgets
            icon={<AiOutlineShoppingCart className="text-[22px]" />}
            text={"Shopping Cart"}
            link={"/cart"}
            close={close}
          />
       
         
        
          <div
            onClick={logOut}
            className="flex p-2 hover:bg-[#b847fe] hover:font-medium hover:text-white items-center space-x-2 w-full"
          >
            <BiLogOutCircle className="text-[22px]" />
            <p className="">{"Log Out"}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function Widgets({ icon, text, link, close }) {
  return (
    <Link
      to={link}
      onClick={close}
      className="flex p-2 hover:bg-[#b847fe] hover:font-medium hover:text-white items-center space-x-2 w-full"
    >
      {icon}
      <p className="">{text}</p>
    </Link>
  );
}
