import React, { useEffect, useMemo } from "react";
import { Hero } from "./hero/hero";
import { GroupWidget } from "../groupwidget/groupWidget";
import { EmailNotification } from "../emailnotification/emailNotification";
import { FeaturedShop } from "../featuredshops/featuredShops";

import Layout from "../layout/layout";
import {
  getTopCategories,
  todaysDeal,
  retrieveViewed,
  altinsmartProduct,
  allOffers,
  featuredShops,
} from "../../Utils/api";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export function Landing() {
  const [data, setData] = useState({
    categories: [],
    todayDeals: [],
    recentlyViewed: [],
    altinsmart: [],
    featured: [],
  });
  const [offers, setOffers] = useState([]);
  const { token, loggedInUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [shuffledOffers, setShuffledOffers] = useState([]);

  // altinsmartProduct
  useEffect(() => {
    async function fetchData() {
      try {
        const [topCat, today, altinsmart, featured] = await Promise.all([
          getTopCategories(),
          todaysDeal(),
          altinsmartProduct(),
          featuredShops(),
        ]);

        setData({
          ...data,
          categories: topCat?.data?.data,
          todayDeals: today?.data?.data,
          altinsmart: altinsmart?.data?.data,
          featured: featured?.data?.data,
        });
      } catch (err) {
        // console.log(err);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (!token) return;
      await retrieveViewed({
        customerid: loggedInUser?.id || loggedInUser.customer?._id,
      })
        .then((res) => {
          const { data } = res?.data;
          setData({ ...data, recentlyViewed: data });
        })
        .catch((err) => {
          //console.log(err)
        });
    })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    (async () => {
      await allOffers()
        .then((res) => {
          const { data } = res?.data;

          const filtered = data?.filter((item) => {
            return (
              item?.offerInuse ||
              item?.title === "viewhistory" ||
              item?.title === "reorder"
            );
          });
          setOffers(filtered);
        })
        .catch((err) => {
          // console.log(err)
        });
    })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const offersList = useMemo(() => {
    return offers?.map(({ title }) => title);
  }, [offers]);

  // the first offer
  useEffect(() => {
    // const indexZeroOffer = shuffledOffers[0]?.title;
    // setFirstOffer(indexZeroOffer);

    let interval = setInterval(() => {
      if (Array.isArray(offers)) {
        setActive((currentIndex) => (currentIndex + 1) % offers.length);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [offers, active]);

  useEffect(() => {
    if (active >= 0) {
      console.log("active", active);
      const productToMove = offers[active];
      const updatedProducts = [
        productToMove,
        ...offers.slice(0, active),
        ...offers.slice(active + 1),
      ];
      setShuffledOffers(updatedProducts);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, offers]);

  return (
    <div>
      <Layout>
        {/* <SubTopNav /> */}
        <Hero />
        <GroupWidget
          heading={"Top Categories"}
          type="categories"
          data={data?.categories}
        />
     

        {offersList.length > 0 && (
          <div className="w-full overflow-x-auto no-scrollbar">
            <div className="w-full my-2 sm:my-4 mx-2 sm:mx-4 flex items-center gap-x-2 sm:gap-x-6 min-w-[1200px]">
              {offersList.map((value, index) => (
                <button
                  onClick={() => setActive(index)}
                  key={value}
                  className={`w-fit  text-xs sm:text-sm capitalize px-2 sm:px-4 shadow border rounded-md h-9 sm:h-10 ${
                    active === index
                      ? "bg-altinsmart text-gray-50"
                      : "bg-gray-50"
                  }`}
                >
                  {value}
                </button>
              ))}
            </div>
          </div>
        )}

        {Array.isArray(shuffledOffers) &&
          shuffledOffers?.map((item) => {
            if (item?.offertype === "advert") {
              return (
                <div className="w-full px-2 sm:px-6">
                  <div
                    role="button"
                    onClick={() => {
                      if (item?.product?.length > 0) {
                        navigate(`/advert?=${item?.title}`, {
                          state: {
                            advert: item,
                          },
                        });
                      }
                    }}
                    key={item?._id}
                    className="w-full  h-[190px] sm:h-[250px] rounded-md overflow-hidden md:h-[400px]  my-2 sm:my-4"
                  >
                    <img
                      src={item?.banner}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              );
            } else if (item?.offertype === "product") {
              if (item?.product?.length > 0) {
                return (
                  <GroupWidget
                    data={item?.product}
                    heading={item?.title}
                    type={"recentlyViewed"}
                     more={true}
                  />
                );
              }
            } else if (item?.offertype === "seller") {
              return <FeaturedShop data={item?.seller} title={item?.title} />;
            } else if (item?.title === "viewhistory") {
              if (
                Array.isArray(item?.viewhistory) &&
                item?.viewhistory?.length > 0
              ) {
                return (
                  <GroupWidget
                    data={item?.product}
                    heading={item?.viewhistory}
                    type={"recentlyViewed"}
                     more={true}
                  />
                );
              }
            } else if (item?.title === "reorder") {
              if (
                Array.isArray(item?.reorderhistory) &&
                item?.reorderhistory?.length > 0
              ) {
                return (
                  <GroupWidget
                    data={item?.reorderhistory}
                    heading={item?.title}
                    type={"recentlyViewed"}
                     more={true}
                  />
                );
              }
            }
            return null;
          })}

<GroupWidget
          heading={"Today's Deals"}
          type="deals"
          data={data?.todayDeals}
        />

        <EmailNotification />

        <FeaturedShop data={data?.featured} title="Featured Shops" />
        {/* <BrandsAndCollections /> */}

        {/**
         <GroupWidget
          data={data?.flash}
          heading={"Flash Sales"}
          type={"flashsales"}
          more={true}
        />
       */}
        {token && (
          <GroupWidget
            heading={"Recently Viewed"}
            type="recentlyViewed"
            data={data?.recentlyViewed}
            more={true}
          />
        )}
        <GroupWidget
          heading={"Altinsmart Products"}
          type="deals"
          data={data?.altinsmart}
        />
      </Layout>
    </div>
  );
}
