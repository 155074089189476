// Action Creators
import * as type from "./Types";
import axios from "../../Utils/useAxios";
import toast from "react-hot-toast";

const GetUsersSuccess = (data) => {
  return {
    type: type.FETCH_USER_SUCCESS,
    payload: data,
  };
};

const loginSuccess = (data) => {
  return {
    type: type.LOGIN_SUCCESS,
    payload: data,
  };
};
const logout = () => {
  return {
    type: type.LOGOUT,
  };
};

const addToCart = (payload, cartItems, deliveryPrice) => {
  return (dispatch) => {
  
    let delivery;
    const idIsPresent = cartItems
      .map((value) => value.productid)
      .includes(payload.productid);

    let isVariantId;
    if (payload?.variantpoduct) {
      isVariantId = cartItems
        .map((value) => value.variantid)
        .includes(payload.variantid);
    }

    if (idIsPresent && !payload?.variantpoduct) {
      cartItems.map((item) => {
        if (item.productid === payload.productid) {
          item.count = parseInt(item.count + payload.count);
          delivery = deliveryPrice + Number(item?.delivery_fee);

          item.subprice = parseInt(item.subprice + payload.subprice);
        }
        return item;
      });
    } else if (payload?.variantpoduct && isVariantId) {
      cartItems.map((item) => {
        if (item.variantid === payload.variantid) {
          item.count = parseInt(item.count + payload.count);
          delivery = deliveryPrice + Number(item?.delivery_fee);

          item.subprice = parseInt(item.subprice + payload.subprice);
        }
        return item;
      });
    } else {
      cartItems.push(payload);
      delivery = Number(payload?.delivery_fee) + deliveryPrice;
    }

    dispatch(updateCart(cartItems));
    dispatch(updateDeliveryFee(delivery));
  };
};
const updateCart = (data) => {
  return {
    type: type.ADD_TO_CART,
    payload: data,
  };
};

const removeFromCart = (id, cartItems, deliveryPrice, vId) => {
  return (dispatch) => {
    let filterCart = [];
    if (vId) {
      filterCart = cartItems.filter((item) => item.variantid !== vId);
    } else {
      filterCart = cartItems.filter((item) => item.productid !== id);
    }

    dispatch(updateCart(filterCart));
    let total = filterCart.map((value) => value.subprice);
    let nums = total.reduce((acc, item) => acc + parseFloat(item || 0), 0);
    let delivery;
    if (vId) {
      delivery = cartItems.find((item) => item?.variantid === vId)?.delivery_fee;
    } else {
      delivery = cartItems.find((item) => item?.productid === id)?.delivery_fee;
    }

    delivery = deliveryPrice - delivery;
    dispatch(updateDeliveryFee(delivery));
    dispatch(updateTotal(nums));
    let numItems = filterCart.map((value) => value.count);
    let totalCount = numItems.reduce((acc, item) => acc + item, 0);
    dispatch(getNums(totalCount));
    toast.success("Item removed successfully");
  };
};
const updateTotal = (data) => {
  return {
    type: type.UPDATE_TOTAL_PRICE,
    payload: data,
  };
};
const getNums = (data) => {
  return {
    type: type.NUM_OF_ITEMS,
    payload: data,
  };
};

const updateDeliveryFee = (data) => {
  return {
    type: type.UPDATE_DELIVERY_PRICE,
    payload: data,
  };
};

const calculateTotal = (cartItems) => {
  return (dispatch) => {
    let total = cartItems.map((value) => value.subprice);
    let nums = total.reduce((acc, item) => acc + parseFloat(item || 0), 0);

    dispatch(updateTotal(nums));
    let numItems = cartItems.map((value) => value.count);
    let totalCount = numItems.reduce((acc, item) => acc + item, 0);
    dispatch(getNums(totalCount));
  };
};

const increaseSingleCartItems = (id, cartItems, vId) => {
  return (dispatch) => {
    const carts = cartItems.map((item) => {
      if (item.productid === id && !vId) {
        item.count += 1;
        if (item.isdiscount && Number(item?.discountprice) > 0) {
          console.log("here");
          item.subprice = parseInt(item.subprice + item.discountprice);
        } else {
          item.subprice = parseInt(item.subprice + item.price);
        }
      } else if (item.variantid === vId) {
        item.count += 1;
        if (item.isdiscount && Number(item?.discountprice) > 0) {
          console.log("here");
          item.subprice = parseInt(item.subprice + item.discountprice);
        } else {
          item.subprice = parseInt(item.subprice + item.price);
        }
      }
      return item;
    });
    dispatch(updateCart(carts));
    let total = cartItems.map((value) => value.subprice);
    let nums = total.reduce((acc, item) => acc + parseFloat(item || 0), 0);

    dispatch(updateTotal(nums));
    let numItems = cartItems.map((value) => value.count);
    let totalCount = numItems.reduce((acc, item) => acc + item, 0);
    dispatch(getNums(totalCount));
  };
};

const decreaseSingleCartItems = (id, cartItems, vId) => {
  return (dispatch) => {
    const carts = cartItems.map((item) => {
      if (item.productid === id && item.count > 1 && !vId) {
        item.count -= 1;
        if (item.isdiscount && Number(item?.discountprice) > 0) {
          item.subprice = parseInt(item.subprice - item.discountprice);
        } else {
          item.subprice = parseInt(item.subprice - item.price);
        }
      } else if (vId && item.count > 1) {
        item.count -= 1;
        if (item.isdiscount && Number(item?.discountprice) > 0) {
          item.subprice = parseInt(item.subprice - item.discountprice);
        } else {
          item.subprice = parseInt(item.subprice - item.price);
        }
      }
      return item;
    });

    dispatch(updateCart(carts));
    let total = cartItems.map((value) => value.subprice);
    let nums = total.reduce((acc, item) => acc + parseFloat(item || 0), 0);

    dispatch(updateTotal(nums));
    let numItems = cartItems.map((value) => value.count);
    let totalCount = numItems.reduce((acc, item) => acc + item, 0);
    dispatch(getNums(totalCount));
  };
};

const LoginAction = (loginParams, navigate, setLoading) => {
  return async (dispatch) => {
    setLoading(true);
    await axios
      .post("/customer/login", loginParams)
      .then(async (res) => {
        //console.log(res.data);

        const { token } = res.data.data;
        console.log({ data: res.data.data, res });
        dispatch(GetUsersSuccess(res.data.data));
        dispatch(loginSuccess(token));
        navigate("/");

        //console.log(token);

        setLoading(false);
        toast.success("Login successful");
      })
      .catch((error) => {
        setLoading(false);
        //console.log(error);
        if (
          error.message === "Network Error" ||
          error.message === "timeout exceeded"
        ) {
          toast.error("Network Error");
        }
        const { error: err } = error.response.data;
        if (err) {
          toast.error(err);
        }

        const { message } = error.response.data.error;
        if (message) {
          toast.error(message);
        }
        const { message: mm } = error.response.data.response;
        if (mm) {
          toast.error(mm);
        }
      });
  };
};

const registration = (registrationParams, navigate, setLoading) => {
  return async (dispatch) => {
    setLoading(true);
    await axios
      .post("/customer/signup", registrationParams, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res.data);

        dispatch(GetUsersSuccess(res.data.data));
        dispatch(loginSuccess(res.data.data.token));
        toast.success(
          "Registration Successful. A verification code has been sent to your mail"
        );
        navigate("/verification");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (
          error.message === "Network Error" ||
          error.message === "timeout exceeded"
        ) {
          toast.error("Network Error");
        }
        //console.log(error.response.data);

        const { error: err } = error.response.data;
        if (err) {
          toast.error(Object.values(err));
        }
        const { message } = error.response.data.error;
        if (message) {
          toast.error(message);
        }
        const { message: mm } = error.response.data.response;
        if (mm) {
          toast.error(mm);
        }

        ////console.log(error.response.data.error.message);
      });
  };
};

export {
  LoginAction,
  addToCart,
  removeFromCart,
  registration,
  loginSuccess,
  GetUsersSuccess,
  logout,
  calculateTotal,
  decreaseSingleCartItems,
  increaseSingleCartItems,
};
