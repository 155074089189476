import { useEffect, useState, useMemo } from "react";
import io from "socket.io-client";
import { HiTicket } from "react-icons/hi2";
import { Loader2Icon } from "lucide-react";

const socket = io("https://altinsmarttest-ee96b8e4b316.herokuapp.com");

export function ChatList({
  islist,
  setList,
  toggleSideBar,
  setId,
  setUserName,
  currentChatId,
  fetchData,
  data,
  loading,
}) {
  const [active, setActive] = useState("activeTicket");

  useEffect(() => {
    fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const formattedData = useMemo(() => {
    if (active === "activeTicket") {
      return data.filter((item) => item.ticketstatus);
    } else {
      return data.filter((item) => !item.ticketstatus);
    }
  }, [data, active]);

  socket.on("connect", () => {
    console.log("Connected to server");
  });

  socket.on("disconnect", () => {
    console.log("Disconnected from server");
  });

  /**
 * 
   useEffect(() => {
    // Listen for messages from dispatch
    socket.on("receieve_dispatch_support", (message) => {
      console.log({ message });
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    // Listen for messages from user
    socket.on("receieve_user_support", (message) => {
      console.log({ message });
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    // Clean up listeners when the component is unmounted
    return () => {
      socket.off("receieve_dispatch_support");
      socket.off("receieve_user_support");
    };
  }, []);
 */

  return (
    <div
      className={` max-[880px]:w-full h-full w-[40%]  rounded-lg bg-white py-2 sm:py-4 ${
        islist ? "max-[880px]:hidden" : "block"
      }`}
    >
      <div className="flex w-full mb-3  px-2 sm:px-4  justify-between items-center">
        <div className="flex  items-center gap-x-2">
          <div className="flex items-center gap-x-2">
            <button
              onClick={() => setActive("activeTicket")}
              className={`border px-4 border-altinsmart rounded-md h-8 sm:h-10 text-xs sm:text-sm  ${
                active === "activeTicket" ? "bg-altinsmart/50" : "bg-gray-200"
              }`}
            >
              <p>Active</p>
            </button>
            <button
              onClick={() => setActive("closedTicket")}
              className={`border px-4 border-altinsmart rounded-md h-8 sm:h-10 text-xs sm:text-sm  ${
                active === "closedTicket" ? "bg-altinsmart/50" : "bg-gray-200"
              }`}
            >
              <p>Closed</p>
            </button>
          </div>
        </div>
      </div>

      <h2 className="font-semibold px-4 sm:px-6 mb-3 text-base sm:text-xl">
        Tickets
      </h2>
      {loading && (
        <div className="flex  items-center col-span-full justify-center w-full h-[250px]">
          <Loader2Icon size={48} className=" animate-spin text-gray-600" />
        </div>
      )}
      {!loading && formattedData?.length === 0 && (
         <div className="flex col-span-full  items-center justify-center w-full flex-col h-[300px]">
         <p className="text-base sm:text-lg font-semibold">No Ticket Yet</p>
         <p className="text-xs sm:text-mobile">You have not created any ticket yet</p>
       </div>
      )}
      {!loading &&
        Array.isArray(formattedData) &&
        formattedData?.map(({ request, _id }, idx) => {
          return (
            <div
              role="button"
              onClick={() => {
                setId(_id);
                setList(true);
                setUserName(request);
              }}
              className={`w-full cursor-pointer  px-2 sm:px-4  ${
                currentChatId === _id
                  ? "bg-altinsmart hover:bg-altinsmart hover:bg-opacity-30 bg-opacity-30"
                  : "hover:bg-gray-100"
              }`}
              key={_id}
            >
              <ChatlistWidget request={request} />
            </div>
          );
        })}
    </div>
  );
}

function ChatlistWidget({ request }) {
  return (
    <div className="flex w-full items-start py-2 border-b justify-between">
      <div className=" flex space-x-2 items-center justify-center">
        <HiTicket className="text-4xl text-altinsmart" />
        <div className="">
          <p className="font-semibold">{request}</p>
        </div>
      </div>
    </div>
  );
}
