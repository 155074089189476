import React, { useEffect, useState } from "react";
import { FeaturedWidget } from "./widget/featuredWiget";
import { useSelector } from "react-redux";
import { retrieveFollowed } from "../../Utils/api";
export function FeaturedShop({ data, title }) {
  const [followedStores, setFollowedStores] = useState([]);
  const { token, loggedInUser } = useSelector((state) => state.user);
 
  useEffect(() => {
    (async () => {
      if (!token) return;
      const payload = {
        customerid: loggedInUser?.id || loggedInUser?.customer?._id,
      };
      await retrieveFollowed(token, payload)
        .then((res) => {
          //console.log(res);
          const { data } = res?.data;

          setFollowedStores(data);
        })
        .catch((err) => {
          //console.log(err);
        });
    })();

  }, [token, loggedInUser]);

  return (
    <div className="w-full  px-4 sm:px-6 my-2 sm:my-4 space-y-3 sm:space-y-5">
      <h2 className="text-zinc-800 capitalize font-semibold text-lg sm:text-xl">
        {title}
      </h2>
      <div className="w-full scroll-style overflow-x-auto  items-center">
        <div className="min-w-max py-3 flex items-center flex-row gap-4 sm:gap-6">
          {Array.isArray(data) &&
            data?.map(({ logo, name, biography, _id }, j) => {
              return (
                <div key={_id}>
                  <FeaturedWidget
                    logo={logo}
                    name={name}
                    biography={biography}
                    id={_id}
                    data={followedStores}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
