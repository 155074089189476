import React from 'react'
import UserContainer from '../../myAccount'

export default function RefundRequest() {
    return (
        <>
        <UserContainer>
            <div className='w-full border border-gray-200 rounded-md'>
                <div className='hidden text-gray-500 p-2 sm:p-3 font-semibold w-full sm:grid grid-cols-1 sm:grid-cols-6 md:grid-cols-7 items-center border-b border-gray-200'>
                    <p className=''>Order Code</p>
                    <p>Shops</p>
                    <p className='sm:col-span-2 md:col-span-3'>Products</p>
                    <p className=''>Amount</p>
                    <p>Status</p>
                </div>
                {[1,2,3,4].map((i,j) => {
                    return (
                        <div
                        key={j}
                        className=' p-2 sm:p-3 w-full grid grid-cols-1 sm:grid-cols-6 md:grid-cols-7 gap-4 hover:bg-gray-200 items-center border-b border-gray-200'>
                   <div className='flex items-center justify-between w-full'>
                    <p className='sm:hidden font-semibold'>Order Code</p>
                   <div className=' '>
                        <p className='font-semibold'>20222-332222</p>
                       
                    </div>
                   </div>
                   <div className='flex items-center justify-between w-full'>
                    <p className='sm:hidden font-semibold'>Shops</p>
                    <p className='font-semibold '>Beauty Shop</p>
                    </div>
                   <div className='flex items-center sm:col-span-2 md:col-span-3 justify-between w-full'>
                    <p className='sm:hidden font-semibold'>Products</p>
                    <div className=''>
                        <p className='flex-wrap justify-start flex'>Mens Vintage Stripes Pullover Stand Collar Mockneck Jumper Sweater</p>
                        <p className=''>Quantity: <span className='font-medium'>1</span></p>
                    </div>
                   </div>
                   <div className='flex items-center justify-between w-full'>
                    <p className='sm:hidden font-semibold'>Amount</p>
                    <p className='font-semibold '>$1,234,00</p>
                    </div>
                    <div className='flex items-center justify-between w-full'>
                    <p className='sm:hidden font-semibold'>Status</p>
                    <button className='font-semibold text-white p-1 rounded-md bg-blue-700 '>pending</button>
                    </div>
                    </div>
                    )
                })}
            </div>
        </UserContainer>

        </>
    )
}