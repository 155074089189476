import React from "react";
import { formatDate } from "../../Utils/formatNumber";
export default function OrderedProduct({order}) {
  return (
    <div className=" p-2 sm:p-4 border-b  ">
   <div className=" text-gray-500 p-2 sm:p-3 font-semibold w-full hidden sm:grid  gap-4 grid-cols-5 items-center border-b border-gray-200">
            <p className="col-span-4 sm:col-span-2">Detail</p>

            <p className="hidden sm:block sm:col-span-2">Quantity</p>
            <p>Amount</p>
          </div>

          <div className=" p-2 sm:p-3 w-full grid grid-cols-5 gap-4 hover:bg-gray-200 items-center border-b border-gray-200">
          <div className="flex items-center col-span-4 sm:col-span-2 gap-x-2">
                  <div className="w-[60px] h-[60px] rounded-md overflow-hidden">
                    <img
                    alt="ods"
                      src={order?.image ?? ""}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="flex flex-col  items-start justify-start">
                    <p className="text-ellipsis overflow-hidden max-w-[150px] whitespace-nowrap">{order?.productname ?? ""}</p>
                    <p className="text-gray-500 font-medium text-xs sm:text-[13px]">
                    {formatDate(order?.createdAt || "0")}
                    </p>
                  </div>
                </div>
                <p className="hidden sm:block sm:col-span-2">{order?.quantity ?? ""}</p>

                <p className="font-semibold">
                  ₦{Number(order?.price || 0)?.toLocaleString()}
                </p>
          </div>
    </div>
  );
}
