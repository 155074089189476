import axios from "./useAxios";

// /password/change
export const changePassword = (token, payload) => {
  return axios.put(`/customer/reset/password`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

// /password/forgot
export const sendMail = (token, payload) => {
  return axios.post(`/password/forgot`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
///customer/forgot/password
export const forgotPassword = (token, payload) => {
  return axios.post("/customer/forgot/password", payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//
export const getCredentials = (token) => {
  return axios.get("/associate/credentials", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//customer/update/profile
export const updateProfile = (token, payload) => {
  return axios.post("/customer/update/profile", payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//customer/update/password
export const updatePassword = (token, payload) => {
  return axios.post("/customer/update/password", payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//customer/retrieve/profile
export const getUser = (token, payload) => {
  return axios.post(`/customer/retrieve/profile`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

///customer/retrieve/topcategory
export const getTopCategories = () => {
  return axios.post(`/customer/retrieve/topcategory`);
};

//customer/add/wishlist
export const addWishlist = (token, payload) => {
  return axios.post(`/customer/add/wishlist`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//customer/remove/wishlist
export const removeWishlist = (token, payload) => {
  return axios.post(`/customer/remove/wishlist`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//customer/retrieve/wishlist
export const retrieveWishlist = (token, payload) => {
  return axios.post(`/customer/retrieve/wishlist`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

//customer/follow/store
export const followStore = (token, payload) => {
  return axios.post(`/customer/follow/store`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const unfollowStore = (token, payload) => {
  return axios.post(`/customer/unfollow/store`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

///customer/retrieve/followedstore
export const retrieveFollowed = (token, payload) => {
  return axios.post(`/customer/retrieve/followedstore`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

///customer/retrieve/brand
export const getBrands = () => {
  return axios.post(`/customer/retrieve/brand`);
};

///all categories
export const getAllCategories = () => {
  return axios.post(`/customer/retrieve/category`);
};

///customer/create/address
export function createAddress(token, payload) {
  return axios.post(`/customer/create/address`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

///customer/update/address
export function updateAddress(token, payload) {
  return axios.post(`/customer/update/address`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}
///customer/retrieve/all/address
export function retrieveAllAddress(token, payload) {
  return axios.post(`/customer/retrieve/all/address`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

////customer/retrieve/single/address
export function retrieveSingleAddress(token, payload) {
  return axios.post(`/customer/retrieve/single/address`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

///customer/setdefault/address
export function setDefaultAddress(token, payload) {
  return axios.post(`/customer/setdefault/address`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

///customer/retrieve/default/address
export function retrieveDefaultAddress(token, payload) {
  return axios.post(`/customer/retrieve/default/address`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}
///customer/retrieve/category/product
export function categoryProducts(payload) {
  return axios.post(`/customer/retrieve/category/product`, payload);
}

export function brandProducts(payload) {
  return axios.post(`/customer/retrieve/brand/product`, payload);
}
///customer/retrieve/category
export function retrieveAllCategories() {
  return axios.post(`/customer/retrieve/category`);
}

///customer/retrieve/brand
export function retrieveAllBrands() {
  return axios.post(`/customer/retrieve/brand`);
}

///customer/retrieve/single/product
export function retrieveSingleProduct(payload) {
  return axios.post(`/customer/retrieve/single/product`, payload);
}

export function fundWallet(payload, token) {
  return axios.post(`/customer/make/payment`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function walletHistory(payload, token) {
  return axios.post(`/customer/retrieve/wallet/history`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function todaysDeal() {
  return axios.post(`/customer/retrieve/today/deal`);
}

// /customer/retrieve/altinsmartproduct
export function altinsmartProduct() {
  return axios.post(`/customer/retrieve/altinsmartproduct`);
}

// /customer/retrieve/all/offer
export function allOffers() {
  return axios.post(`/customer/retrieve/all/offer`);
}

export function brands() {
  return axios.post(`/customer/retrieve/brand`);
}

export function featuredShops() {
  return axios.post(`/customer/retrieve/featuredshop`);
}

export function newsArrival(payload) {
  return axios.post(`/customer/subscribe/email`, payload);
}

export function searchName(payload) {
  return axios.post(`/customer/search/name`, payload);
}

export function searchProduct(payload) {
  return axios.post(`/customer/search/product`, payload);
}

export function flashSales() {
  return axios.post(`/customer/retrieve/flashsale`);
}

//

export function retrieveStore(payload) {
  return axios.post(`/customer/retrieve/single/store`, payload);
}

export function noticeView(payload) {
  return axios.post(`/customer/create/product/view/history`, payload);
}

export function retrieveViewed(payload) {
  return axios.post(`/customer/retrieve/product/view/history`, payload);
}

// /customer/check/email
export function verifyUser(payload) {
  return axios.post(`/customer/check/email`, payload);
}

// /customer/retrieve/all/advert
export function retrieveAllAdvert(payload) {
  return axios.post(`/customer/retrieve/all/advert`, payload);
}

export function retrieveSellerCategory(payload) {
  return axios.post(`/customer/retrieve/seller/category`, payload);
}

// /customer/dashboard
export function customerDashboard(payload, token) {
  return axios.post(`/customer/dashboard`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

// /customer/sharecart
export function customerShareCart(payload) {
  return axios.post(`/customer/sharecart`, payload);
}

// /customer/send/cartcode
export function customerCartCode(payload) {
  return axios.post(`/customer/send/cartcode`, payload);
}

export function placeOrder(payload, token) {
  return axios.post(`/customer/create/order`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getUserAddresses(payload, token) {
  return axios.post(`/customer/retrieve/default/address`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function retriveAllOrders(payload, token) {
  return axios.post(`/customer/retrive/all/customer/order`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function retreivePlatformNumber(payload, token) {
  return axios.post(`/customer/retrieve/payment/platform`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function retreiveAllBlogs(payload, token) {
  return axios.post(`/customer/retrieve/all/blog`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function retreiveSingleBlogs(payload, token) {
  return axios.post(`/customer/retrieve/single/blog`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function reactToBlog(payload, token) {
  return axios.post(`/customer/react/blog`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function commentOnBlog(payload, token) {
  return axios.post(`/customer/add/comment`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function reactToComment(payload, token) {
  return axios.post(`/customer/react/comment`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function replyToComment(payload, token) {
  return axios.post(`/customer/add/reply`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function reactToReply(payload, token) {
  return axios.post(`/customer/react/reply`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function retrieveSingleOrder(payload, token) {
  return axios.post(`/customer/retrive/single/customer/order`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function reOrder(payload, token) {
  return axios.post(`/customer/create/product/order/history`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function reviewProduct(payload, token) {
  return axios.post(`/customer/create/product/order/history`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

// /customer/waitlist
export function waitlist(payload) {
  return axios.post(`/customer/waitlist`, payload);
}

// /customer/create/ticket
export function createTicket(payload, token) {
  return axios.post(`/customer/create/ticket`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

// /customer/retrieve/ticket
export function retrieveTickets(payload, token) {
  return axios.post(`/customer/retrieve/ticket`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

// /customer/retrieve/chat

export function retrieveChat(payload, token) {
  return axios.post(`/customer/retrieve/ticket`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

// /customer/retrieve/sharecart

export function retrieveSharedCart(payload) {
  return axios.post(`/customer/retrieve/sharecart`, payload);
}

export function retrieveSellerCategoryDetail(payload) {
  return axios.post(`/customer/retrieve/seller/subcategory`, payload)
}

export function retrieveSellerSubCategoryDetail(payload) {
  return axios.post(`/customer/retrieve/store/product`, payload)
}