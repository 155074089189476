import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useSelector } from "react-redux";
import Recharge from "../../rechargewallet/rechargeWallet";
import { getUser } from "../../../../../Utils/api";

export default function WalletWidget() {
  const [isOpen, setOpen] = useState(false);
  const { loggedInUser, token } = useSelector((state) => state.user);
  const [profile, setProfile] = useState(null);
  function onClose() {
    setOpen(!isOpen);
  }

  useEffect(() => {
    getUser(token, {
      customerid: loggedInUser?.id || loggedInUser.customer._id,
    })
      .then((res) => {
        const { data } = res.data;
        setProfile(data);
        console.log(data);
      })
      .catch();
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-6 items-center">
        <div className="flex flex-col space-y-2 items-center justify-center w-full h-[120px] min-[900px]:h-[100px] rounded-md bg-slate-600 ">
          <p className="font-semibold text-[#b847fe]">Wallet Balance</p>
          <h2 className="text-white text-xl sm:text-2xl">
            {" "}
            {`₦${(profile?.userwallet?.balance ?? 0)?.toLocaleString()}`}
          </h2>
        </div>
       
        <div
          onClick={onClose}
          className="cursor-pointer flex flex-col space-y-2 items-center justify-center w-full h-[120px] min-[900px]:h-[100px] rounded-md bg-slate-200 "
        >
          <p className="font-semibold ">Recharge Wallet</p>
          <AiOutlinePlus className="text-2xl" />
        </div>
      </div>
      {isOpen && <Recharge close={onClose} />}
    </>
  );
}
