import React, { useEffect, useMemo, useState } from "react";
import UserContainer from "../../myAccount";
import topa from "../../../../assets/png/topa.jpg";
import { unfollowStore } from "../../../../Utils/api";
import { useSelector } from "react-redux";
import { Loader2Icon } from "lucide-react";
import { retrieveFollowed } from "../../../../Utils/api";
import { formatDate } from "../../../../Utils/formatNumber";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
export default function FollowedShops() {
  const { token, loggedInUser } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  async function retrieve() {
    const payload = {
      customerid: loggedInUser?.id || loggedInUser?.customer?._id,
    };
    setLoading(true);
    await retrieveFollowed(token, payload)
      .then((res) => {
        //console.log(res);
        const { data } = res?.data;
        setData(data);
      })
      .catch((err) => {
        //console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  useEffect(() => {
    if (!token) return;

    retrieve();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <UserContainer>
        <div className="grid grid-cols-1 sm:grid-cols-2  min-[900px]:grid-cols-2 lg:grid-cols-3 w-full items-center gap-6">
          {loading && (
            <div className="w-full col-span-full h-[300px] flex items-center justify-center flex-col">
              <Loader2Icon className="animate-spin text-altinsmart" />
            </div>
          )}
          {!loading && data?.length === 0 && (
            <div className="w-full h-[300px] flex col-span-full items-center justify-center flex-col">
              <p className="text-red-500">No Data</p>
            </div>
          )}

          {!loading &&
            Array.isArray(data) &&
            data.map((item) => {
              return (
                <FollowedShopsWidget
                  key={item?._id}
                  item={item}
                  retrieve={retrieve}
                />
              );
            })}
        </div>
      </UserContainer>
    </>
  );
}

function FollowedShopsWidget({ item, retrieve }) {
  const navigate = useNavigate();
  const { loggedInUser, token } = useSelector((state) => state.user);

  const createdDate = useMemo(() => {
    return formatDate(item?.createdAt);
  }, [item?.createdAt]);

  async function removefromFollowed() {
    if (!token) {
      toast.error("You have to log in to remove from your favorites");
      return;
    }
    const payload = {
      customerid: loggedInUser?.id || loggedInUser?.customer?._id,
      sellerid: item?._id,
    };

    await unfollowStore(token, payload)
      .then((res) => {
        retrieve();
        //console.log(res);
        toast.success("Shop Unfollowed");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        //console.log(err);
      });
  }

  return (
    <div className="w-full h-fit rounded-md border border-gray-200">
      <div className="w-full h-[140px] relative rounded-t-md">
        <img
          src={item?.images[0]?.img ?? topa}
          alt=""
          className="w-full h-full object-cover rounded-t-md"
        />
        <div className="absolute w-full mx-auto h-fit flex items-center justify-center inset-x-0 bottom-[-50px] ">
          <div className="w-[80px] h-[80px] border-2 border-white rounded-full">
            <img
              src={item?.logo}
              alt=""
              className="w-full h-full rounded-full object-contain"
            />
          </div>
        </div>
      </div>
      <div className="w-full h-fit items-center p-2 sm:p-3 mt-9 flex flex-col justify-center">
        <h2 className="text-xl text-center font-semibold mb-2 sm:text-2xl">
          {item?.name}
        </h2>
        <p className="text-xs w-full text-ellipsis whitespace-nowrap overflow-hidden  sm:text-sm text-center mb-2">
          {item?.biography ?? ""}
        </p>

        <div className="text-xs text-center sm:text-sm mb-2">
          <p>{`Shop since ${createdDate}`}</p>
        </div>
        <div className="w-full items-center justify-center flex space-x-2">
          <button
            onClick={removefromFollowed}
            className="text-white rounded-md font-semibold text-sm py-2 px-3 bg-[#b847fe]"
          >
            UnFollow
          </button>
          <button
            onClick={() => navigate(`/brands-detail?n=${item?._id}`)}
            className="text-[#b847fe] py-2 px-3 text-sm font-semibold bg-slate-600 border border-[#b847fe] rounded-md"
          >
            View Store
          </button>
        </div>
      </div>
    </div>
  );
}
