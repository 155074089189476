import React from "react";
import TopBar from "../landing/topbar/topBar";
import Footer from "../footer/footer";
import MobileNav from "../landing/subcomponents/mobileNav";

export default function Layout({ children }) {

  return (
    <>
      <TopBar />
      <div className="w-full pt-[65px] h-full">{children}</div>
      <MobileNav />
     
      <Footer />
    </>
  );
}

