import {  useLocation } from "react-router-dom";
import Layout from "../../layout/layout";
import Widget from "../../categories/categoriespage/widget/catPageWidget";
// import { CountDown } from "./CountDown";

export default function AdvertPage() {
  const { state } = useLocation();
  const { advert } = state;

  return (
    <Layout hideDesktopMessaging>
      <div className="w-full">
        <div className="w-full h-32 sm:h-48 relative ">
          <img
            src={advert?.banner}
            className="w-full h-full object-cover"
            alt="banner"
          />
          <div className="w-full absolute h-full capitalize bg-black/30 flex items-center justify-center inset-0 text-gray-50 font-semibold text-base sm:text-3xl">
            <p>{advert?.title ?? ""}</p>
          </div>
        </div>
     {/**   <CountDown startDate={advert?.start_date}/> */}

        <div className="w-full grid grid-cols-2 my-6 sm:my-8 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 p-2 items-center gap-x-3 gap-y-6 ">
          {Array.isArray(advert?.product) &&
            advert?.product?.map((item) => {
              const { coverimage, name, price, _id, discount } = item;
              return (
                <div key={_id}>
                  <Widget
                    image={coverimage}
                    name={name}
                    price={price}
                    discount={discount?.discount_price}
                    isdiscount={discount?.isdiscount}
                    id={_id}
                    nav={advert?.title}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </Layout>
  );
}
