import { Link } from "react-router-dom";
import Layout from "../../layout/layout";
import { useEffect, useState } from "react";
import {  retrieveSellerCategoryDetail } from "../../../Utils/api";
import { Loader2Icon } from "lucide-react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CatPageWidgetSkeleton from "../../categories/categoriespage/widget/catPageWidgetSkeleton";
import Widget from "../../categories/categoriespage/widget/catPageWidget";
export default function BrandCategoryPage() {
  const [loading, setLoading] = useState(false);
  const {searchParams} = new URL(window.location)
 const query = searchParams.get("n")
 const brand = searchParams.get("b")
 
  const [data, setData] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    fade: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    //prevArrow: false,
    //nextArrow: false,
  };


  useEffect(() => {
    (async () => {
      setLoading(true);
      await retrieveSellerCategoryDetail({sellerid: brand, categoryid: query })
        .then((res) => {
          const { data } = res?.data;
          setData(data);
          setLoading(false);
        })
        .catch((err) => {
          //console.log(err)
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, brand]);

  const Comp = data?.store?.images?.length > 1 ? Slider : "div"

  return (
    <Layout hideDesktopMessaging>
      {!loading ? (
        <div className="w-full">
            {!data ? (
          <div className="w-full z-10 mb-6 h-36 sm:h-[250px]  animate-pulse">
            <div className="w-full h-full bg-gray-200"></div>
          </div>
        ) : (
          <Comp
            className="brand-seller w-full mb-6 z-10 h-36 sm:h-[250px]"
            {...settings}
          >
            {Array.isArray(data?.store?.images) &&
              data?.store?.images.map(({ img }, index) => (
                <div
                  key={`${img}${index}`}
                  className="w-full h-36 sm:h-[250px] relative "
                >
                  <div className="w-full h-full absolute px-8 sm:px-10 py-8 sm:py-10 inset-0 bg-black/50 z-10">
                    <img
                      src={data?.store?.logo}
                      alt=""
                      className="object-contain"
                    />
                  </div>
                  <img
                    src={img}
                    className="w-full h-full object-cover"
                    alt="banner"
                  />
                </div>
              ))}
          </Comp>
        )}

          <div className="w-full grid grid-cols-1 my-6 sm:my-8 sm:grid-cols-2 p-2 items-center gap-x-3 gap-y-6 ">
            {/** if the number of sellers categories is not an even number add any of his product to make it even */}
            {Array.isArray(data?.storesubcategories) &&
              data?.storesubcategories?.map(({ _id, subcategory, subcategoryurl }) => (
                <Link
                  key={_id}
                  to={`/brands-subcategory-detail?n=${_id}&b=${brand}&detail=${JSON.stringify(data?.store)}`}
                  className="w-full h-44 sm:h-72 overflow-hidden rounded-lg relative"
                >
                  <div className="absolute inset-0 bg-black/40 h-full w-full">
                    <p className="capitalize absolute bottom-0 left-0 p-3 sm:p-4 rounded-tr-lg bg-black/60 sm:bottom-0 sm:left-0 text-white font-semibold text-base sm:text-lg">
                      {subcategory}
                    </p>
                  </div>
                  <img
                    src={subcategoryurl}
                    alt="category"
                    className="w-full h-full object-cover"
                  />
                </Link>
              ))}
          </div>

          <div className="grid grid-cols-2 p-2 mb-3 sm:p-3 justify-center  sm:grid-cols-3  min-[900px]:grid-cols-3 min-[1050px]:grid-cols-3 xl:grid-cols-4 w-full items-center gap-6">
          {!data?.products &&
            [1, 2, 3, 4, 5, 6].map((i, j) => {
              return (
                <div key={j}>
                  <CatPageWidgetSkeleton />
                </div>
              );
            })}
          {Array.isArray(data?.products) &&
            data?.products?.map((item) => {
              const { coverimage, name, price, _id, discount } = item;
              return (
                <div key={_id}>
                  <Widget
                    image={coverimage}
                    name={name}
                    price={price}
                    discount={discount?.discount_price}
                    isdiscount={discount?.isdiscount}
                    id={_id}
                    nav={data?.name ?? "Brand"}
                  />
                </div>
              );
            })}
        </div>
        </div>
      ) : (
        <div className="w-full h-[300px] flex items-center justify-center">
          <Loader2Icon size={32} className="animate-spin" />
        </div>
      )}
    </Layout>
  );
}
