export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const FETCH_USER_START = "FETCH_USER_START";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_START = "LOGIN_START";

export const LOGOUT = "LOGOUT";

export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const UPDATE_TOTAL_PRICE = "UPDATE_TOTAL_PRICE";
export const NUM_OF_ITEMS = "NUM_OF_ITEMS";
export const CLEAR_ALL = "CLEAR_ALL"
export const UPDATE_DELIVERY_PRICE = "UPDATE_DELIVERY_PRICE"