import React from "react";
import { useContext } from "react";
import { DetailContext } from "../productDetail";

export default function ProductDescription() {
  const {detaildata} = useContext(DetailContext)
  return (
   <>
   {detaildata?.about && <div className="w-full space-y-3 sm:space-y-5 my-2 sm:px-6 px-4 sm:my-4">
      <h2 className="font-semibold text-base sm:text-xl">About</h2>
      <div className="flex flex-wrap justify-start leading-6 w-full text-xs sm:text-sm text-gray-500">
        {detaildata?.about || ''}
      </div>
    </div>}

    {detaildata?.description && <div className="w-full space-y-3 sm:px-6 px-4 sm:space-y-5 my-2 sm:my-4">
      <h2 className="font-semibold text-base sm:text-xl">Product Description</h2>
      <div className="flex flex-wrap justify-start leading-6 w-full text-xs sm:text-sm text-gray-500">
        {detaildata?.description || ''}
      </div>
    </div>}
   </>
  );
}
