import React from "react";

import logo from "../../../assets/png/smart.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoaderIcon } from "lucide-react";
import { toast } from "react-hot-toast";
import { MdNavigateBefore } from "react-icons/md";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { changePassword } from "../../../Utils/api";
const ResetPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.user);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    //console.log(data);
    setLoading(true);
    await changePassword(token, data)
      .then((res) => {
        //console.log(res);
        setLoading(false);
        toast.success("Password Updated");
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
        toast.error("failed to change password");
      });
  };

  return (
    <div className="let swipeIn relative flex min-h-screen text-sm sm:text-base bg-[#ECF1F4]">
      <div
        onClick={() => {
          navigate(-1);
        }}
        className="absolute top-3 left-3 cursor-pointer w-fit h-fit flex space-x-2 items-center"
      >
        <MdNavigateBefore className="text-[22px]" />
        Back
      </div>
      <div className="w-full   flex items-center justify-center flex-col px-6 md:px-4">
        <div className="max-w-[400px]  flex items-center justify-center flex-col mx-auto w-full">
          <div className="sm:w-[64px] sm:h-[64px] h-[40px] w-[40px]">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <div className="flex flex-col items-center justify-center gap-y-1 my-8 ">
            <h2 className="text-2xl font-medium font-heading">
              Reset password
            </h2>
            <p className="leading-6 text-sm font-heading text-gray-600">
              Change your Password
            </p>
          </div>

          <div className="w-full my-5">
            <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
              <div className="relative form-group  mb-4 w-full">
                <input
                  type="password"
                  placeholder="Enter new password"
                  className="w-full h-11 sm:h-12 ring-offset-background focus:outline-none px-4 focus-visible:ring-2 placeholder:text-muted-foreground focus-visible:ring-ring focus-visible:ring-offset-2 rounded-3xl border border-gray-400 "
                  {...register("password", {
                    required: true,
                  })}
                />
                {errors?.password?.type === "required" && (
                  <p className="mt-2 pl-4 text-red-500">Password is required</p>
                )}
              </div>

              <button
                className="w-full text-white font-medium mb-5 h-11 sm:h-12 rounded-3xl flex items-center justify-center px-8 bg-[#B847EF] hover:bg-purple-400"
                type="submit"
                disabled={isLoading}
                variant={"default"}
              >
                {isLoading ? (
                  <LoaderIcon className="animate-spin" />
                ) : (
                  <span>Change Password</span>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
