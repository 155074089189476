import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import {
  retrieveAllCategories,
} from "../../../../Utils/api";
import { useNavigate } from "react-router-dom";
export default function MobileFilterNav({ close, activeid, setActiveCats }) {
  const [data, setdata] = useState([]);
 // const [brands, setBrands] = useState([]);
  const navigate = useNavigate()
  

  useEffect(() => {
    async function fetchCats() {
      await retrieveAllCategories()
        .then((res) => {
          ////console.log(res);
          const { data } = res.data;
          setdata(data);
          setActiveCats(data.filter(({ _id }) => _id === activeid)[0].category);
        })
        .catch((err) => {
          ////console.log(err);
        });

    }

    fetchCats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeid]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        close();
      }}
      className="fixed h-full w-full z-[60] min-[900px]:hidden bg-white bg-opacity-50  inset-0"
    >
      <div className="absolute top-0 right-0 w-[220px] sm:w-[300px] overflow-y-auto h-full bg-white let swipeIn shadow-md px-4 py-5">
        <div onClick={close} className="absolute top-3 right-3">
          <MdClose className="text-[22px]" />
        </div>

        <h2 className="w-full text-base font-semibold sm:text-lg py-3 px-2 sm:px-3 ">
          Categories
        </h2>

        <p className="px-2 sm:px-3 font-semibold">All Categories</p>
        <div className="flex flex-col px-2 sm:px-3 pt-3 pb-6 border-b border-gray-200 justify-start items-start w-full">
        {data?.map(({ category, _id }, j) => {
          return (
            <div key={j}>
              <button
                className={`block mb-2 capitalize ${
                  activeid === _id ? "font-semibold" : ""
                }`}
                onClick={() => {
                  navigate(`/category-detail?n=${_id}`, {
                    state: {
                      isCategory: true
                    }
                  })
                }}
              >
                {category}
              </button>
            </div>
          );
        })}
        </div>
       {/**
         <div className="flex flex-col items-start justify-start pt-3">
          <p className="font-semibold mb-3">Brands</p>
          <div className="flex flex-col space-y-3 items-start justify-start">
          {brands?.map(({ brand, _id }, idx) => {
            return (
              <label
                key={idx}
                onClick={() => {
                  activeBrand ? setactiveBrand(null) : setactiveBrand(_id);
                }}
                className="relative brand-container"
              >
                <div className="capitalize">{brand}</div>
                <input type="checkbox" defaultChecked={activeBrand === _id} />
                <span className="brand-checkmark"></span>
              </label>
            );
          })}
        </div>
        </div>
        */}
      </div>
    </div>
  );
}

