import { PiStarLight, PiStarFill } from "react-icons/pi";
export function Rating({ rating, size = 32 }) {
  const ratingButtons = [1, 2, 3, 4, 5];
  return (
    <div className="flex items-center ">
      {ratingButtons.map((value) => {
        return (
          <button key={value} className=" text-zinc-700">
            {value <= rating ? (
              <PiStarFill size={size} className="text-altinsmart" />
            ) : (
              <PiStarLight size={size} />
            )}
          </button>
        );
      })}
    </div>
  );
}
