import * as type from "../Actions/Types";

const initailState = {
  cartItems: [],
  overallPrice: 0,
  numOfCartItems: 0,
  deliveryPrice: 0,
};
const Cart = (state = initailState, action) => {
  switch (action.type) {
    case type.ADD_TO_CART:
      return {
        ...state,
        cartItems: action.payload,
      };

    case type.UPDATE_TOTAL_PRICE:
      return {
        ...state,
        overallPrice: action.payload,
      };

    case type.NUM_OF_ITEMS:
      return {
        ...state,
        numOfCartItems: action.payload,
      };
    case type.UPDATE_DELIVERY_PRICE:
      return {
        ...state,
        deliveryPrice: action.payload,
      };
    case type.CLEAR_ALL:
      return {
        ...state,
        cartItems: [],
        numOfCartItems: 0,
        overallPrice: 0,
        deliveryPrice: 0
      };
    default:
      return state;
  }
};

export default Cart;
