import React from "react";

export default function OrderAddresses({ order }) {
  return (
    <div className="grid w-full grid-cols-1 items-center sm:grid-cols-3 gap-6 my-4">
      <div className="space-y-3">
        <p className="font-semibold text-base sm:text-lg">Billing Address</p>

        <div>
          <p className="font-semibold">{order?.username}</p>
          <div className="text-gray-500 text-xs sm:text-sm">
            {order?.useraddress}
          </div>
        </div>

        <div className="flex items-center space-x-1">
          <p className="font-semibold">Phone:</p>
          <div className="text-gray-500 text-xs sm:text-sm">
            {order?.userphone}
          </div>
        </div>
      </div>

      {/* <div className="space-y-3">
        <p className="font-semibold text-base sm:text-lg">Shipping Address</p>

        <div>
          <p className="font-semibold">Kelvin Gilbert</p>
          <div className="text-gray-500 text-xs sm:text-sm">
            2 Nike Art Gallery Rd, Lekki Phase I 106104, Lekki, Lagos, Nigeria
          </div>
        </div>
        <div className="flex items-center space-x-1">
          <p className="font-semibold">Email:</p>
          <div className="text-gray-500 text-xs sm:text-sm">
            kelvingilbert@gmail.com
          </div>
        </div>
        <div className="flex items-center space-x-1">
          <p className="font-semibold">Phone:</p>
          <div className="text-gray-500 text-xs sm:text-sm">+234818383903</div>
        </div>
      </div> */}
    </div>
  );
}
