import React, { useState, useEffect } from "react";
import { shareAudio, sharingChanels } from "./utils";
import { customerCartCode, customerShareCart } from "../../../Utils/api";
import { Loader2Icon } from "lucide-react";
import Input from "../input";
import toast from "react-hot-toast";

const ShareCart = ({ isShare, setisShare, cart }) => {
  const [email, setEmail] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");

  useEffect(() => {
    (async () => {
      setLoading(true);
      const price = cart
        .map(({ subprice }) => Number(subprice))
        .reduce((prev, curr) => prev + curr, 0);
      const formattedCart = cart?.map((item) => {
        const { count, ...restItem } = item;

        return {
          ...restItem,
          quantity: count,
        };
      });

      const payload = {
        cart: formattedCart,
        price,
      };
      await customerShareCart(payload)
        .then((res) => {
          const { data } = res?.data;
          setCode(data);
        })
        .catch((err) => {
          //console.log(err)
          toast.error(err.response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  async function sendMail() {
    setSendingEmail(true);
    const payload = {
      bookingcode: code,
      email,
    };

    await customerCartCode(payload)
      .then((res) => {
        toast.success("Cart Code sent successfully");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setSendingEmail(false);
      });
  }

  const handleShareAdiolInk = (item) => {
    shareAudio(item.key, item.link, `${window.location.href}?c=${code}`);
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setisShare(!isShare);
      }}
      className={
        isShare
          ? `share_wrap w-full h-full inset-0 fixed z-[80] bg-white/50`
          : "hidden"
      }
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="let swipeDown share_box w-[95%] sm:w-[550px]  shadow inset-0 absolute m-auto flex flex-col item-center justify-center py-6 px-4 h-fit space-y-4 rounded-lg bg-white "
      >
        {loading ? (
          <div className="w-full flex items-center justify-center h-full">
            <Loader2Icon size={40} className="text-altinsmart animate-spin" />
          </div>
        ) : (
          <>
            <div className="w-full flex flex-col gap-y-3">
              <p className="font-semibold w-full text-center text-lg sm:text-2xl">{`Code: ${code}`}</p>
              <Input
                value={email}
                setValue={setEmail}
                placeholder="Email Address"
                type="email"
              />
              <button
                disabled={sendingEmail || email === ""}
                onClick={sendMail}
                className="text-gray-50 w-full h-11 sm:h-12 font-medium bg-altinsmart rounded-3xl"
              >
                Send To Email
              </button>
            </div>
            <p className="w-full text-center">OR</p>
            <div className="share_btn flex justify-center items-center space-x-2 ">
              {sharingChanels.map((item, index) => {
                return (
                  <button
                    title={item.key}
                    disabled={code === ""}
                    key={item.key}
                    className="hover:bg-pink-300 text-altinsmart rounded-full p-1 flex items-center justify-center cursor-pointer"
                    onClick={() => handleShareAdiolInk(item)}
                  >
                    {item.icon}
                  </button>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ShareCart;
