import React,{useEffect} from "react";
import UserContainer from "../../myAccount";
import { useNavigate } from "react-router-dom";
import c from "../../../../assets/png/c.png";
import { AiFillHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { useSelector } from "react-redux";
import { retrieveWishlist } from "../../../../Utils/api";
export default function MywWishlist() {
  const {token, loggedInUser} = useSelector((state) => state.user)


  useEffect(() => {
    if (!token) return;
    const payload = {
      customerid:loggedInUser?.id || loggedInUser?.customer?._id
    }
    async function retrieve() {
      await retrieveWishlist(token, payload)
        .then((res) => {
          //console.log(res);
        })
        .catch((err) => {
          //console.log(err);
        });
    }
    retrieve();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <UserContainer>
        <div className="grid grid-cols-1 sm:grid-cols-2  min-[900px]:grid-cols-2 min-[1050px]:grid-cols-3 w-full items-center gap-6">
          {[1, 2, 3, 4, 5, 6].map((i, j) => {
            return <WishlistWidget key={j} />;
          })}
        </div>
      </UserContainer>
    </>
  );
}

function WishlistWidget() {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate("/details");
      }}
      className="cursor-pointer relative space-y-4 sm:space-y-5 flex flex-col items-center justify-center h-[310px] sm:h-[330px] rounded-lg font-semibold border border-gray-300 p-4 hover:border-gray-600"
    >
      <button className="absolute left-2 top-[12px] ">
        <AiFillHeart className="text-[22px] text-[#b847fe]" />
      </button>
      <div className="w-[150px] h-[150px]">
        <img src={c} alt="" className="w-full h-full object-contain" />
      </div>
      <div className="w-full text-[12px] sm:text-[13px] text-black">
        <p className="whitespace-nowrap text-ellipsis w-full overflow-hidden  ">
          XIAOMI Redmi 10 2022 6.5" 6GB RAM/128GB ROM Android 11 -Carbon Gray
        </p>
        <div className="flex  w-full items-center justify-between">
          <div className="flex items-center space-x-1">
            <span>{"₦112,900"}</span>
            <span className="text-gray-500 line-through">{"₦112,300"}</span>
          </div>
          <div className="text-white px-2 py-1 bg-[#B847EF] rounded-lg">
            10%
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center w-full">
        <button className="bg-[#b847fe] text-white font-medium rounded-2xl py-2 px-6 sm:px-8 flex items-center space-x-2 justify-center">
          <AiOutlineShoppingCart className="text-[22px]" />
          <span>Add to Cart</span>
        </button>
      </div>
    </div>
  );
}
