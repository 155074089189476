import { useEffect, useMemo, useState, useRef } from "react";
import { LiaUserCircleSolid } from "react-icons/lia";
import { IoAttach } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BiSolidNavigation } from "react-icons/bi";
import { MdNavigateBefore } from "react-icons/md";
import io from "socket.io-client";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { retrieveChat } from "../../Utils/api";
import { uploadFile, formatChatDate } from "../../Utils/formatNumber";

const socket = io("https://altinsmarttest-ee96b8e4b316.herokuapp.com");

export function CurrentChat({ islist, name, setList, currentChatId }) {
  const { loggedInUser, token } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const messagesRef = useRef(null);

  socket.on("connect", () => {
    console.log("Connected to server");
  });

  socket.on("disconnect", () => {
    console.log("Disconnected from server");
  });

  useEffect(() => {
    if (loggedInUser?.id || loggedInUser.customer?._id) {
    }

    socket.emit("supportuser", {
      userid: loggedInUser?.id || loggedInUser.customer?._id,
    });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser?.id || loggedInUser.customer?._id]);

  useEffect(() => {
    if (currentChatId) {
      // Listen for messages from user
      socket.on("receieve_user_support", (message) => {
        //  console.log({ message });
        setData((prevMessages) => [...prevMessages, message]);
      });
    }

    // Clean up listeners when the component is unmounted
    return () => {
      socket.off("receieve_user_support");
    };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChatId]);
  async function sendMessage(type, image) {
    setSending(true);
    const payload = {
      ticketid: currentChatId,
      userid: loggedInUser?.id || loggedInUser.customer?._id,
      type,
      usertype: "customer",
      text: message || image,
    };

    socket.emit("send_user_support", payload);
    setSending(false);
    setMessage("");
    fetchData();
  }

  async function handleSubmit(e) {
    if (e.target.files[0]) {
      const img = await uploadFile(e.target.files[0], "image");
      sendMessage("image", img);
    }
  }

  async function fetchData() {
    if (currentChatId) {
      const payload = {
        customerid: loggedInUser?.id || loggedInUser.customer?._id,
        ticketid: currentChatId,
      };

      await retrieveChat(payload, token)
        .then((res) => {
          const { data } = res?.data;
          // console.log(res?.data)
          setData(data);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  }

  useEffect(() => {
    fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChatId]);

  useEffect(() => {
    const currentRef = messagesRef.current;
    const handleDOMNodeInserted = (event) => {
      if (currentRef) {
        const currentTarget = event.currentTarget 
        currentTarget?.scroll({
          top: currentTarget?.scrollHeight,
          behavior: "smooth",
        });
      }
    };
    if (currentRef) {
      currentRef?.addEventListener("DOMNodeInserted", handleDOMNodeInserted);
    }

    return () => {
      // Clean up the event listener when the component unmounts
      if (currentRef) {
        currentRef?.removeEventListener(
          "DOMNodeInserted",
          handleDOMNodeInserted
        );
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesRef?.current]);
  return (
    <>
      <div
        className={` relative max-[880px]:w-full w-full rounded-lg h-full bg-white p-1 sm:p-4 ${
          islist ? "block w-full" : "max-[880px]:hidden"
        }`}
      >
        <div className="w-full mb-3  justify-between items-center flex">
          <div className="flex space-x-2 items-center">
            <button
              onClick={() => {
                setList((prev) => !prev);
              }}
            >
              <MdNavigateBefore className="text-2xl" />
            </button>
            <div className="flex items-center gap-x-2">
              <LiaUserCircleSolid className="text-4xl text-altinsmart " />
              <p>{name}</p>
            </div>
          </div>

          <div className=" flex items-center">
            <button className="p-2 rounded-full  bg-gray-100">
              <BsThreeDotsVertical className="text-xl" />
            </button>
          </div>
        </div>

        <div
          ref={messagesRef}
          className=" relative h-[90%] pt-4 pb-20 overflow-y-auto no-scrollbar w-full"
        >
          {data?.length === 0 && (
             <div className="flex  items-center justify-center flex-col w-full h-[300px]">
             <p className="text-base sm:text-lg font-semibold">Chat is Empty</p>
         <p className="text-xs sm:text-mobile">You have not started any conversation</p>
           </div>
          )}
          {Array.isArray(data) &&
            data?.map(({ usertype, text, type, createdAt }, idx) => (
              <div key={idx} className="flex flex-col">
                {usertype !== "customer" && (
                  <Sender
                    text={text}
                    type={type}
                    name={name}
                    createdAt={createdAt}
                  />
                )}

                {usertype === "customer" && (
                  <Receiver text={text} type={type} createdAt={createdAt} />
                )}
              </div>
            ))}
        </div>
        <div className="bottom-0 absolute  w-full inset-x-0 p-2 bg-white sm:p-4">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              sendMessage("text");
            }}
            className="w-full relative h-12 "
          >
            <label
              htmlFor="upload"
              className="absolute bg-gray-300  rounded-full h-9 w-9 items-center justify-center flex p-1 top-[0.4rem] left-2"
            >
              <IoAttach className="text-gray-50 transform rotate-45 text-[22px]" />
              <input
                onChange={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleSubmit(e);
                }}
                id="upload"
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                hidden
                className="w-full h-full"
              />
            </label>
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="border h-full border-gray-100 shadow  placeholder:text-gray-400 bg-gray-100 rounded-lg focus:outline-none w-full pr-8 pl-16"
              placeholder="Type a message..."
            />
            <button
              type="submit"
              disabled={message === "" || sending}
              className="absolute top-[0.4rem] right-2 bg-altinsmart rounded-full h-8 w-8 flex items-center justify-center p-1"
            >
              <BiSolidNavigation className="text-white text-[22px]" />
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

function Sender({ text, type, name, createdAt }) {
  const formattedTime = useMemo(() => formatChatDate(createdAt), [createdAt]);
  return (
    <div className="w-full h-full">
      <div className="w-[70%] mb-3 float-left grid grid-cols-9 gap-3">
        <LiaUserCircleSolid className="text-4xl text-altinsmart" />

        <div className="w-full col-span-8 flex flex-col items-start justify-start gap-y-2">
          <div className="flex items-center gap-x-4">
            <p className="text-mobile sm:text-sm text-[#221662]">{name}</p>
            <p className="text-xs sm:text-[13px]">{`${formattedTime?.date} ${formattedTime?.time}`}</p>
          </div>
          <div className="   text-white text-mobile sm:text-sm h-fit bg-altinsmart rounded-t-lg rounded-br-lg py-2 px-4">
            {type === "text" ? (
              <div className="font-medium w-full justify-start items-start flex fle-wrap">
                {text}
              </div>
            ) : (
              <div className="w-full h-[250px] rounded-lg">
                <img
                  src={text}
                  alt="im"
                  className="w-full h-full object-contain rounded-lg"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function Receiver({ text, type, createdAt }) {
  const formattedTime = useMemo(() => formatChatDate(createdAt), [createdAt]);
  return (
    <div className="w-full h-full">
      <div className=" float-right  mb-3 w-[70%] grid grid-cols-9 gap-0 sm:gap-3">
        <div className="col-span-8 w-full flex flex-col items-end justify-end gap-y-2">
          <div className="flex items-center gap-x-4">
            <p className="text-xs sm:text-[13px]">{`${formattedTime?.date} ${formattedTime?.time}`}</p>
            <p className="text-[#221662] text-mobile sm:text-sm">User</p>
          </div>
          <div className=" rounded-t-lg py-2 px-4 text-mobile sm:text-sm  h-fit bg-purple-100 rounded-bl-lg ">
            {type === "text" ? (
              <div className="font-medium w-full justify-start items-start flex fle-wrap">
                {text}
              </div>
            ) : (
              <div className="w-full h-[250px] rounded-lg">
                <img
                  src={text}
                  alt="img"
                  className="w-full h-full object-contain rounded-lg"
                />
              </div>
            )}
          </div>
        </div>
        <LiaUserCircleSolid className="text-4xl text-altinsmart" />
      </div>
    </div>
  );
}
