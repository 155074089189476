import React from "react";

export default function Input({type, placeholder, value, setValue, header}) {
  return (
    <div className="relative mb-3 w-full space-y-2">
      <label className="block font-medium text-gray-500" htmlFor={type}>
        {header}
      </label>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        name={type}
        className="w-full h-11 sm:h-12  focus:outline-none px-4 bg-altinsmart/30 placeholder:text-gray-400 rounded-3xl border "
      />
    </div>
  );
}
