import React, { useEffect } from "react";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import AddAddress from "../../addaddress/addAddress";
import { getUserAddresses } from "../../../../../Utils/api";
import { useSelector } from "react-redux";

export default function ShippingAddress() {
  const [isOpen, setOpen] = useState(false);
  const { loggedInUser, token } = useSelector((state) => state.user);
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);

  function onClose() {
    setOpen(!isOpen);
  }

  useEffect(() => {
    setLoading(true);

    getUserAddresses(
      {
        customerid: loggedInUser?.customer?._id ?? loggedInUser?.id,
      },
      token
    )
      .then(({ data }) => {
        setAddresses(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser, token]);

  return (
    <>
      <div className="w-full h-full min-h-[250px] max-h-[320px]">
        <div className="w-full border overflow-hidden mb-3 h-[65%] border-gray-200 rounded-md ">
          <p className="border-b font-medium relative z-[10] border-gray-200 p-2 sm:p-3">
            Default Shipping Address
          </p>
          <div className="flex flex-col pb-6 p-2 sm:p-3 items-start">
            {loading ? (
              <div>Loading</div>
            ) : addresses.length < 1 ? (
              <div>No Data</div>
            ) : (
              // addresses.map((add, idx) => (
              <div>
                <p>{addresses.address}</p>
                <p>
                  {addresses.state}, {addresses.city}
                </p>
                <p>{addresses.country}</p>
                <p>{addresses.postalcode}</p>
              </div>
              // ))
            )}
          </div>
        </div>
        <div
          onClick={onClose}
          className="cursor-pointer h-[30%] hover:bg-purple-300 rounded-md border border-dashed w-full flex items-center justify-center border-[#b847fe]"
        >
          <p className="font-semibold">Add Address</p>
          <AiOutlinePlus className="text-xl" />
        </div>
      </div>
      {isOpen && <AddAddress close={onClose} />}
    </>
  );
}
