import React, { useEffect, useState } from "react";
import { HiOutlineSearch, HiMicrophone } from "react-icons/hi";
import { searchName } from "../../../Utils/api";
import { Link } from "react-router-dom";
import { useSpeechRecognitionHook } from "../../../Hooks/useSpeechRecognitionHook";
function SearchInput() {  
const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const {
    text,
    listening,
    startListening,
    stopListening,
  } = useSpeechRecognitionHook();

  
  useEffect(() => {
    async function fetchData() {
      if (search || text) {
        await searchName({ name: search || text })
          .then((res) => {
            const { data } = res?.data;
            setSearchResult(data);
             stopListening();
          })
          .catch((err) => {});
      }
    }
    fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, text]);

  return (
    <div className="w-[65%] sm:w-[40%] min-[890px]:w-[30%]  relative h-10 sm:h-12">
      <input
        type="search"
        placeholder="search item"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        className="border outline-none rounded-lg bg-[#b847fe] bg-opacity-30 sm:bg-none focus:border-gray-400 pl-10 pr-4 w-full h-full"
      />
      <div className="absolute top-[0.6rem] sm:top-[0.8rem] left-3 flex gap-x-2 items-center text-[22px]">
        

        <button
          className={`hidden ${listening ? "bg-red-600 rounded-full p-1 " : ""}`}
          onClick={() => {
            if (listening) {
              stopListening();
            } else {
              startListening();
            }
          }}
        >
          {" "}
          <HiMicrophone className={`${listening ? "text-base" : ""}`} />
        </button>

        <button>
          {" "}
          <HiOutlineSearch />
        </button>
      </div>
      <div className="w-full absolute top-12 h-fit max-h-[250px] overflow-y-auto bg-white rounded-md">
        <div className="w-full flex flex-col justify-start items-start gap-y-1">
          {Array.isArray(searchResult) &&
            searchResult?.map(({ name, _id }) => (
              <Link
                key={_id}
                className="px-2 py-3 hover:bg-gray-200 w-full border-b text-start"
                to={`/search?id=${name}`}
              >
                {name}
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
}

export default SearchInput;
