import React, { useEffect, useState } from "react";
import UserContainer from "../../myAccount";
import { useSelector } from "react-redux";
import {
  reOrder,
  retriveAllOrders,
  reviewProduct,
} from "../../../../Utils/api";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../Utils/formatNumber";
import { TiStarFullOutline } from "react-icons/ti";
import { Loader2Icon } from "lucide-react";

const LeaveAReview = ({ close, selected }) => {
  const { token, loggedInUser } = useSelector((state) => state.user);

  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);

  const reviewOrder = () => {
    toast.loading("Submitting Review");

    reviewProduct(
      {
        customerid: loggedInUser?.customer?._id ?? loggedInUser.id,
        productid: selected.productid,
        sellerid: selected.sellerid,
        review,
        rating,
      },
      token
    )
      .then(() => {
        toast.success("Review Submitted");
      })
      .catch(() => {
        toast.error("An Error occurred");
      });
  };

  return (
    <div
      onClick={close}
      className="w-full h-full bg-black/30 z-30 fixed inset-0"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="p-3 sm:p-4 m-auto absolute w-[95%] sm:w-[500px] rounded-lg bg-white inset-0 h-fit"
      >
        <div className="flex justify-center gap-2 mb-6">
          {[1, 2, 3, 4, 5].map((rate) => (
            <TiStarFullOutline
              className={`text-4xl ${
                rating >= rate ? "text-yellow-400" : "text-gray-400"
              }`}
              onClick={() => setRating(rate)}
              key={rate}
            />
          ))}
        </div>
        <div className="space-y-3">
          <label htmlFor="review ">Comment your review:</label>
          <textarea
            className="w-full h-48 rounded-md bg-info-800/10 resize-none text-sm p-1 outline-none"
            placeholder=""
            id="review"
            value={review}
            onChange={(e) => setReview(e.target.value)}
          ></textarea>
        </div>

        <div className="flex space-x-2 pt-6 justify-end w-full items-end">
          <button
            onClick={close}
            className="px-4 py-2 bg-gray-300 font-semibold rounded-lg"
          >
            Cancel
          </button>
          <button
            onClick={reviewOrder}
            className={`px-4 py-2 bg-[#b847fe] text-white font-semibold rounded-lg`}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default function PurchaseHistory() {
  const types = ["pending", "completed", "canceled"];
  const [type, setType] = useState(types[0]);
  const { token, loggedInUser } = useSelector((state) => state.user);
  const [orders, setOrders] = useState(null);
  const [, setLoadingOrders] = useState(false);
  const [revieweing, setRevieweing] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setLoadingOrders(true);
    retriveAllOrders(
      {
        customerid: loggedInUser?.customer?._id ?? loggedInUser.id,
      },
      token
    )
      .then(({ data }) => {
        setLoadingOrders(false);
        setOrders(data.data);
      })
      .catch(() => {
        toast.error("Error Fetching Orders");
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortOrder = () => {
    return orders?.filter((order) => order.order_status === type);
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

  const reOrderProduct = (id) => {
    toast.loading("Processing re-order");
    reOrder(
      {
        customerid: loggedInUser?.customer?._id ?? loggedInUser.id,
        productid: id,
      },
      token
    )
      .then(() => {
        toast.success("Re-order complete");
      })
      .catch(() => {
        toast.error("Reorder failed");
      });
  };
  return (
    <>
      <UserContainer>
        <div className="w-full border border-gray-200 rounded-md">
          <div className="flex mx-auto w-fit bg-gray-200 p-1 rounded-lg mt-2">
            {types.map((item) => (
              <div
                key={item}
                onClick={() => setType(item)}
                className={`rounded-lg px-4 font-semibold capitalize text-sm py-2 duration-300 cursor-pointer ${
                  item === type ? "bg-white" : "bg-transparent"
                }`}
              >
                {item}
              </div>
            ))}
          </div>

          <div className=" text-gray-500 p-2 sm:p-3 font-semibold w-full grid  gap-4 grid-cols-5 items-center border-b border-gray-200">
            <p className="col-span-2">Detail</p>

            <p className="col-span-2">Amount</p>
            <p>Action</p>
          </div>

          {!orders ? (
            <div className="h-60 flex items-center text-center justify-center ">
              <Loader2Icon className="text-2xl animate-spin" />
            </div>
          ) : sortOrder()?.length < 1 ? (
            <div className="h-60 flex items-center text-center  justify-center font-semibold">
              <div>No Data</div>
            </div>
          ) : (
            sortOrder()?.map((order) => (
              <div
                key={order._id}
                className=" p-2 sm:p-3 w-full grid grid-cols-5 gap-4 hover:bg-gray-200 items-center border-b border-gray-200"
              >
                <div className="flex items-center col-span-2 gap-x-2">
                  <div className="w-[60px] h-[60px] rounded-md overflow-hidden">
                    <img
                      src={order?.image ?? ""}
                      alt="img"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="flex flex-col  items-start justify-start">
                    <p className="text-ellipsis overflow-hidden max-w-[150px] whitespace-nowrap">
                      {order?.productname ?? ""}
                    </p>
                    <p className="text-gray-500 font-medium text-xs sm:text-[13px]">
                      {formatDate(order.createdAt)}
                    </p>
                  </div>
                </div>

                <p className="font-semibold col-span-2">
                  ₦{formatNumber(order.price)}
                </p>

                {type === types[0] ? (
                  <Link to={`/order-tracker-detail?n=${order._id}`}>
                    <button className="rounded-3xl bg-orange-300 text-xs px-2 py-1 text-center ">
                      Track Order
                    </button>
                  </Link>
                ) : type === types[1] ? (
                  <button
                    className="rounded-3xl bg-green-400 text-xs px-2 py-1 text-center"
                    onClick={() => {
                      setRevieweing(true);
                      setSelected({
                        productid: order.productid,
                        sellerid: order.sellerid,
                      });
                    }}
                  >
                    Leave a review
                  </button>
                ) : (
                  <button
                    className=" rounded-3xl bg-altinsmart text-xs px-2 py-1 text-center"
                    onClick={() => reOrderProduct(order._id)}
                  >
                    Re Order
                  </button>
                )}
              </div>
            ))
          )}
        </div>
      </UserContainer>

      {revieweing && (
        <LeaveAReview close={() => setRevieweing(false)} selected={selected} />
      )}
    </>
  );
}
