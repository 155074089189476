"use client";
import React, { useState } from "react";
import Navs from "../subcomponents/navs";
import SearchInput from "../subcomponents/searchInput";
import { BsCart2 } from "react-icons/bs";
import logo from "../../../assets/png/smart.png";
import { useNavigate } from "react-router-dom";
import smart from "../../../assets/png/smartlogo.png"
import { TbEyeSearch } from "react-icons/tb";
import { BiSolidUser } from "react-icons/bi";
import { MdNavigateBefore } from "react-icons/md";
import AccountDropdown from "../dropdown/accountDropdown";
import { useSelector } from "react-redux";
import { AiOutlineScan } from "react-icons/ai";
import { IoMenu } from "react-icons/io5";
import { UserNav } from "../../user/userNav";
import { AddPreviewCode } from "../../Composable/AddPreviewCode";

function TopBar() {
  const [account, showaccount] = useState(false);
  const [isdrop, setdrop] = useState(false);
  const { token, loggedInUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isPreview, setPreview] = useState(false)
  const { numOfCartItems } = useSelector((state) => state.cart);
  function login() {
    navigate("/login");
  }

  function onClose() {
    showaccount(!account);
  }
  function toggle() {
    setdrop(!isdrop);
  }

  function showPreview() {
    setPreview(!isPreview)
  }
  return (
    <>
      <div className="bg-[#FAFCFE] border-b border-gray-300 fixed z-[20]  top-0 px-2 py-3 text-gray-600 sm:px-6 sm:py-4 flex gap-x-2 justify-between items-center w-full">
       <div className="flex items-center">
      <button
      onClick={toggle}
      className="block sm:hidden">
        <IoMenu className="text-2xl"/>
      </button>
       <div
          onClick={() => {
            navigate("/");
          }}
          className="hidden sm:block w-[120px] h-[50px] "
        >
          <img src={logo}  alt="logo" className="w-full h-full object-cover"/>
        
        </div>
        <div
          onClick={() => {
            navigate("/");
          }}
          className="sm:hidden block w-[100px] h-[40px] "
        >
          <img src={smart}  alt="logo" className="w-full h-full object-cover"/>
        
        </div>

       </div>

        <div className="hidden min-[900px]:flex items-center space-x-3 lg:space-x-6">
          <Navs text="Home" link="/" />
          <Navs text="Brand" link="/brands" />
          <Navs text="Categories" link="/categories" />
          <Navs text="Blog" link="/blog" />
        </div>

        <SearchInput />
        <div className="gap-x-2 sm:gap-x-4 flex items-center">
          {token ? (
            <div
              onClick={() => {
                showaccount(!account);
              }}
              className="hidden cursor-pointer relative  sm:flex items-center space-x-1"
            >
              <BiSolidUser className="text-[#b847fe] text-[22px]" />
              <p className="text-ellipsis whitespace-nowrap font-medium overflow-hidden w-[70px]">
                {`Hi,${loggedInUser?.name?.split(" ")[0] ||loggedInUser.customer?.name || "User"}`}
              </p>
              <button>
                <MdNavigateBefore
                  className={`text-xl ransition-all transform ${
                    account ? "rotate-90" : "-rotate-90"
                  }`}
                />
              </button>

              {account && <AccountDropdown close={onClose} />}
            </div>
          ) : (
            <button
              onClick={login}
              className="hidden rounded-3xl px-8 py-3 text-white sm:block bg-[#B847EF]"
            >
               Log in
            </button>
          )}
         <button className="block sm:hidden">
            <AiOutlineScan className="text-[22px] sm:text-2xl"/>
          </button>
          <div
            onClick={() => {
              navigate("/cart");
            }}
            className="flex relative"
          >
            <BsCart2 className="text-[22px] sm:text-2xl" />
            <span className="absolute text-[10px] font-semibold top-[-10px] text-[#b847fe] right-2">
              {numOfCartItems > 0 && numOfCartItems}
            </span>
          </div>
         
          <button
          onClick={showPreview}
          className="hidden sm:block">
            <TbEyeSearch className="text-[22px] sm:text-2xl"/>
          </button>

        </div>
      </div>

      {isdrop && <UserNav close={toggle}/>}
      {isPreview && <AddPreviewCode close={showPreview}/>}
    </>
  );
}

export default TopBar;
