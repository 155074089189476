import Layout from "../layout/layout";
import HeaderWidget from "../headerwidget/headerWidget";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { searchProduct } from "../../Utils/api";

export default function Search() {
  const { search } = useLocation();
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setloading(true);
      await searchProduct({ name: search.replace("?id=", "") })
        .then((res) => {
          const { data } = res?.data;
          setData(data);
          setloading(false);
        })
        .catch((err) => {
          console.log(err);
          setloading(false);
        });
    }

    fetchData();
  }, [search]);
  return (
    <>
      <Layout>
        <div className="my-2 sm:my-4">
          <HeaderWidget
            title={search.replace("?id=", "").replace(/%20/g, " ")}
            //text={"Enjoy discounted prices off quality products."}
          />

          <div className="mt-4 w-full grid grid-cols-2 px-2 sm:px-5 items-center sm:grid-cols-3 gap-4 lg:grid-cols-4">
            {loading && (
              <div className="w-full col-span-full h-[300px] flex flex-col items-center justify-center">
                <div className="w-20 h-20 rounded-full border-b border-gray-200 border-r animate-spin"></div>
              </div>
            )}
            {!loading && data?.length === 0 && (
              <div className="w-full col-span-full h-400px] flex flex-col items-center justify-center">
                <h2 className="font-semibold w-full text-center text-base sm:text-xl text-gray-300 ">{`No search result for "${search
                  .replace("?id=", "")
                  .replace(/%20/g, " ")}"`}</h2>
              </div>
            )}

            {Array.isArray(data) &&
              data?.map(({ images, price, name, discount, _id }, j) => (
                <div key={j}>
                  <Widget
                    image={images[0]?.image}
                    price={price}
                    name={name}
                    id={_id}
                    discountPrice={discount?.discount_price}
                    isDiscount={discount?.isdiscount}
                  />
                </div>
              ))}
          </div>
        </div>
      </Layout>
    </>
  );
}

export function Widget({ image, price, name, discountPrice, isDiscount, id }) {
  const navigate = useNavigate();

  function percentageDiscount(original, discount) {
    const result = ((original - discount) / original) * 100;

    return `${result.toFixed(0)}%`;
  }

  return (
    <div
      onClick={() => {
        navigate(`/details?n=${id}`);
      }}
      className="cursor-pointer w-full space-y-4 sm:space-y-5 flex flex-col items-center justify-center h-[180px] sm:h-[200px] rounded-lg font-semibold border border-gray-300 p-4 hover:border-gray-600"
    >
      <div className="w-[100px] h-[100px]">
        <img src={image} alt="" className="w-full h-full" />
      </div>
      <div className="w-full text-[12px] sm:text-[13px] text-black">
        <p className="whitespace-nowrap text-ellipsis w-full overflow-hidden  ">
          {name}
        </p>
        <div className="flex  w-full items-center justify-between">
          <div className="flex items-center flex-col md:flex-row md:gap-1">
            <span>{`₦${(price || 0)?.toLocaleString()}`}</span>
            {isDiscount && (
              <span className="text-gray-500 line-through">{`₦${(
                discountPrice || 0
              )?.toLocaleString()}`}</span>
            )}
          </div>
          {isDiscount && (
            <div className="text-white px-2 py-1 bg-[#B847EF] rounded-lg">
              {percentageDiscount(price, discountPrice)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
