import React from "react";
import { CiHome } from "react-icons/ci";
import { PiChatTextThin, PiEyeThin } from "react-icons/pi";
import { RxDashboard } from "react-icons/rx";
import { Link, useLocation } from "react-router-dom";
import { UserNav } from "../../user/userNav";
import { useState } from "react";
import { AddPreviewCode } from "../../Composable/AddPreviewCode";
import { BiLogoBlogger } from "react-icons/bi";
export default function MobileNav() {
  const { pathname } = useLocation();
  const [isClose, setClose] = useState(false)
  const [isPreview, setPreview] = useState(false)

  function showPreview() {
    setPreview(!isPreview)
  }

  function onClose() {
    setClose(!isClose)
  }
  return (
    <>
    <div className="bottom-0 bg-white border-t shadow-md bg-opacity-90 inset-x-0 fixed  flex min-[900px]:hidden w-full items-center justify-between py-3 px-4">
      <Link
        to="/"
        className={`${
          pathname === "/" ? "text-[#b847fe]" : ""
        } flex flex-col items-center space-y-1`}
      >
        <CiHome className="text-xl" />
        <p className="text-xs">Home</p>
      </Link>
      <Link
        to="/categories"
        className={`${
          pathname.includes("categories") ? "text-[#b847fe]" : ""
        } flex flex-col items-center space-y-1`}
      >
        <RxDashboard className="text-xl" />
        <p className="text-xs">Categories</p>
      </Link>
      <Link
        to="/messaging"
        className={`${
          pathname.includes("messaging") ? "text-[#b847fe]" : ""
        } flex flex-col items-center space-y-1`}
      >
        <PiChatTextThin className="text-xl" />
        <p className="text-xs">Chat</p>
      </Link>
      <Link
        to="/blog"
        className={`${
          pathname.includes("blog") ? "text-[#b847fe]" : "text-gray-400"
        } flex flex-col items-center space-y-1`}
      >
        <BiLogoBlogger className="text-xl" />
        <p className="text-xs">Blog</p>
      </Link>
    
      <div
       onClick={showPreview}
        className={`${
          isPreview ? "text-[#b847fe]" : ""
        } flex flex-col items-center space-y-1`}
      >
        <PiEyeThin className="text-[22px]" />
        <p className="text-xs">Preview</p>
      </div>
    </div>
    {isClose && <UserNav close={onClose}/>}
    {isPreview && <AddPreviewCode close={showPreview}/>}
    </>
  );
}
