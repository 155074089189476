import { useMemo } from "react";
import { IoIosHeart } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { timeElapsedSince } from "../../Utils/formatNumber";
import { reactToBlog } from "../../Utils/api";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { AiOutlineLike } from "react-icons/ai";
import { FaRegCommentDots } from "react-icons/fa6";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useState } from "react";

const SingleBlog = ({ blog, refetch }) => {
  const { token, loggedInUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isLike, setLiked] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    fade: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    //prevArrow: false,
    //nextArrow: false,
  };
  const reactBlog = () => {
    if (!token) {
      toast.error("Login is required");
      return;
    }
    setLiked(!isLike);
    reactToBlog(
      {
        customerid: loggedInUser.id || loggedInUser.customer?._id,
        blogid: blog._id,
      },
      token
    )
      .then(() => {
        toast.success("Blog Liked");
        refetch();
      })
      .catch(() => {
        toast.error("Failed to like this blog");
      });
  };

  const postedDate = useMemo(() => {
    return timeElapsedSince(blog?.createdAt ?? "0");
  }, [blog?.createdAt]);

  const Comp = blog?.media?.length > 0 ? Slider : "div";

  return (
    <div
      onClick={() => navigate(`/blog-detail?n=${blog?._id}`)}
      role="button"
      className=" bg-white  py-6 rounded-lg shadow"
    >
      <div className="px-4 flex items-center justify-between">
        <div className="flex gap-10 items-center">
          <div className="flex gap-4 items-center">
            {blog?.sellerid?.logo ? (
              <div className="w-12 h-12 overflow-hidden rounded-full">
                <img
                  src={blog?.sellerid?.logo}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            ) : (
              <div className="w-12 h-12 rounded-full bg-gray-200"></div>
            )}
            <div>
              <div className="text-sm font-semibold capitalize">
                {blog?.sellerid?.name ?? "Altinsmart"}
              </div>
              <div className=" text-xs">{postedDate ?? ""}</div>
            </div>
          </div>
          {/* <button className="bg-info-700 text-white text-sm px-2 py-1">
            Follow
          </button> */}
        </div>
      </div>
      {Array.isArray(blog?.media) && blog?.media?.length > 0 && (
        <>
          <Comp
            className="brand-seller w-full  my-3 z-10 h-[12rem] sm:h-[18rem]"
            {...settings}
          >
            {Array.isArray(blog?.media) &&
              blog?.media?.map((media) => {
                if (media?.mediatype === "video") {
                  return (
                    <div className="w-full h-[12rem] sm:h-[18rem] ">
                      <video
                        muted
                        controls
                        src={media?.url}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  );
                } else {
                  return (
                    <div className="w-full h-[12rem] sm:h-[18rem]">
                      <img
                        src={media?.url}
                        className="w-full h-full object-cover"
                        alt="blog"
                      />
                    </div>
                  );
                }
              })}
          </Comp>
        </>
      )}

      <h2 className="font-semibold text-base sm:text-lg px-4 pt-4 capitalize">
        {blog?.title ?? ""}
      </h2>
      <div className="px-4 w-full line-clamp-2 my-4">{blog?.content}</div>

      <div className="px-4 flex gap-4 my-3 items-center">
        <div className="text-sm flex gap-2 items-center">
          <IoIosHeart className="text-red-700 text-2xl" />
          <div className="font-semibold">{blog.likes} Like</div>
        </div>
        <div className="font-semibold">{blog.totalcomment} Comments</div>
      </div>

      {
        <div className="px-4 flex gap-4  border-t pt-4 items-center mt-2">
          <button
            className="flex gap-2 items-center"
            onClick={(e) => {
              e.stopPropagation();
              reactBlog();
            }}
          >
            <AiOutlineLike
              className={`text-[22px] ${isLike ? "text-altinsmart" : ""}`}
            />
            <div className="text-sm">Like</div>
          </button>

          <Link to={`/blog/${blog?._id}`}>
            <div className="flex gap-2 items-center">
              <FaRegCommentDots className="text-[22px]" />
              <div className="text-sm">Comment</div>
            </div>
          </Link>

          <div className="hidden  gap-2 items-center">
            <div className="text-sm">Share</div>
          </div>
        </div>
      }
    </div>
  );
};

export default SingleBlog;

/*
  <div className="grid grid-cols-2 gap-2">
<img
  src="https://plus.unsplash.com/premium_photo-1711051475117-f3a4d3ff6778?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  alt=""
  className="col-span-2 h-80 w-full object-cover rounded-lg"
/>
   <img
          src="https://images.unsplash.com/photo-1711008995950-2590b21d92a2?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt=""
          className="w-full h-52 rounded-lg"
        />

        <img
          src="https://images.unsplash.com/photo-1682687982468-4584ff11f88a?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt=""
          className="w-full h-52 rounded-lg"
        /> 
        </div>;*/
