import React from "react";
import { Link } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";
import { HiOutlineMail } from "react-icons/hi";
import { AiOutlinePhone } from "react-icons/ai";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaGooglePlay,
  FaApple,
} from "react-icons/fa";
import { AiFillInstagram, AiOutlineTwitter } from "react-icons/ai";
export default function Footer() {
  return (
    <div className="w-full space-y-3 sm:space-y-5  my-2 sm:my-4">
      <div className="w-full bg-[#111] pb-20 min-[900px]:pb-0 text-white ">
        <div className="grid grid-cols-2 sm:grid-cols-6 md:grid-cols-7 border-b border-white w-full px-4 py-6 sm:p-6 items-start gap-8 sm:gap-6">
          <div className="hidden sm:block w-full space-y-5 col-span-2">
            <p>Terms and Conditions</p>
            <p>Return Policy</p>
            <p>Privacy Policy</p>
            <p>Warranty Policy</p>
          </div>

          <div className="w-full space-y-5 sm:col-span-2 md:col-span-1">
            <p className="font-semibold text-[15px] sm:text-lg">Resources</p>
            <Link to="/" className="block">
              Home
            </Link>
            <Link className="block" to="/categories">
              Categories
            </Link>
            <Link className="block" to="/brands">
              Brand
            </Link>
          </div>
          <div className="w-full h-fit space-y-5 sm:col-span-2">
            <div>
              <p className="font-semibold">Dowload SmartBest Mobile App</p>
              <p>Get access to exclusive offers</p>
            </div>
            <button className="w-[150px] h-[3rem] sm:w-[200px] rounded-[2rem] sm:h-[3.5rem] border px-6 flex gap-x-3 items-center justify-start border-gray-50">
              <FaApple className="text-xl sm:text-3xl" />
              <div className="flex flex-col items-start justify-start">
                <p className="text-[8px] sm:text-[10px]">GET IT ON</p>
                <p className="text-[10px] sm:text-sm">APP STORE</p>
              </div>
            </button>
            <button className="w-[150px] h-[3rem] sm:w-[200px] rounded-[2rem] sm:h-[3.5rem] border px-6 flex gap-x-3 items-center justify-start border-gray-50">
              <FaGooglePlay className="text-lg sm:text-3xl" />
              <div className="flex flex-col items-start justify-start">
                <p className="text-[8px] sm:text-[10px]">DOWNLOAD ON</p>
                < p className="text-[10px] sm:text-sm">PLAY STORE</p>
              </div>
            </button>
          </div>

          <div className="w-full space-y-5 col-span-full sm:col-span-2">
            <p className="font-semibold text-[15px] sm:text-lg">Help/Support</p>

            <div className="flex items-center text-gray-500 space-x-2">
              <IoLocationOutline className="text-[22px]" />
              <p>Address</p>
            </div>
            <p>7B Udeco Medical Road, Chevy View Estate, Lagos</p>
            <div className="flex items-center text-gray-500 space-x-2">
              <HiOutlineMail className="text-[22px]" />
              <p>Email</p>
            </div>
            <p>support@altinsmart.com</p>
            <div className="flex items-center text-gray-500 space-x-2">
              <AiOutlinePhone className="text-[22px]" />
              <p>Phone</p>
            </div>
            <p>+234-0801-234-5678</p>
          </div>
          <div className="sm:hidden block w-full space-y-5 col-span-full">
            <p>Terms and Conditions</p>
            <p>Return Policy</p>
            <p>Privacy Policy</p>
            <p>Warranty Policy</p>
          </div>
        </div>
        <div className="w-full p-4 sm:p-6">
          <div className="flex flex-col-reverse sm:flex-row w-full gap-6 items-start sm:items-center justify-start sm:justify-between">
            <div className="flex flex-wrap">
              {`  © Altinsmart ${new Date().getFullYear()} | Trademarks and brands are the property of their respective owners.`}
            </div>
            <div className="flex space-x-6 sm:space-x-8 items-center">
              <Link to="https://www.instagram.com">
                <AiFillInstagram className="text-[22px]" />
              </Link>
              <Link to="https://www.twitter.com">
                <AiOutlineTwitter className="text-[22px]" />
              </Link>
              <Link to="https://www.fb.com">
                <FaFacebookF className="text-[22px]" />
              </Link>
              <Link to="https://linkedin.com">
                <FaLinkedinIn className="text-[22px]" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
