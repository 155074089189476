import React from "react";

export default function HeaderWidget({title,text}) {
    return (
        <div className="sm:space-y-2 px-4 py-3  sm:px-6 sm:py-4">
         <h1 className="capitalize text-black text-xl sm:text-4xl font-bold">{title}</h1>
        <p className="text-gray-400">{text}</p>
   </div>
      
    )
}