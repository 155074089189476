import React, { useState } from "react";
import logo from "../../assets/png/smart.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import VerificationInput from "react-verification-input";
import { LoaderIcon } from "lucide-react";
import { MdNavigateBefore } from "react-icons/md";
import { verifyUser } from "../../Utils/api";
const Verification = () => {

  const [code, setCode] = useState("");
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async () => {
    setLoading(true); 
    await verifyUser({code})
      .then((res) => {
        setLoading(false);
      //  console.log(res);
        toast.success("Verifcation Successful");
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className="let swipeIn relative flex min-h-screen text-sm sm:text-base bg-[#ECF1F4]">
      <div
        onClick={() => {
          navigate(-1);
        }}
        className="absolute top-3 left-3 cursor-pointer w-fit h-fit flex space-x-2 items-center"
      >
        <MdNavigateBefore className="text-[22px]" />
        Back
      </div>
      <div className="w-full   flex items-center justify-center flex-col px-6 md:px-4">
        <div className="max-w-[400px]  flex items-center justify-center flex-col mx-auto w-full">
          <div className="sm:w-[64px] sm:h-[64px] h-[40px] w-[40px]">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <div className="flex flex-col items-center justify-center gap-y-1 my-8 ">
            <h2 className="text-2xl font-medium font-heading">
              Reset password
            </h2>
            <p className="leading-6 text-sm font-heading text-gray-600">
              Enter the confirmation code you received
            </p>
          </div>

          <div className="w-full my-5">
            <form
              className="w-full items-center justify-center"
              onSubmit={(e) => {
                e.preventDefault()
                onSubmit()
              }}
            >
              <div className="relative form-group  mb-4 w-full">
                <VerificationInput
                  classNames={{
                    character: "character",
                    container: "container",
                  }}
                  length={5}
                  inputProps={{
                    autoComplete: "one-time-code", // for IOS
                  }}
                  onChange={(value) => {
                    setCode(value);
                  }}
                />
              </div>

              <button
                className="w-full mb-5 h-11 text-white flex items-center justify-center sm:h-12 rounded-3xl px-8 bg-[#B847EF] hover:bg-purple-400"
                type="submit"
                disabled={isLoading}
                variant={"default"}
              >
                {isLoading ? (
                  <LoaderIcon className="animate-spin" />
                ) : (
                  <span>Continue</span>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
