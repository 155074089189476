import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { addWishlist, retrieveWishlist } from "../../Utils/api";
import toast from "react-hot-toast";
import { IoMdPricetags } from "react-icons/io";

export function FavouriteButton({ product, isCart }) {
  const { token, loggedInUser } = useSelector((state) => state.user);
  const [quantity] = useState(1);
  const [isFav, setFav] = useState(false);

  useEffect(() => {
    if (!token) return;

    const payload = {
      customerid: loggedInUser?.id || loggedInUser?.customer?._id,
    };
    async function retrieve() {
      if (isFav || !isFav) {
        await retrieveWishlist(token, payload)
          .then((res) => {
            // //console.log("wish", res);
            const { data } = res.data;
            const isId = data?.cart
              ?.map(({ productid }) => productid)
              .includes(product?._id);
            if (isId) {
              setFav(true);
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    }
    retrieve();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFav, product]);

  async function addtoFav() {
    if (!token) {
      toast.error("You have to log in to add to your favorites");
      return;
    }
    if (isFav) toast.error("Product already added to favorites");

    setFav(true);
    const payload = isCart
      ? {
          customerid: loggedInUser?.id || loggedInUser?.customer?._id,
          item: product,
        }
      : {
          customerid: loggedInUser?.id || loggedInUser?.customer?._id,

          item: {
            variantid: "",
            variantpoduct: false,
            price: product?.price,
            delivery_fee: parseInt(product?.vehicle_fee),
            total_fee: parseInt(product?.price),
            subprice:
              product?.discount?.isdiscount &&
              parseInt(product?.discount?.discount_price) > 0
                ? parseInt(product?.discount?.discount_price)
                : parseInt(product?.price),
            latitiude: product?.location?.latitiude,
            longitude: product?.location?.longitude,
            vehicle_fee: parseInt(product?.vehicle_fee),
            quantity: quantity,
            image: product?.images?.filter(
              (item) => item.mediatype === "image"
            )[0]?.url,
            productname: product?.name,
            productDescription: product?.description,
            delivery_vehicle: product?.delivery_vehicle,
            productid: product?._id,
            sellerid: product?.sellerid._id,
          },
        };
    await addWishlist(token, payload)
      .then((res) => {
        //console.log(res);
        toast.success("Item added to your favorites");
      })
      .catch((err) => {
        //console.log(err);
      });
  }

  return (
    <>
      {isCart ? (
        <button
          disabled={isFav}
          onClick={(e) => {
            // setFav(false);
            e.stopPropagation();
            addtoFav();
          }}
          className="text-altinsmart border border-altinsmart px-2 justify-center h-9 rounded-lg flex items-center gap-x-2"
        >
          <IoMdPricetags className="hidden sm:block text-xl" />
          <p>Save for Later</p>
        </button>
      ) : (
        <button
          disabled={isFav}
          onClick={() => {
            // setFav(false);

            addtoFav();
          }}
          className="w-fit rounded-lg bg-black/10 p-1  h-fit flex items-center justify-center"
        >
          {isFav ? (
            <AiFillHeart className={"text-[22px] text-altinsmart"} />
          ) : (
            <AiOutlineHeart className="text-[22px] text-white" />
          )}
        </button>
      )}
    </>
  );
}
