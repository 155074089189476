import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  retrieveAllCategories,
} from "../../../../Utils/api";
import { useState } from "react";

export default function CategoryFilter({ activeid, setActiveCats }) {
  const [data, setdata] = useState([]);
 // const [brands, setBrands] = useState([]);
  const navigate = useNavigate()
 

  useEffect(() => {
    async function fetchCats() {
      await retrieveAllCategories()
        .then((res) => {
          ////console.log(res);
          const { data } = res.data;
          setdata(data);
          setActiveCats(data.filter(({ _id }) => _id === activeid)[0].category);
        })
        .catch((err) => {
          ////console.log(err);
        });
    /**
       await retrieveAllBrands()
        .then((res) => {
          ////console.log(res);
          const { data } = res.data;
          setBrands(data);
        })
        .catch((err) => {
         // //console.log(err);
        });
     */
    }

    fetchCats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeid]);

  return (
    <div className="min-[900px]:block hidden w-[250px] py-4 border-r border-gray-200 h-fit">
      <h2 className="w-full text-base sm:text-lg font-semibold py-3 px-2 sm:px-3 ">
        Categories
      </h2>

      <p className="px-2 sm:px-3 font-semibold ">All Categories</p>
      <div className="flex flex-col px-2 sm:px-3 pt-3 pb-6 border-b border-gray-200 justify-start items-start w-full">
        {data?.map(({ category, _id }, j) => {
          return (
            <div key={j}>
              <button
                className={`block mb-2 capitalize ${
                  activeid === _id ? "font-semibold" : ""
                }`}
                onClick={() => {
                  navigate(`/category-detail?n=${_id}`, {
                    state: {
                      isCategory: true
                    }
                  })
                }}
              >
                {category}
              </button>
            </div>
          );
        })}
      </div>
 {/**
       <div className="flex flex-col justify-start items-start pt-3">
        <p className="font-semibold mb-3 ">Brands</p>
        <div className="flex flex-col space-y-3 items-start justify-start">
          {brands?.map(({ brand, _id }, idx) => {
            return (
              <label
                key={idx}
                onClick={() => {
                  activeBrand ? setactiveBrand(null) : setactiveBrand(_id);
                }}
                className="relative brand-container"
              >
                <div className="capitalize">{brand}</div>
                <input type="checkbox" defaultChecked={activeBrand === _id} />
                <span className="brand-checkmark"></span>
              </label>
            );
          })}
        </div>
      </div>
  */}
    </div>
  );
}
