import React, { useEffect, useState } from "react";
import UserContainer from "../../myAccount";
import { AiOutlinePlus } from "react-icons/ai";
import Recharge from "../rechargewallet/rechargeWallet";
import { useSelector } from "react-redux";
import { walletHistory } from "../../../../Utils/api";
export default function MyWallet() {
  const [isOpen, setOpen] = useState(false);
  const { loggedInUser, token } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  function onClose() {
    setOpen(!isOpen);
  }

  useEffect(() => {
    async function userWalletHistory() {
      const payload = {
        customerid: loggedInUser?.id || loggedInUser?.customer?._id,
        walletid: loggedInUser?.userwallet?._id,
      };

      await walletHistory(payload, token)
        .then((res) => {
          const { data } = res?.data;
          setData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    userWalletHistory();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser?.userwallet?.balance, loggedInUser?.customer?._id]);

  return (
    <>
      <UserContainer>
        <div className="w-full grid grid-cols-2 gap-4 ">
          <div className="flex flex-col items-center justify-center gap-4 w-full h-[140px] min-[900px]:h-[120px] rounded-md bg-slate-600 ">
            <p className="font-semibold text-[#b847fe]">Wallet Balance</p>
            <h2 className="text-white text-xl sm:text-2xl font-semibold">
              {`₦${(loggedInUser?.userwallet?.balance || 0)?.toLocaleString()}`}
            </h2>
          </div>
          <div
            onClick={onClose}
            className="cursor-pointer border border-dashed border-[#b847fe] hover:bg-purple-300 flex flex-col space-y-2 items-center gap-4 justify-center w-full h-[140px] min-[900px]:h-[120px] rounded-md bg-slate-200 "
          >
            <p className="font-semibold ">Recharge Wallet</p>
            <AiOutlinePlus className="text-2xl" />
          </div>
        </div>
        <h2 className="border-b w-full p-2 sm:p-3 ">Wallet History</h2>

        <div className="w-full border border-gray-200 rounded-md">
          <div className="hidden text-gray-500 p-2 sm:p-3 font-semibold w-full sm:grid grid-cols-1 gap-4 sm:grid-cols-5 md:grid-cols-6 items-center border-b border-gray-200">
            <p className="">Date</p>
            <p className="">Amount</p>
            <p className="sm:col-span-2 md:col-span-3">Transaction ID</p>
            <p>Type</p>
          </div>
          {Array.isArray(data?.wallethistory) && data?.wallethistory?.length === 0 && (
            <div className="w-full h-[250px] flex flex-col items-center justify-center">
              <p className="text-red-600">- No Transaction History -</p>
            </div>
          )}
          {Array.isArray(data?.wallethistory) &&
            data?.wallethistory?.map(({ createdAt, trx_type, transactionid, status, amount }, j) => {
              return (
                <div
                  key={j}
                  className=" p-2 sm:p-3 w-full grid grid-cols-1 sm:grid-cols-5 md:grid-cols-6 gap-4 hover:bg-gray-200 items-center border-b border-gray-200"
                >
                  <div className="flex items-center sm:items-start justify-between w-full">
                    <p className="sm:hidden font-semibold">Date</p>

                    <p className="font-semibold">
                      {createdAt?.split("T")[0] || "--"}
                    </p>
                  </div>
                  <div className="flex items-center sm:items-start justify-between w-full">
                    <p className="sm:hidden font-semibold">Amount</p>

                    <p className="font-semibold ">{`₦${(
                      amount || 0
                    )?.toLocaleString()}`}</p>
                  </div>
                  <div className="flex items-center sm:col-span-2 md:col-span-3 sm:items-start justify-between w-full">
                    <p className="sm:hidden font-semibold">Transaction ID</p>

                    <p className=" font-semibold">
                      {" "}
                      {` ${transactionid !== "" ? transactionid : ""}`}
                    </p>
                  </div>

                  <div className="flex items-center justify-between sm:items-start w-full">
                    <p className="sm:hidden font-semibold">Type</p>
                    <button
                      className={`text-xs sm:text-sm rounded-md text-white ${
                        status === "success" ? "bg-green-700" : "bg-red-700"
                      } p-1 w-[80px]`}
                    >
                      {status}
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </UserContainer>
      {isOpen && <Recharge close={onClose} />}
    </>
  );
}
