import React, { useEffect, useState } from "react";

import OrderSummary from "./orderSummary";
import TrackerUI from "./trackerUI";
import OrderedProduct from "./orderedProduct";
import OrderAddresses from "./orderAddresses";
import { MdNavigateBefore } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "../layout/layout";
import { retrieveSingleOrder } from "../../Utils/api";
import { useSelector } from "react-redux";

export default function OrderTracker() {
  const { token, loggedInUser } = useSelector((state) => state.user);
  const [orderDetails, setOrderDetails] = useState(null);
  const navigate = useNavigate();
  
  const {search} = useLocation()
 const query = search.replace("?n=", "")

  useEffect(() => {
    retrieveSingleOrder(
      {
        customerid: loggedInUser?.customer?._id || loggedInUser.id,
        orderid: query,
      },
      token
    )
      .then(({ data }) => {
        setOrderDetails(data.data);
        console.log(data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full">
      <Layout>
        <div className="w-full space-y-5 sm:space-y-6 px-4 sm:px-6 my-2 sm:my-4">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="flex items-center text-[#b847fe] space-x-1 sm:space-x-2"
          >
            <MdNavigateBefore className="text-[22px]" />
            <span>Order detail</span>
          </button>
          <div className="w-full border border-gray-300 p-2 sm:p-4 rounded-lg">
            <OrderSummary order={orderDetails} />
            <TrackerUI />
            {/* <OrderActivity /> */}
             <OrderedProduct order={orderDetails} /> 
            <OrderAddresses order={orderDetails} />
          </div>
        </div>
      </Layout>
    </div>
  );
}
