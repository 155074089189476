import React, { useState } from "react";
import Input from "../../../Composable/input";
import AddAddress from "../addaddress/addAddress";
import UserContainer from "../../myAccount";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import {
  getUser,
  retrieveAllAddress,
  retrieveDefaultAddress,
  setDefaultAddress,
  updatePassword,
  updateProfile,
} from "../../../../Utils/api";
import { LoaderIcon } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { GetUsersSuccess } from "../../../../Redux/Actions/ActionCreators";
import { ReactCountries } from "../../../../Utils/countrydropdown/countries";
import { useEffect } from "react";
export default function ManageProfile() {
  const { token, loggedInUser } = useSelector((state) => state.user);
  const [name, setName] = useState(loggedInUser?.customer?.name || "");
  const [email, setEmail] = useState(loggedInUser?.customer?.email || "");
  const [newpassword, setPassword] = useState("");
  const [country, setCountry] = useState(
    loggedInUser?.customer?.country || "Nigeria"
  );
  const [currentpassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState(loggedInUser?.customer?.phone || "");
  const [addresslist, setaddresslist] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [addressdata, setaddressdata] = useState("");
  const [userDefaultAdress, setuserDefaultAdress] = useState("");

  const [updaingdetails, setupdetails] = useState(false);

  const [updatingPassword, setupPassword] = useState(false);

  const dispatch = useDispatch();
  function onClose(data) {
    setOpen(!isOpen);

    setaddressdata(data);
  }

  useEffect(() => {
    //retrieve user profile details
    async function retrieveProfile() {
      const payload = {
        customerid: loggedInUser?.id || loggedInUser?.customer?._id,
      };
      //console.log(payload);

      await getUser(token, payload)
        .then((res) => {
          ////console.log(res);
          const { data } = res.data;
          dispatch(GetUsersSuccess(data));
        })
        .catch((err) => {
          //console.log(err);
        });

      await retrieveAllAddress(token, {
        customerid: loggedInUser?.id || loggedInUser?.customer?._id,
      })
        .then((res) => {
          ////console.log(res);
          const { data } = res.data;
          setaddresslist(data);
        })
        .catch((err) => {
          //console.log(err);
        });
      await retrieveDefaultAddress(token, {
        customerid: loggedInUser?.id || loggedInUser?.customer?._id,
      })
        .then((res) => {
          const { data } = res.data;
          setuserDefaultAdress(data);
        })
        .catch((err) => {
          //console.log(err)
        });
    }

    retrieveProfile();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [loggedInUser?.id, loggedInUser?.customer?._id, dispatch, token]);
  async function updateDetails() {
    const payload = {
      customerid: loggedInUser?.id || loggedInUser?.customer?._id,
      name,
      email,
      phone,
      country,
    };

    setupdetails(true);
    await updateProfile(token, payload)
      .then((res) => {
        //console.log(res.data);
        setupdetails(false);
        toast.success("Profile updated successfully");
        window.location.reload();
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err.response.data.message);
        setupdetails(false);
      });
  }

  async function updatePasswords() {
    const payload = {
      customerid: loggedInUser?.id || loggedInUser?.customer?._id,
      currentpassword,
      newpassword,
    };

    ////console.log(payload)
    setupPassword(true);
    await updatePassword(token, payload)
      .then((res) => {
        //  //console.log(res.data)
        toast.success("Password updated successfully");
        setupPassword(false);
        window.location.reload();
      })
      .catch((err) => {
        //  //console.log(err)
        toast.error(err.response.data.message);
        setupPassword(false);
      });
  }

  return (
    <>
      <UserContainer>
        <div className="w-full mb-3">
          <h2 className="text-base sm:text-lg font-semibold mb-3">
            Profile Information
          </h2>
          <div className="w-full border border-gray-400 h-fit p-2 sm:p-4 rounded-2xl mb-3">
            <Input
              type={"name"}
              placeholder={"John Doe"}
              header={"Full Name"}
              value={name}
              setValue={setName}
            />
            <Input
              type={"email"}
              placeholder={"name@email.com"}
              header={"Email Address"}
              value={email}
              setValue={setEmail}
            />
            <Input
              type={"number"}
              placeholder={"0813 456 432"}
              header={"Phone Number"}
              value={phone}
              setValue={setPhone}
            />

            <div className="space-y-2">
              <p className="text-gray-500 font-medium">Country</p>

              <ReactCountries setCountry={setCountry} />
            </div>

            <button
              onClick={updateDetails}
              className="flex items-center justify-center  w-[120px] text-center my-3 rounded-3xl bg-[#b847fe] text-white font-semibold h-[43px]"
            >
              {updaingdetails ? (
                <LoaderIcon className="  animate-spin text-[22px]" />
              ) : (
                <span> Update</span>
              )}
            </button>
          </div>
          <div className="w-full border border-gray-400 h-fit p-2 sm:p-4 rounded-2xl">
            <Input
              type={"password"}
              placeholder={"*******"}
              header={"Current Password"}
              value={currentpassword}
              setValue={setConfirmPassword}
            />
            <Input
              type={"password"}
              placeholder={"********"}
              header={"New Password"}
              value={newpassword}
              setValue={setPassword}
            />

            <button
              onClick={updatePasswords}
              className="flex items-center justify-center w-[120px] text-center my-3 rounded-3xl bg-[#b847fe] text-white font-semibold h-[43px]"
            >
              {updatingPassword ? (
                <LoaderIcon className="animate-spin text-[22px]" />
              ) : (
                <span> Update</span>
              )}
            </button>
          </div>
        </div>

        <div className="w-full mt-3">
          <div className="flex items-center font-semibold mb-3 justify-between w-full">
            <h2 className="text-base sm:text-lg ">Address</h2>

            <button
              onClick={() => {
                onClose("");
              }}
              className="px-4 py-2 h-10 rounded-3xl bg-[#b847fe] text-white"
            >
              Add New
            </button>
          </div>

          <div className="w-full grid grid-cols-1 mb-3 gap-4 sm:grid-cols-2">
            <div className="w-full">
              <h3 className="font-semibold mb-1">Default Shipping Address</h3>
              {userDefaultAdress && (
                <div className="border flex flex-col border-gray-200 rounded-3xl py-3 sm:py-6 px-2 sm:px-3 space-y-1 h-fit w-full">
                  <p>{userDefaultAdress.address}</p>
                  <p>
                    {userDefaultAdress.state}, {userDefaultAdress.city}
                  </p>
                  {/* <p>{add.state}</p> */}
                  <p>{userDefaultAdress.country}</p>
                  <p>{userDefaultAdress.postalcode}</p>
                </div>
              )}
            </div>

            {/* <div className="w-full">
              <h3 className="font-semibold mb-1">Default Billing Address</h3>
              {userDefaultAdress && (
                <div className="border flex flex-col border-gray-200 rounded-3xl py-3 sm:py-6 px-2 sm:px-3 space-y-1 h-fit w-full">
                  <p>{userDefaultAdress.address}</p>
                  <p>
                    {userDefaultAdress.state}, {userDefaultAdress.city}
                  </p>
                  <p>{userDefaultAdress.country}</p>
                  <p>{userDefaultAdress.postalcode}</p>
                </div>
              )}
            </div> */}
          </div>
          <div className="w-full grid grid-cols-1 mb-3 gap-3 sm:grid-cols-2">
            {Array.isArray(addresslist) &&
              addresslist.map((item, idx) => {
                return (
                  <div key={idx}>
                    <AddressListwidget data={item} onClose={onClose} />
                  </div>
                );
              })}
          </div>
        </div>
        {isOpen && <AddAddress data={addressdata} close={onClose} />}
      </UserContainer>
    </>
  );
}

function AddressListwidget({ onClose, data }) {
  const [isshow, setshow] = useState(false);
  const { token, loggedInUser } = useSelector((state) => state.user);
  function setClose() {
    setshow(!isshow);
  }

  async function setDefault(id) {
    const payload = {
      customerid:
        loggedInUser?.id || loggedInUser.customer?._id ,
      addressid: id,
    };

    await setDefaultAddress(token, payload)
      .then((res) => {
        toast.success("Address successfully set");
      })
      .catch((err) => {
        toast.error("Try Again");
      });
  }

  return (
    <div className="w-full">
      <h3 className="mb-1 font-semibold">Billing/Shipping Address</h3>
      <div className="relative border flex flex-col border-gray-200 rounded-3xl py-3 sm:py-6 px-2 sm:px-3 space-y-1 h-fit w-full">
        <div className="w-fit h-fit absolute top-2 right-2">
          <button
            onClick={setClose}
            className="p-2 hover:bg-gray-200 rounded-full "
          >
            <PiDotsThreeVerticalBold className="text-xl" />
          </button>
          {isshow && (
            <div className="absolute top-[30px] left-[-210px] sm:left-0">
              <div
                onClick={setClose}
                className="fixed bg-none h-full w-full inset-0 z-[45]"
              ></div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="relative w-[220px] p-2 h-fit border bg-white z-[60]"
              >
                <button
                  onClick={() => {
                    onClose(data);
                    setshow(!isshow);
                  }}
                  className="w-full flex justify-start items-start hover:bg-gray-200 py-2 text-xs sm:text-[12px] font-medium"
                >
                  Edit
                </button>

                {/* <button className="jhidden w-full flex justify-start items-start hover:bg-gray-200 py-2  text-xs sm:text-[12px] font-medium">
                  Mark as default billing address
                </button> */}

                <button
                  onClick={() => setDefault(data?._id)}
                  className="w-full flex justify-start items-start hover:bg-gray-200 py-2  text-xs sm:text-[12px] font-medium"
                >
                  Mark as default shipping address
                </button>
              </div>
            </div>
          )}
        </div>
        <p>{data?.address}</p>
        <p>{`${data?.city} ,${data?.state} State`}</p>
        <p>{data?.country}</p>
        <p>{data?.postalcode}</p>
      </div>
    </div>
  );
}
