import React from "react";
import Layout from "../../layout/layout";
import head from "../../../assets/png/head.jpg";
import CategoryFilter from "./filter/categoryFilter";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { BiFilterAlt } from "react-icons/bi";
import Widget from "./widget/catPageWidget";
import MobileFilterNav from "./filter/mobileFilterNav";
import { useEffect } from "react";
import empty from "../../../assets/png/emptyorder.png";
import { categoryProducts } from "../../../Utils/api";
import CatPageWidgetSkeleton from "./widget/catPageWidgetSkeleton";
export default function CategoriesPage() {
  const [isOpen, setOpen] = useState(false);
  const {search} = useLocation()
 const query = search.replace("?n=", "")
  const [activeCategory, setactiveCategory] = useState("");
  const [data, setdata] = useState(null);

  useEffect(() => {
    async function fetchData() {
      await categoryProducts({ category: query })
        .then((res) => {
          //console.log(res);
          const { data } = res.data;
          setdata(data);
        })
        .catch((err) => {
          //console.log(erFr);
        });
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  function onClose() {
    setOpen((prev) => !prev);
  }

  return (
    <>
      <Layout>
        <div className="w-full ">
          <div className="w-full h-[80px] sm:h-[100px]">
            <img src={head} alt="" className="w-full h-full object-cover" />
          </div>

          <div className="w-full  flex relative">
            <CategoryFilter activeid={query} setActiveCats={setactiveCategory} />
            <div className="w-full md:px-8 min-[900px]:px-4 xl:px-10  min-[900px]:w-[80%]">
              <div className="w-full p-2 my-3 sm:p-3 flex space-x-3 items-center">
                <div>
                  <p className="font-semibold capitalize">{activeCategory}</p>
                  <p className="text-gray-500 font-medium text-xs sm:text-sm">
                    {`Total ${data?.length || 0} products found`}
                  </p>
                </div>
                <button
                  onClick={onClose}
                  className="min-[900px]:hidden px-2 py-1 border border-gray-600 rounded-md flex items-center justify-center"
                >
                  <span>Filter</span> <BiFilterAlt className="text-[22px]" />
                </button>
              </div>
              <div className="grid grid-cols-2 p-2 mb-3 sm:p-3 justify-center  sm:grid-cols-2  min-[900px]:grid-cols-2 min-[1050px]:grid-cols-3 w-full items-center gap-6">
                {!data &&
                  [1, 2, 3, 4, 5, 6].map((i, j) => {
                    return (
                      <div key={j}>
                        <CatPageWidgetSkeleton />
                      </div>
                    );
                  })}
                {data && data?.length === 0 && (
                  <div className="w-full col-span-full row-span-full m-auto flex items-center justify-center h-[400px]">
                    <div className="w-[200px] h-[200px] ">
                      <img src={empty} alt="" className="w-full h-full" />
                    </div>
                  </div>
                )}
                {Array.isArray(data) &&
                  data?.map((item, idx) => {
                    const { coverimage, name, price, _id, discount } = item;
                    return (
                      <div key={_id}>
                        <Widget
                          image={coverimage}
                          name={name}
                          price={price}
                          discount={discount?.discount_price}
                          isdiscount={discount?.isdiscount}
                          id={_id}
                          nav={activeCategory}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </Layout>
      {isOpen && <MobileFilterNav activeid={query} close={onClose} />}
    </>
  );
}
