import React, { useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import logo from "../../../assets/png/smart.png";

export function NewDealsWidget({
  images,
  name,
  isDiscount,
  price,
  discountPrice,
  id,
  isVariant,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    function lazyImage() {
      const lazy = document.querySelectorAll("#deals");
      lazy.forEach((im) => {
        const newurl = im.getAttribute("src-data");
        im.src = newurl;

        im.addEventListener("error", () => {
          im.src = logo;
        });
      });
    }

    lazyImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function percentageDiscount(original, discount) {
    const result = ((original - discount) / original) * 100;

    return `${result.toFixed(0)}%`;
  }


  return (
    <div
      role="button"
      onClick={() => {
        if (isVariant) return;
        navigate(`/details?n=${id}`);
      }}
      className="cursor-pointer relative w-full sm:w-[280px] overflow-hidden gap-y-1 sm:gap-y-1 flex flex-col items-center  h-[250px] sm:h-[280px] rounded-lg font-semibold border space-y-4 sm:space-y-5 border-gray-300  shadow"
    >
      <div className="w-[200px] rounded-lg overflow-hidden relative h-[189px] sm:w-[280px]">
        
        <img
          src={logo}
          id="deals"
          src-data={images}
          alt=""
          className="w-full h-full  object-cover"
        />
      </div>
      <div className="px-3 sm:px-4 pb-4 w-full text-[12px] sm:text-[13px] text-black">
        <p className=" w-full  line-clamp-2 capitalize ">{name}</p>
        <div className="flex  w-full items-center justify-between">
          <div className="flex items-center space-x-1">
            <span>{`₦${(price || 0)?.toLocaleString()}`}</span>
            {!isVariant && isDiscount && (
              <span className="text-gray-500 line-through">{`₦${(
                Number(discountPrice)
              )?.toLocaleString()}`}</span>
            )}
          </div>
          {!isVariant && isDiscount && (
            <div className=" px-2 py-1 bg-gray-100 shadow rounded-lg">
              {percentageDiscount(price, Number(discountPrice))}
            </div>
          )}
        </div>
      </div>

     
    </div>
  );
}

