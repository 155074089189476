import React, { useState } from "react";

//import Loader from "../UI/Loader";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { GoogleLogoIcon } from "../atoms/icon";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { registration } from "../../Redux/Actions/ActionCreators";
import { LoaderIcon } from "lucide-react";
import { ReactCountries } from "../../Utils/countrydropdown/countries";
const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [country, setCountry] = useState("Nigeria");
  const [isLoading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const payload = { ...data, country };
    //console.log({ ...data, country });
    dispatch(registration(payload, navigate, setLoading));
  };

  return (
   
    
        <div className="max-w-[400px] p-2 sm:p-3 flex items-center justify-center flex-col  mx-auto w-full">
          <div className="flex flex-col gap-y-1 text-center sm:my-8">
            <h2 className="text-2xl font-medium font-heading">
              Welcome to Altinsmart
            </h2>
            <p className="leading-6 text-sm font-heading text-gray-600">
              Create an account with us today!
            </p>
          </div>

          <div className="w-full my-5">
            <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="relative form-group  mb-4 w-full">
                <input
                  type="text"
                  placeholder="Enter name"
                  className="w-full h-11 sm:h-12 border-gray-400 ring-offset-background focus:outline-none px-4 focus-visible:ring-2 placeholder:text-muted-foreground focus-visible:ring-ring focus-visible:ring-offset-2 rounded-3xl border "
                  {...register("name", {
                    required: true,
                  })}
                />
                {errors?.name?.type === "required" && (
                  <p className="mt-2 pl-4 text-red-500">Name is required</p>
                )}
              </div>
              <div className="relative form-group  mb-4 w-full">
                <input
                  type="email"
                  placeholder="Enter your email address"
                  className="w-full h-11 sm:h-12 border-gray-400 ring-offset-background focus:outline-none px-4 focus-visible:ring-2 placeholder:text-muted-foreground focus-visible:ring-ring focus-visible:ring-offset-2 rounded-3xl border "
                  {...register("email", {
                    required: true,
                  })}
                />
                {errors?.email?.type === "required" && (
                  <p className="mt-2 pl-4 text-red-500">Email is required</p>
                )}
              </div>
              <div className="relative form-group  mb-4 w-full">
                <input
                  type="password"
                  placeholder="Enter your password"
                  className="w-full h-11 sm:h-12 border-gray-400 ring-offset-background focus:outline-none px-4 focus-visible:ring-2 placeholder:text-muted-foreground focus-visible:ring-ring focus-visible:ring-offset-2 rounded-3xl border "
                  {...register("password", {
                    required: true,
                  })}
                />
                {errors?.password?.type === "required" && (
                  <p className="mt-2 pl-4 text-red-500">Password is required</p>
                )}
              </div>
              <div className="relative form-group  mb-4 w-full">
                <input
                  type="tel"
                  placeholder="Mobile or Phone Number"
                  inputMode="numeric"
                  name="phone"
                  pattern="[0-9]*"
                  className="w-full h-11 sm:h-12 border-gray-400 ring-offset-background focus:outline-none px-4 focus-visible:ring-2 placeholder:text-muted-foreground focus-visible:ring-ring focus-visible:ring-offset-2 rounded-3xl border "
                  {...register("phone", {
                    required: true,
                  })}
                />
                {errors?.phone?.type === "required" && (
                  <p className="mt-2 pl-4 text-red-500">
                    Phone number is required
                  </p>
                )}
              </div>
              <div className="w-full mb-4">
                <ReactCountries setCountry={setCountry} />
              </div>

              <button
                className="w-full text-white font-medium mb-5 h-11 flex items-center justify-center sm:h-12 rounded-3xl px-8 bg-[#B847EF] hover:bg-purple-400"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <LoaderIcon className="animate-spin" />
                ) : (
                  <span>Sign Up</span>
                )}
              </button>
            </form>

            <p className="hidden text-center text-sm sm:text-base mb-5">
              Already have an Account?{" "}
              <Link className="text-purple-600" to={"/login"}>
                Log In
              </Link>
            </p>

            <p className="text-center mb-3 text-sm sm:text-base font-medium">
              or
            </p>
            <button
              className="w-full bg-none flex gap-x-2 border items-center border-gray-400 h-11 sm:h-12 text-sm sm:text-base rounded-3xl px-8 justify-center"
              type="button"
            >
              <span>Continue with Google</span>
              <GoogleLogoIcon width={20} height={20} />{" "}
            </button>
          </div>
        </div>
     
  );
};

export default Register;
