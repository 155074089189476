import Input from "./input";
import React, { useState } from "react";
import { Loader2Icon } from "lucide-react";
import { retrieveSharedCart } from "../../Utils/api";
import toast from "react-hot-toast";
import { addToCart, calculateTotal } from "../../Redux/Actions/ActionCreators";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
export function AddPreviewCode({ close }) {
  const [code, setCode] = useState("");
  const { cartItems, deliveryPrice } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  async function shared() {
    setLoading(true);
    await retrieveSharedCart({ bookingcode: code })
      .then((res) => {
        const { data } = res?.data;

        let carts = data?.cart?.map((item) => {
          return {
            ...item,
            count: item?.quantity,
          };
        
        });
        carts?.forEach((item) => {
          dispatch(addToCart(item, cartItems, deliveryPrice));
          dispatch(calculateTotal(cartItems));

        });

        navigate("/cart");
        /**
         
       
        console.log({cart}, data)
        
         */
        //
      })
      .catch((err) => {
        /// console.log(err)
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
        close();
      });
  }
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        close();
      }}
      className={`share_wrap w-full h-full inset-0 fixed z-[80] bg-white/10 sm:bg-white/50`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="list swipeUp  share_box w-full sm:w-[500px] bottom-0 shadow sm:inset-0 absolute sm:m-auto flex flex-col item-start justify-start py-6 px-4 h-fit gap-y-4 rounded-t-3xl sm:rounded-b-md sm:rounded-t-md bg-white "
      >
        <button
        onClick={close}
        className=" absolute sm:hidden block inset-x-0 mx-auto transition-all duration-300 transform  top-1 bg-gray-100 rounded-3xl w-24 h-2"></button>
        <h2 className="text-base sm:text-lg font-semibold">Shared Cart</h2>
        <>
          <div className="w-full flex flex-col gap-y-3">
            <Input
              value={code}
              setValue={setCode}
              placeholder="Input code here ..."
              type="number"
            />
            <button
              onClick={shared}
              disabled={loading || code === ""}
              className="text-gray-50 self-end flex items-center justify-center gap-x-2 w-full sm:w-[140px] h-11 sm:h-12 font-medium bg-altinsmart rounded-3xl"
            >
              {loading && <Loader2Icon className="animate-spin" />}
              <p>Submit</p>
            </button>
          </div>
        </>
      </div>
    </div>
  );
}
