import React from "react";
import payment from "../../assets/png/payment.png";

export default function PaymentDetail({ paymentMethod, setPaymentMethod }) {
  return (
    <>
      <div className="w-full border my-2 sm:my-4 border-gray-400 space-y-5 sm:space-y-6 rounded-lg p-4">
        <div className="font-semibold">2. Delivery Details</div>

        <div className="w-full border my-2 sm:my-4 border-gray-400 space-y-5 sm:space-y-6 rounded-lg p-4">
          <div className="space-y-2 sm:space-y-3">
            <div className="flex w-full justify-between items-center">
              <label className="font-semibold relative order-container">
                <span className="">Pay with Cards, Transfer or USSD</span>

                <input
                  onChange={() => {
                    setPaymentMethod("Card");
                  }}
                  type="checkbox"
                  checked={paymentMethod === "Card"}
                />
                <span className="order-checkmark"></span>
              </label>
              <img src={payment} alt="" />
            </div>

            <button
              //  onClick={onClose}
              className="hidden ml-[30px] font-semibold text-[#b847fe] underline"
            >
              Card Information
            </button>
          </div>

          <div className="space-y-2 sm:space-y-3">
            <label className="font-semibold relative order-container">
              <span className="">Pay with wallet</span>

              <input
                onChange={() => {
                  setPaymentMethod("Wallet");
                }}
                type="checkbox"
                checked={paymentMethod === "Wallet"}
              />
              <span className="order-checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    </>
  );
}
