import Layout from "../layout/layout";
import HeaderWidget from "../headerwidget/headerWidget";
import { useLocation } from "react-router-dom";
import Widget from "../categories/categoriespage/widget/catPageWidget";

export default function More() {
  const { state } = useLocation();

  return (
    <>
      <Layout>
        <div className="my-2 sm:my-4">
          <HeaderWidget
            title={state?.heading}
            //text={"Enjoy discounted prices off quality products."}
          />

          <div className="mt-4 w-full grid grid-cols-2 px-2 sm:px-5 items-center sm:grid-cols-3 gap-4 lg:grid-cols-4">
            {state?.data?.length === 0 && (
              <div className="w-full col-span-full h-400px] flex flex-col items-center justify-center">
                <h2 className="font-semibold w-full text-center text-base sm:text-xl text-gray-300 ">{`No result for "${state?.heading}"`}</h2>
              </div>
            )}

            {Array.isArray(state?.data) &&
              state?.data?.map(
                ({ coverimage, name, price, _id, discount }, j) => (
                  <div key={j}>
                    <Widget
                      image={coverimage}
                      name={name}
                      price={price}
                      discount={discount?.discount_price}
                      isdiscount={discount?.isdiscount}
                      id={_id}
                      nav={state?.heading}
                    />
                  </div>
                )
              )}
          </div>
        </div>
      </Layout>
    </>
  );
}
