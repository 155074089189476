import React from "react";
import { BsCheck, BsCardChecklist } from "react-icons/bs";
import TrackerIcons from "../Composable/trackerIcons";
import {TbTruckDelivery} from 'react-icons/tb'
import {FaRegHandshake} from 'react-icons/fa'
export default function TrackerUI() {
  return (
    <div className="p-6 sm:p-4 my-4  border-b space-y-3 ">
      
<div className="w-full flex flex-row items-start justify-between sm:justify-start sm:items-start sm:flex-col">

<div className="flex flex-col sm:flex-row h-60 sm:h-auto sm:justify-center items-center w-fit sm:w-full">
        <div className="flex flex-col sm:flex-row items-center h-[50%] sm:w-[50%]">
          <div className="rounded-full relative h-[18px] w-[18px] sm:h-[25px] sm:w-[25px] flex items-center justify-center bg-gray-500">
            <BsCheck className="text-[22px] text-white" />
            <p className="absolute text-xs block sm:hidden -right-16">Accepted</p>
          </div>
          <div className="h-[95%] sm:w-[95%] w-1 sm:h-1 bg-gray-500"></div>
        </div>
        <div className="flex flex-col sm:flex-row items-center h-[50%] sm:w-[50%]">
          <div className="rounded-full relative h-[18px] w-[18px] sm:h-[25px] sm:w-[25px] flex items-center justify-center bg-gray-500">
          <BsCheck className="text-[22px] text-white" />
          <p className="absolute text-xs block sm:hidden -right-16">Shipping</p>
          </div>
          <div className="h-[95%] sm:w-[95%] w-1 sm:h-1 bg-gray-500"></div>
        </div>
       
        <div className="rounded-full relative h-[18px] w-[18px] sm:h-[25px] sm:w-[25px] flex items-center justify-center bg-gray-500">
          <BsCheck className="text-[22px] text-white" />
          <p className="absolute text-xs block sm:hidden -right-16">Delivered</p>
          </div>
         
     
      </div>
      <div className="flex flex-col sm:flex-row items-center justify-center h-60 sm:h-auto  sm:w-full">
        <div className="flex items-center flex-col sm:flex-row w-fit h-[50%] sm:h-auto sm:w-[50%]">
          <TrackerIcons
            icon={<BsCardChecklist className="text-[22px] text-gray-500" />}
            text={"Accepted"}
          />
        </div>

       

        <div className="flex items-center flex-col sm:flex-row w-fit h-[50%] sm:h-auto sm:w-[50%]">
          <TrackerIcons
            icon={<TbTruckDelivery className="text-[22px] text-gray-500" />}
            text={"Shipping"}
          />
        </div>

       
          <TrackerIcons
            icon={<FaRegHandshake className="text-[22px] text-gray-500" />}
            text={"Delivered"}
          />
       
      </div>
</div>
    </div>
  );
}
