import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import { Toaster } from "react-hot-toast";
import Scrolltotop from "./components/UI/ScrollToTop";
import { RequireAuth } from "./Utils/RequireAuth";
import {
  AuthLayout,
  Verification,
  ForgotPassword,
  Landing,
  Brands,
  Categories,
  TopDeals,
  Product,
  Cart,
  CheckOut,
  OrderTracker,
  Messaging,
  OrderHistory,
  UserDashboard,
  PurchaseHistory,
  RefundRequest,
  FollowedShops,
  MyQueries,
  MywWishlist,
  MyWallet,
  ManageProfile,
  CategoriesPage,
  ResetPassword,
  Search,
  More,
  BrandHome,
  BrandCategoryPage,
  Blog,
  ViewPost,
  AdvertPage,
  BrandSubCategoryPage,
} from "./Pages";

const App = () => {
  return (
    <div className="relative w-full h-full text-mobile sm:text-desktop inter text-zinc-700">
      <div>
        <Toaster
          toastOptions={{
            duration: 5000,
            position: "top-center",
            success: {
              style: {
                background: "#222",

                color: "#fff",
              },
            },
            error: {
              duration: 5000,
              position: "top-center",
              style: {
                background: "red",
                color: "#fff",
              },
            },
          }}
        />
      </div>
      <Router>
        <Scrolltotop />

        <Routes>
          {/*    AUTH PAGE */}
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/register" element={<AuthLayout />} />
          <Route exact path="/login" element={<AuthLayout />} />
          {/*    LANDING PAGE  */}
          <Route exact path="/brands" element={<Brands />} />
          <Route exact path="/brands-detail" element={<BrandHome />} />
          <Route exact path="/checkout" element={<CheckOut />} />
          <Route exact path="/order-tracker" element={<OrderTracker />} />
          <Route
            exact
            path="/order-tracker-detail"
            element={<OrderTracker />}
          />
          <Route exact path="/order-history" element={<OrderHistory />} />
          <Route exact path="/cart" element={<Cart />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/details" element={<Product />} />
          <Route exact path="/deals" element={<TopDeals />} />
          <Route exact path="/categories" element={<Categories />} />
          <Route exact path="/verification" element={<Verification />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route
            exact
            path="/user"
            element={
              <RequireAuth>
                <UserDashboard />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/messaging"
            element={
              <RequireAuth>
                <Messaging />
              </RequireAuth>
            }
          />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/brands-subcategory-detail" element={<BrandSubCategoryPage />} />
          <Route exact path="/blog-detail" element={<ViewPost />} />
          <Route exact path="/advert" element={<AdvertPage />} />
          <Route exact path="/search" element={<Search />} />
          <Route
            exact
            path="/user-purchase-history"
            element={
              <RequireAuth>
                <PurchaseHistory />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/user-refund-request"
            element={
              <RequireAuth>
                <RefundRequest />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/user-followed-shops"
            element={
              <RequireAuth>
                <FollowedShops />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/user-my-queries"
            element={
              <RequireAuth>
                <MyQueries />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/user-my-wishlist"
            element={
              <RequireAuth>
                <MywWishlist />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/user-my-wallet"
            element={
              <RequireAuth>
                <MyWallet />
              </RequireAuth>
            }
          />
          <Route exact path="/category-detail" element={<CategoriesPage />} />
          <Route
            exact
            path="/user-my-profile"
            element={
              <RequireAuth>
                <ManageProfile />
              </RequireAuth>
            }
          />
          <Route exact path="/more" element={<More />} />
          <Route
            exact
            path="/brands-category-detail"
            element={<BrandCategoryPage />}
          />
          F{/**Protected routes */}
        </Routes>
      </Router>
    </div>
  );
};

export default App;
