import React, { useState } from "react";
import Input from "../../Composable/input";
import { MdClose } from "react-icons/md";

export default function AddAddress({close}) {
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [states, setState] = useState("");
  const [address, setAddress] = useState("");
  return (
    <div
    onClick={close}
    className="w-full h-full bg-white bg-opacity-40 fixed inset-0 z-20">

      <div
      onClick={(e) => {
        e.stopPropagation()
      }}
      className="bg-white shadow-xl rounded-lg w-[90%] sm:w-[600px] absolute inset-0 h-fit p-4 sm:p-6 m-auto">
        
       <div className="flex w-full items-center justify-between">
       <h2 className="font-semibold text-base sm:text-lg mb-3">
          Add Shipping Address
        </h2>
        <div
        onClick={close}
        className=" cursor-pointer">
        <MdClose className="text-[24px]"/>
        </div>
       </div>

        <Input
          header={"Enter Your Full Name"}
          placeholder={"e.g Ikecchkwu Emmanuel"}
          type={"text"}
          value={name}
          setValue={setName}
        />

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 w-full items-center">
          <Input
            header={"Country /Region"}
            placeholder={"e.g Nigeria"}
            type={"text"}
            value={country}
            setValue={setCountry}
          />
          <Input
            header={"Phone"}
            placeholder={"e.g 08156453434"}
            type={"tel"}
            value={phone}
            setValue={setPhone}
          />
        </div>
        <div className="grid grid-cols-1 gap-4  sm:grid-cols-3 w-full items-center">
          <Input
            header={"State"}
            placeholder={"e.g Lagos"}
            type={"text"}
            value={states}
            setValue={setState}
          />
          <Input
            header={"City"}
            placeholder={"e.g Ibadan"}
            type={"text"}
            value={city}
            setValue={setCity}
          />
          <Input
            header={"Zip Code"}
            placeholder={"e.g 123456"}
            type={"number"}
            value={zip}
            setValue={setZip}
          />
        </div>

        <Input
          header={"Address"}
          placeholder={"e.g Adebiyi Street"}
          type={"text"}
          value={address}
          setValue={setAddress}
        />

        <div className="mt-2 w-full flex items-end justify-end space-x-3">
        <button
        onClick={close}
        className="flex items-center px-4 py-2 font-semibold rounded-3xl border border-[#b847fe] sm:py-3 sm:px-8 text-[#b847fe]">
      
            <span>Cancel</span>
        </button>
        <button className="flex items-center px-4 py-2 font-semibold rounded-3xl bg-[#b847fe] sm:py-3 sm:px-8 text-white space-x-2">
        Save
        </button>

        </div>
      </div>
      
    </div>
  );
}
