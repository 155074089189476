import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { GoogleLogoIcon } from "../atoms/icon";
import { LoginAction } from "../../Redux/Actions/ActionCreators";
import { LoaderIcon } from "lucide-react";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    //console.log(data);

    dispatch(LoginAction(data, navigate, setLoading));
  };

  return (
    <div className="max-w-[400px] p-2 sm:p-3  flex items-center justify-center flex-col mx-auto w-full">
      <div className="flex flex-col items-center justify-center gap-y-1 my-8 ">
        <h2 className="text-2xl font-medium font-heading">Welcome Back</h2>
        <p className="leading-6 text-sm font-heading text-gray-600">
          Enter your email and password!
        </p>
      </div>

      <div className="w-full my-5">
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="relative form-group  mb-4 w-full">
            <input
              type="email"
              placeholder="Enter your email address"
              className="w-full h-11 sm:h-12 ring-offset-background focus:outline-none px-4 focus-visible:ring-2 placeholder:text-muted-foreground focus-visible:ring-ring focus-visible:ring-offset-2 rounded-3xl border border-gray-400 "
              {...register("email", {
                required: true,
              })}
            />
            {errors?.email?.type === "required" && (
              <p className="mt-2 pl-4 text-red-500">Email is required</p>
            )}
          </div>
          <div className="relative form-group  mb-4 w-full">
            <input
              type="password"
              placeholder="Enter your password"
              className="w-full h-11 sm:h-12 ring-offset-background focus:outline-none px-4 focus-visible:ring-2 placeholder:text-muted-foreground focus-visible:ring-ring focus-visible:ring-offset-2 rounded-3xl border border-gray-400"
              {...register("password", {
                required: true,
              })}
            />
            {errors?.password?.type === "required" && (
              <p className="mt-2 pl-4 text-red-500">Password is required</p>
            )}
          </div>

          <div className="w-full mb-5 flex justify-end items-end">
            <Link
              to="/forgot-password"
              className="font-semibold text-[#B847EF]"
            >
              Forgot Password?
            </Link>
          </div>

          <button
            className="w-full text-white mb-5 h-11 sm:h-12 flex items-center justify-center rounded-3xl px-8 bg-[#B847EF] hover:bg-purple-400"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <LoaderIcon className="animate-spin" />
            ) : (
              <span>Log in</span>
            )}
          </button>
        </form>

        <p className="hidden text-center mb-5">
          Don&#39;t have an Account?{" "}
          <Link className="text-purple-600" to={"/register"}>
            register
          </Link>
        </p>

        <p className="text-center mb-3  font-medium">or</p>

        <button
          className="w-full bg-none flex gap-x-2 items-center border border-gray-400 h-11 sm:h-12 rounded-3xl px-8 justify-center"
          type="button"
        >
          <span>Continue with Google</span>
          <GoogleLogoIcon width={20} height={20} />{" "}
        </button>
      </div>
    </div>
  );
};

export default Login;
