import React from "react";
import { Rating } from "../../../Composable/Rating";
import { formatDate } from "../../../../Utils/formatNumber";
export default function CustomerReview({item}) {
  return (
    <div className="border p-4 border-gray-300 space-y-3 sm:space-y-4 overflow-hidden rounded-lg w-[250px] sm:w-[300px] h-[200px]">
      <div className="flex items-center">
      
        <div>
          <p className="font-semibold capitalize">{item?.customerid?.name ?? ""}</p>
         <Rating rating={item?.rating || 0} size={20}/>
          <p className="text-gray-500 text-xs sm:text-sm">{formatDate(item?.createdAt) ?? ""}</p>
        </div>
      </div>
      <div className="space-y-2">
        <h3 className="font-semibold">Review</h3>
        <div className="flex flex-wrap items-start justify-start text-ellipsis line-clamp-3  overflow-hidden">
         {item?.review ?? ""}
        </div>
      </div>
     
    </div>
  );
}
