import { Link, useLocation } from "react-router-dom";
import { GoPlus } from "react-icons/go";
import Layout from "../../layout/layout";
import { useEffect, useMemo, useState } from "react";
import { BrandCategories } from "../_components/BrandCategories";
import { retrieveStore } from "../../../Utils/api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CatPageWidgetSkeleton from "../../categories/categoriespage/widget/catPageWidgetSkeleton";
import Widget from "../../categories/categoriespage/widget/catPageWidget";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { FiMinus } from "react-icons/fi";
import {
  followStore,
  unfollowStore,
  retrieveFollowed,
} from "../../../Utils/api";
export default function BrandHome() {
  const [isOpen, setOpen] = useState(false);
  const [, setLoading] = useState(false);
  const [followedStores, setFollowedStores] = useState([]);
  const { token, loggedInUser } = useSelector((state) => state.user);
  const { search } = useLocation();
  const query = search.replace("?n=", "");
  const [data, setData] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    fade: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    //prevArrow: false,
    //nextArrow: false,
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await retrieveStore({ sellerid: query })
        .then((res) => {
          const { data } = res?.data;
          setData(data);
          setLoading(false);
        })
        .catch((err) => {
          //console.log(err)
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onClose() {
    setOpen((prev) => !prev);
  }

  useEffect(() => {
    (async () => {
      if (!token) return;
      const payload = {
        customerid: loggedInUser?.id || loggedInUser?.customer?._id,
      };
      await retrieveFollowed(token, payload)
        .then((res) => {
          //console.log(res);
          const { data } = res?.data;

          setFollowedStores(data);
        })
        .catch((err) => {
          //console.log(err);
        });
    })();
  }, [token, loggedInUser]);

  async function addtoFollowed() {
    if (!token) {
      toast.error("You have to log in to add to your favorites");
      return;
    }
    const payload = {
      customerid: loggedInUser?.id || loggedInUser?.customer?._id,
      sellerid: query,
    };
    await followStore(token, payload)
      .then((res) => {
        toast.success("Shop Followed");
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  }

  async function removefromFollowed() {
    if (!token) {
      toast.error("You have to log in to remove from your favorites");
      return;
    }
    const payload = {
      customerid: loggedInUser?.id || loggedInUser?.customer?._id,
      sellerid: query,
    };

    await unfollowStore(token, payload)
      .then((res) => {
        //console.log(res);
        toast.success("Shop Unfollowed");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        //console.log(err);
      });
  }

  const isFollowing = useMemo(() => {
    return followedStores?.some(({ _id }) => _id === query);
  }, [query, followedStores]);

  const Comp = data?.store?.images?.length > 1 ? Slider : "div"
  return (
    <Layout hideDesktopMessaging>
      <div className="w-full">
        {!data ? (
          <div className="w-full z-10 mb-6 h-36 sm:h-[250px]  animate-pulse">
            <div className="w-full h-full bg-gray-200"></div>
          </div>
        ) : (
          <Comp
            className="brand-seller w-full mb-6 z-10 h-36 sm:h-[250px]"
            {...settings}
          >
            {Array.isArray(data?.store?.images) &&
              data?.store?.images.map(({ img }, index) => (
                <div
                  key={`${img}${index}`}
                  className="w-full h-36 sm:h-[250px] relative "
                >
                  <div className="w-full h-full absolute px-8 sm:px-10 py-8 sm:py-10 inset-0 bg-black/50 z-10">
                    <img
                      src={data?.store?.logo}
                      alt=""
                      className="object-contain"
                    />
                  </div>
                  <img
                    src={img}
                    className="w-full h-full object-cover"
                    alt="banner"
                  />
                </div>
              ))}
          </Comp>
        )}

        <div className="w-full bg-white border-y shadow p-2 flex justify-between items-center sm:p-4">
          <ul className="flex items-center gap-x-0 lg:gap-x-3">
            <li>
              <button
                onClick={() => {
                  if (isFollowing) {
                    removefromFollowed();
                  } else {
                    addtoFollowed();
                  }
                }}
                className="flex items-center rounded-3xl bg-[#b847fe] text-white gap-x-2 justify-center px-4 h-12"
              >
                <span>{isFollowing ? "UnFollow" : "Follow"}</span>
                {isFollowing ? (
                  <FiMinus className="text-[22px]" />
                ) : (
                  <GoPlus className="text-xl" />
                )}
              </button>
            </li>

            <li>
              <Link
                to={`/brands-detail?n=${query}`}
                className="hover:border-b hidden sm:block border-zinc-700 p-2"
              >
                Home
              </Link>
            </li>

            <>
              {Array.isArray(data?.storecategories) &&
                data?.storecategories
                  .slice(0, 3) // Only consider the first three elements
                  .map(({ _id, category }) => (
                    <li key={_id}>
                      <Link
                        to={`/brands-category-detail?n=${_id}&b=${query}`}
                        className=" hover:border-b hidden lg:flex border-zinc-700 p-2"
                      >
                        {category}
                      </Link>
                    </li>
                  ))}
            </>

            <li>
              <button
                onClick={onClose}
                className="hover:border-b hidden sm:block border-zinc-700 p-2"
              >
                More
              </button>
            </li>
          </ul>

          {/**
             <form className="w-[70%] sm:w-72">
              <input
                type="search"
                placeholder="search [brand name]"
                className="border w-full sm:w-72 h-11 outline-none rounded-3xl bg-[#b847fe] bg-opacity-30 sm:bg-none focus:border-gray-400 pl-4 pr-10 "
              />
            </form>
            */}
        </div>

        <div className="w-full grid grid-cols-1 my-6 sm:my-8 sm:grid-cols-2 p-2 items-center gap-x-3 gap-y-6 ">
          {/** if the number of sellers categories is not an even number add any of his product to make it even */}
          {!data?.storecategories &&
            [1, 2, 3, 4].map((_) => (
              <div className="w-full z-10 mb-6 h-36 sm:h-[250px]  animate-pulse">
                <div className="w-full h-full bg-gray-200"></div>
              </div>
            ))}
          {Array.isArray(data?.storecategories) &&
            data?.storecategories?.map(({ _id, category, categoryurls }) => (
              <Link
                key={_id}
                to={`/brands-category-detail?n=${_id}&b=${query}`}
                className="rounded-lg w-full h-44 sm:h-72 overflow-hidden relative"
              >
                <div className="absolute inset-0 bg-black/40 h-full w-full">
                  <p className="capitalize absolute bottom-0 left-0 p-3 sm:p-4 rounded-tr-lg bg-black/60 sm:bottom-0 sm:left-0 text-white font-semibold text-base sm:text-lg">
                    {category}
                  </p>
                </div>
                <img
                  src={categoryurls[0]?.categoryurl}
                  alt="category"
                  className="w-full h-full object-cover"
                />
              </Link>
            ))}
        </div>

        <div className="grid grid-cols-2 p-2 mb-3 sm:p-3 justify-center  sm:grid-cols-3  min-[900px]:grid-cols-3 min-[1050px]:grid-cols-3 xl:grid-cols-4 w-full items-center gap-6">
          {!data?.sellerproduct &&
            [1, 2, 3, 4, 5, 6].map((i, j) => {
              return (
                <div key={j}>
                  <CatPageWidgetSkeleton />
                </div>
              );
            })}
          {Array.isArray(data?.sellerproduct) &&
            data?.sellerproduct?.map((item) => {
              const { coverimage, name, price, _id, discount } = item;
              return (
                <div key={_id}>
                  <Widget
                    image={coverimage}
                    name={name}
                    price={price}
                    discount={discount?.discount_price}
                    isdiscount={discount?.isdiscount}
                    id={_id}
                    nav={data?.name ?? "Brand"}
                  />
                </div>
              );
            })}
        </div>
      </div>

      {isOpen && (
        <BrandCategories close={onClose} data={data?.storecategories} />
      )}
    </Layout>
  );
}

/*!loading ? (
         ) : (
          <div className="w-full h-[300px] flex items-center justify-center">
            <Loader2Icon size={32} className="animate-spin" />
          </div>
        )*/
