import React, { createContext, useState } from "react";
import ProductImageWidget from "./widget/productImageWidget";
import ProductTileWidget from "./widget/productTitleWidget";
import ProductDescription from "./widget/produtDescription";
import Reviews from "./widget/reveiw/review";
import AltProducts from "./widget/altProducts";
import Layout from "../layout/layout";
import { useEffect } from "react";
import { noticeView, retrieveSingleProduct } from "../../Utils/api";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { MdNavigateNext } from "react-icons/md";
import ProductGallery from "./widget/ProductGallery";
import ProductInformation from "./widget/ProductInfo";
import { useSelector } from "react-redux";
import { Loader2Icon } from "lucide-react";

export const DetailContext = createContext();
export default function Product() {
  const [detaildata, setdetaildata] = useState(null);
  const { loggedInUser } = useSelector((state) => state.user);
  const {search} = useLocation()
 const query = search.replace("?n=", "")
  const navigate = useNavigate();
  const { state } = useLocation();
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [customerReviews, setCustomerReviews] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchdata() {
      setLoading(true);
      await retrieveSingleProduct({ productid: query })
        .then((res) => {
          //console.log(res)
          const { data } = res.data;
          setdetaildata(data?.product);
          setRelatedProducts(data?.relatedproduct);
          setCustomerReviews(data?.review);
        })
        .catch((err) => {
          //console.log(err)
        })
        .finally(() => {
          setLoading(false);
        });
    }
    fetchdata();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // send view
  useEffect(() => {
    (async () => {
      if (loggedInUser?.id || loggedInUser.customer?._id) {
        await noticeView({
          productid: query,
          customerid: loggedInUser?.id || loggedInUser.customer?._id,
        });
      }
    })();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full">
      <Layout>
        <DetailContext.Provider value={{ detaildata }}>
          {loading ? (
            <div className="w-full h-96 flex items-center justify-center">
              <Loader2Icon size={30} className="text-altinsmart animate-spin" />
            </div>
          ) : (
            <div className="w-full my-4 space-y-4 sm:space-y-6 ">
              <div className="flex sm:px-6 px-4 items-center">
                <Link className="hover:text-[#b847fe] font-medium" to="/">
                  Home
                </Link>
                <button>
                  <MdNavigateNext className="text-[#b847fe] text-[22px]" />
                </button>
                <div
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="hover:text-[#b847fe] cursor-pointer font-medium capitalize"
                >
                  {state?.navtitle}
                </div>
                <button>
                  <MdNavigateNext className="text-[#b847fe] text-[22px]" />
                </button>
                <div className="hover:text-[#b847fe] capitalize font-medium">
                  {detaildata?.name}
                </div>
              </div>
              <div className="grid sm:px-6 w-full items-center min-[900px]:items-start my-2 sm:my-4 grid-cols-1 min-[900px]:grid-cols-6 gap-4 sm:gap-6">
                <ProductImageWidget />
                <ProductTileWidget />
              </div>

              <ProductDescription />

              <ProductGallery />
              <ProductInformation />
              <Reviews customerReviews={customerReviews} />
              <AltProducts relatedProducts={relatedProducts} />
            </div>
          )}
        </DetailContext.Provider>
      </Layout>
    </div>
  );
}
