import React from "react";
import { Link, useLocation } from "react-router-dom";
import { MdClose } from "react-icons/md";


export function BrandCategories({ close, data }) {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        close();
      }}
      className="fixed h-full w-full z-[100]  bg-white bg-opacity-50  inset-0"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="absolute top-0 right-0 w-[220px] sm:w-[350px] overflow-y-auto h-full bg-white let swipeIn shadow-md  py-5"
      >
        <div onClick={close} className="absolute top-3 right-2">
          <MdClose className="text-[22px]" />
        </div>

        <div className="flex w-full flex-col  pb-6 justify-start items-start">
          <IconsText text={"Home"} link={"/"} />
          {Array.isArray(data) &&
            data?.map(({ _id, category }, index) => (
              <IconsText
                key={_id}
                text={category}
                link={`/brands-category-detail?n=${_id}`}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

function IconsText({ text, link }) {
  const { pathname } = useLocation();
  return (
    <Link
      to={link}
      className={`${
        pathname === link ? "text-[#b847fe]" : ""
      } gap-x-2 flex border-b hover:bg-gray-200 w-full  p-4 items-center`}
    >
      <p>{text}</p>
    </Link>
  );
}
