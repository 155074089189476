import React from "react";
import Layout from "../layout/layout";
import product from "../../assets/png/product.png";
import { MdNavigateBefore } from "react-icons/md";
import { useNavigate } from "react-router-dom";
export default function OrderHistory() {
  const navigate = useNavigate();
  return (
    <>
      <Layout>
        <div className="w-full mt-4 px-4 sm:px-6 space-y-5 sm:space-y-6">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="flex items-center text-[#b847fe] space-x-1 sm:space-x-2"
          >
            <MdNavigateBefore className="text-[22px]" />
            <span>Back</span>
          </button>
          <div className="w-full border my-2 sm:my-4 border-gray-500 space-y-5 sm:space-y-6 rounded-lg p-2 sm:p-4">
            <div className="font-semibold">Order History</div>
          </div>
          <div className="w-full border my-2 sm:my-4 border-gray-500 space-y-5 sm:space-y-6 rounded-lg p-2 sm:p-4">
            <div className="flex items-center flex-col md:flex-row justify-between w-full gap-6">
              <div className="flex flex-col md:flex-row items-center justify-center md:justify-start md:items-start md:space-x-6">
                <div className="flex items-start space-x-6">
                  <div className="w-[100px] h-[100px]">
                    <img
                      src={product}
                      alt=""
                      className="w-full h-full object-cover "
                    />
                  </div>
                </div>
                <div className="mt-2 space-y-3">
                  <div className="flex flex-wrap font-semibold justify-center items-center md:justify-start md:items-start">
                    Asus TUF DASH INTEL CORE I5, 16GB RAM, 512GB SSD 15.6" GTX
                    1650 (4GB) WINS 10
                  </div>
                  <div className="text-xs sm:text-[13px] flex items-center space-x-2">
                    <span>Seller:</span>
                    <span className="text-[#b847fe]">Asus</span>
                  </div>
                  <div className="text-xs sm:text-[13px] flex items-center space-x-2">
                    <span>Qty:</span>
                    <span className="">x2</span>
                  </div>
                  <div className=" flex items-center space-x-2">
                    <span>Price:</span>
                    <span className="font-semibold">₦69,000</span>
                  </div>
                </div>
              </div>

              <div className="space-y-3 w-full md:w-fit">
                <button
                  onClick={() => {
                    navigate("/order-history");
                  }}
                  className="flex items-center justify-center w-full md:w-fit py-2 font-semibold rounded-3xl border border-[#b847fe] px-10 text-[#b847fe] "
                >
                  <span>Re-Order</span>
                </button>
                <button
                  onClick={() => {
                    navigate("/order-tracker");
                  }}
                  className="flex items-center justify-center py-2 w-full md:w-fit font-semibold rounded-3xl bg-[#b847fe] px-8 text-white space-x-2"
                >
                  Track Order
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
