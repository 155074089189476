
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

export function useSpeechRecognitionHook() {
  const { transcript, listening } = useSpeechRecognition();

  const startListening = () => {

    SpeechRecognition.startListening({ continuous: true });
  };

  const stopListening = () => {
    //resetTranscript();
    SpeechRecognition.stopListening();
    // recognition.stop();
  };

 

  return {
    text: transcript,
    startListening,
    listening,
    stopListening,
  };
}
