import React from "react";
import { TodayDealsWidget } from "../../groupwidget/widget/todayWidget";

export default function AltProducts({relatedProducts}) {


  
    return (
        <div className="sm:px-6 px-4 py-3 flex flex-col overflow-x-hidden my-2 sm:my-4 space-y-3 sm:space-y-5 text-gray-500  sm:py-4  w-full">
        <div className="py-2 border-b-2 border-gray-300 w-full ">
        <h2 className="text-zinc-800 font-semibold text-base sm:text-xl">
        Related Products
      </h2>
        </div>

        <div className="w-full scroll-style overflow-x-auto py-4 items-center ">
          <div className="min-w-max  flex items-center space-x-4 sm:space-x-6">
            {Array.isArray(relatedProducts) && relatedProducts?.map((product, idx) => {
              return (
                <div key={idx}>
                  <TodayDealsWidget product={product} />
                </div>
              );
            })}
          </div>
        </div>

        </div>
    )
}