import React, { useState } from "react";
import AddAddress from "./popupmodals/addAddress";
import { useNavigate } from "react-router-dom";

export default function DeliveryAddress({ address, loading }) {
  const [isclose, setclose] = useState(false);
  const navigate = useNavigate();

  function onClose() {
    setclose(!isclose);
  }

  return (
    <>
      <div className="w-full border my-2 sm:my-4 border-gray-400 space-y-5 sm:space-y-6 rounded-lg p-4">
        <div className="font-semibold">1. Customer Address</div>

        {loading ? (
          <div className="h-20 text-center">Loading...</div>
        ) : !address ? (
          <div className="h-20 text-center">You have no address yet</div>
        ) : (
          <div className="flex flex-col  py-3 px-2 sm:px-3 space-y-1 h-fit w-full">
            <p>{address.address}</p>
            <p>
              {address.state}, {address.city}
            </p>
            {/* <p>{add.state}</p> */}
            <p>{address.country}</p>
            <p>{address.postalcode}</p>
          </div>
        )}

        <button
          onClick={() => navigate("/user/my-profile")}
          className="border border-gray-400 p-2 rounded-md flex space-x-2 items-center ml-2"
        >
          {address ? (
            <span>Change Shipping address</span>
          ) : (
            <>
              <span className="text-lg">+</span>
              <span>Add Shipping Address</span>
            </>
          )}
        </button>
      </div>
      {isclose && <AddAddress close={onClose} />}
    </>
  );
}
