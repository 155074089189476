import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export default function Summation() {
  const { numOfCartItems, overallPrice, deliveryPrice } = useSelector(
    (state) => state.cart
  );

  useEffect(() => {
    const total = overallPrice + deliveryPrice;
    localStorage.setItem("totalCart", total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full border my-2 sm:my-4 shadow bg-white space-y-5 sm:space-y-6 rounded-lg p-4">
      <p className="font-semibold text-[15px] sm:text-base">
        Complete Purchase{" "}
        <span className="text-gray-500">{`(${numOfCartItems})`}</span>
      </p>
      <div className="flex items-center justify-between w-full font-medium">
        <span>SubTotal</span>
        <span className="font-semibold text-[15px] sm:text-base">{`₦${overallPrice?.toLocaleString()}`}</span>
      </div>
      <div className="flex items-center justify-between w-full font-medium">
        <span>Delivery</span>
        <span className="font-semibold text-[15px] sm:text-base">{`₦${(
          deliveryPrice || 0
        )?.toLocaleString()}`}</span>
      </div>
      <div className="flex items-center justify-between w-full font-medium">
        <span>Total</span>
        <span className="font-semibold text-[15px] sm:text-base">{`₦${(
          Number(overallPrice || 0) + Number(deliveryPrice || 0)
        ).toLocaleString()}`}</span>
      </div>
    </div>
  );
}
