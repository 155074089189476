import React from "react";
import HeaderWidget from "../headerwidget/headerWidget";
import { GroupWidget } from "../groupwidget/groupWidget";

import Layout from "../layout/layout";
export default function TopDeals() {
  return (
    <>
      <Layout>
        <div className="my-2 sm:my-4">
          <HeaderWidget
            title={"Top Deals"}
            text={"Enjoy discounted prices off quality products."}
          />
          <GroupWidget
            type={"top-deal"}
            more={true}
            heading={"Phones and Tablets"}
          />
          <GroupWidget
            type={"top-deal"}
            more={true}
            heading={"Gaming Laptops"}
          />
          <GroupWidget type={"top-deal"} more={true} heading={"Electronics"} />
          <GroupWidget type={"top-deal"} more={true} heading={"Accessories"} />
        </div>
      </Layout>
    </>
  );
}
