import React from "react";
import { useContext } from "react";
import { DetailContext } from "../productDetail";

export default function ProductInformation() {
  const { detaildata } = useContext(DetailContext);

  if (
    Array.isArray(detaildata?.productinfo) &&
    detaildata?.productinfo?.length > 0
  ) {
    return (
      <div className="sm:px-6 px-4 w-full space-y-3 sm:space-y-5 my-2 sm:my-4">
        <h2 className="font-semibold text-base sm:text-xl">
          Product Information
        </h2>
        <div className="w-full grid grid-cols-1 items-center sm:grid-cols-2">
          {Array.isArray(detaildata?.productinfo) &&
            detaildata?.productinfo.map(({ name, value }, index) => (
              <Widget key={index} name={name} value={value} />
            ))}
        </div>
      </div>
    );
  }
}

function Widget({ name, value }) {
  return (
    <div className="w-full flex border  h-fit">
      <div className="bg-[#b847fe] py-4 bg-opacity-30  h-full px-4 border-r w-[30%]">
        {name}
      </div>
      <div className=" h-full px-4 py-4  w-[70%]">{value}</div>
    </div>
  );
}
