import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export function TodayDealsWidget({ product }) {
  const navigate = useNavigate();

  const discountPercentage = useMemo(() => {
    const result =
      ((Number(product?.price) - Number(product?.discount?.discount_price)) /
        Number(product?.price)) *
      100;

    return `${result.toFixed(0)}%`;
  }, [product?.discount?.discount_price, product?.price]);
  return (
    <div
      role="button"
      onClick={() => {
        navigate(`/details?n=${product?._id}`);
      }}
      className="cursor-pointer overflow-hidden w-[200px] sm:w-[280px] gap-y-1 space-y-2 sm:space-y-3 flex flex-col items-center justify-center h-[210px] sm:h-[260px] rounded-lg font-semibold border border-gray-300  hover:border-gray-600"
    >
      <div className="w-full h-[160px] sm:h-[180px]">
        <img
          src={product?.coverimage}
          alt=""
          className="w-full h-full object-cover"
        />
      </div>
      <div className="w-full p-4 text-[12px] sm:text-[13px] text-black">
        <p className="whitespace-nowrap text-ellipsis w-full overflow-hidden  ">
          {product?.name ?? ""}
        </p>
        <div className="flex  w-full  items-center justify-between">
          <div className="flex items-center space-x-1">
            <span>{`₦${Number(product?.price)?.toLocaleString()}`}</span>
            <span className="text-gray-500 line-through">{`₦${Number(
              product?.discount?.discount_price
            )?.toLocaleString()}`}</span>
          </div>
          {product?.discount?.isdiscount && (
            <div className="text-white px-2 py-1 bg-[#B847EF] rounded-lg">
              {discountPercentage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
