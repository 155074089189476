import React from "react";
import logo from "../../assets/png/smart.png";
import Register from "./register";
import Login from "./login";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdNavigateBefore } from "react-icons/md";
export default function AuthLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <div className="let swipeIn flex min-h-screen text-sm sm:text-base bg-[#ECF1F4] relative">
      <div
        onClick={() => {
          navigate(-1);
        }}
        className="absolute cursor-pointer left-3 top-3 w-fit h-fit flex items-center space-x-2"
      >
        <MdNavigateBefore className="text-[22px]" />
        <span>Back</span>
      </div>
      <div className="w-full pb-20  flex items-center justify-center flex-col px-6 md:px-4">
        <div className="max-w-[400px] space-y-2 flex items-center justify-center flex-col mx-auto w-full">
         <div className="w-[70px] sm:w-[90px] h-[40px] mt-6 sm:h-[64px]">
         <img
            onClick={() => {
              navigate("/");
            }}
            src={logo}
            alt=""
            className="w-full h-full"
          />
         </div>
          <div className="w-full h-fit border  rounded-3xl">
            <div className="w-full h-fit flex flex-col">
              <FilterButton
                text={"Create an account."}
                subtext={"New to Altinsmart?"}
                link={"/register"}
                btn={1}
              />

              {pathname.includes("register") && <Register />}
            </div>
            <div className="w-full h-fit flex flex-col ">
              <FilterButton
                text={"Sign in."}
                subtext={"Already a customer?"}
                link={"/login"}
                btn={2}
              />

              {pathname.includes("login") && <Login />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function FilterButton({ text, subtext, link, btn }) {
  const { pathname } = useLocation();
  return (
    <Link
      to={link}
      className={`w-full h-fit px-2 bg-gray-300  border-b sm:px-3 py-2 sm:py-4 
       ${
         (pathname.includes("register") && btn === 1) ||
         (pathname.includes("login") && btn === 1)
           ? "rounded-t-3xl"
           : ""
       }
      ${pathname.includes("register") && btn === 2 ? "rounded-b-3xl" : ""}`}
    >
      <label className="relative order-container">
        <span className="font-semibold">{text}</span> <span>{subtext}</span>
        <input type="checkbox" checked={pathname === link} />
        <span className="order-checkmark"></span>
      </label>
    </Link>
  );
}
