import React from 'react';
import AccountSideNav from './accountSideNav';
import AccountTopNav from './subcomponents/accountTopNav';
import TopBar from '../landing/topbar/topBar';
import MobileNav from '../landing/subcomponents/mobileNav';
import Footer from '../footer/footer';

export default function UserContainer({children}) {
    return (
        <>
        <TopBar/>
        <div className='w-full h-full px-2 pt-[81px] sm:px-6 flex items-start'>
            <AccountSideNav/>
            <div className='w-full min-[900px]:w-[80%] h-fit'>
                <AccountTopNav/>
                <div className='w-full h-fit px-2 sm:px-4 py-3 sm:py-4'>
                {children}
                </div>

            </div>

        </div>
        <MobileNav/>
        <Footer/>
        </>
    )
}