import React, { useContext, useEffect, useMemo, useState } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { GoDotFill } from "react-icons/go";
import { useSelector, useDispatch } from "react-redux";
import { DetailContext } from "../productDetail";
import { useNavigate } from "react-router-dom";
import { FavouriteButton } from "../../Composable/favouriteButton";
import { MdClose } from "react-icons/md";
import { Rating } from "../../Composable/Rating";
import toast from "react-hot-toast";
import { AddItemToCart } from "../../Composable/AddItemToCart";
import {
  addToCart,
  calculateTotal,
} from "../../../Redux/Actions/ActionCreators";
export default function ProductTileWidget() {
  const dispatch = useDispatch();
  const { cartItems, deliveryPrice } = useSelector((state) => state.cart);
  const { detaildata } = useContext(DetailContext);
  const navigate = useNavigate();
  const [rearrangedVariant, setRearrangedVariant] = useState([]);
  const [restructuredVariant, setRestructuredVariant] = useState(null);
  const [checkedAttribute, setCheckedAttribute] = useState([]);
  const [activePrice, setActivePrice] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [variantProduct, setVariantProduct] = useState(null);

  function onClose() {
    setOpen((prev) => !prev);
  }

  function percentageDiscount(original, discount) {
    const result = ((original - discount) / original) * 100;

    return `${result.toFixed(0)}%`;
  }

  /**
   function sendToCart() {
    if (!token) {
      toast.error("You must be logged in to add to cart");
      navigate("/login");
      return;
    }
    const payload = {
      ...detaildata,
      curPrice: detaildata?.isdiscount
        ? detaildata?.discount?.discount_price
        : detaildata?.price,
      count: 1,
    };
    // isdiscount ? discount : price
    dispatch(addToCart(payload, cartItems));

    //navigate("/cart");
    dispatch(calculateTotal(cartItems));
    toast.success("Item added to cart");
  }
  */

  //
  // console.log({ checkedAttribute });
  useEffect(() => {
    let images = [];
    let id = [];
    let attribute = [];

    if (Array.isArray(detaildata?.variants)) {
      // re arrange
      const rearranged = detaildata?.variants?.map((item) => {
        return {
          ...item,
          attributes: item?.attributes?.map(({ value }) => value),
        };
      });
      setRearrangedVariant(rearranged);

      setVariantProduct(rearranged[0]);

      const variantList = {
        variantImages: [],
        variantAttributes: [],
      };

      // restructure variant list
      detaildata?.variants.forEach((value) => {
        images.push({ img: value?.image, id: value?._id });
        id.push(value?._id);
        //   price = Number(value?.price)
        attribute.push(value?.attributes?.map((val) => val));
      });

      // variantList.price = price;

      variantList.variantImages = images;
      variantList.variantAttributes = attribute.flat();

      // Grouping items by name and collecting values into arrays
      const groupedData = attribute.flat().reduce((acc, item, index) => {
        const existingItem = acc.find((group) => group.name === item.name);
        if (existingItem) {
          existingItem.value.push(item.value);
        } else {
          acc.push({ name: item.name, value: [item.value] });
        }
        return acc;
      }, []);
      variantList.variantAttributes = groupedData;

      // console.log(variantList);
      setRestructuredVariant(variantList);
    }
  }, [detaildata?.variants]);

  useEffect(() => {
    if (checkedAttribute) {
      /// check for the product price
      rearrangedVariant.map((v) => {
        const attr = v?.attributes;
        const checked = checkedAttribute.map((v) => v?.value);
        if (
          attr?.length === checked?.length &&
          checked.every((value, index) => value === attr[index])
        ) {
          // console.log(v);
          setVariantProduct(v);

          setActivePrice(Number(v?.price));
        }
        return null
      });
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedAttribute, rearrangedVariant]);

  function updateAttribute(value, name) {
    const isNamePresent = checkedAttribute.some((v) => v?.name === name);

    if (isNamePresent) {
      setCheckedAttribute(
        checkedAttribute?.map((item) => {
          if (item?.name === name) {
            return {
              ...item,
              value,
            };
          }
          return item;
        })
      );
      //  setCheckedAttribute([{ value, name }]);

      //  setActivePrice(0);
    } else {
      setCheckedAttribute((prev) => [...prev, { value, name }]);
    }
  }

  const addProductToCart = {
    variantid: variantProduct?._id,
    variantpoduct: true,
    price: Number(variantProduct?.price),
    isdiscount: detaildata?.discount?.isdiscount,
    discountprice: detaildata?.discount?.isdiscount
      ? parseFloat(detaildata?.discount?.discount_price)
      : 0,
    delivery_fee: parseInt(detaildata?.vehicle_fee),
    vehicle_fee: parseInt(detaildata?.vehicle_fee),
    subprice:
      detaildata?.discount?.isdiscount &&
      parseInt(detaildata?.discount?.discount_price) > 0
        ? parseInt(detaildata?.discount?.discount_price)
        : Number(variantProduct?.price),
    latitiude: detaildata?.location?.latitiude,
    longitude: detaildata?.location?.longitude,
    count: 1,
    quantity: 1,
    image: variantProduct?.image,
    productname: detaildata?.name,
    productDescription: detaildata?.description,
    delivery_vehicle: detaildata?.delivery_vehicle,
    productid: detaildata?._id,
    sellerid: detaildata?.sellerid._id,
  };

  // add to cart
  async function add() {
    //console.log({addProductToCart})
   /**
     const currentSeller = cartItems?.some(
      ({ sellerid }) => sellerid === addProductToCart?.sellerid._id
    );
    if (cartItems?.length > 0 && !currentSeller) {
      toast.error(
        "You cannot add product from different brand to your cart. Proceed to empty your cart before you continue."
      );
      return;
    }
    */

    dispatch(addToCart(addProductToCart, cartItems, deliveryPrice));
    dispatch(calculateTotal(cartItems));
    toast.success("Item added to cart");
  }

  // memoize product name
  const productName = useMemo(() => {
    const attributes = variantProduct?.attributes
      .toString()
      ?.replace(",", " / ");
    return `${detaildata?.name} / ${attributes}`;
  }, [detaildata, variantProduct]);

  return (
    <>
      <div className="w-full px-4 h-fit flex flex-col min-[900px]:col-span-3">
        <div className="mb-2 hidden sm:flex items-center justify-between w-full">
          <FavouriteButton product={detaildata} />
        </div>
        <button
          onClick={() => navigate(`/brands-detail?n=${detaildata?.sellerid?._id}`)}
          className="text-xs text-start sm:text-[13px] mb-1 text-[#b847fe]"
        >
          Visit store
        </button>
        <p className="flex uppercase flex-wrap mb-3 justify-start items-start leading-6 w-full font-semibold text-[15px]m sm:text-lg text-black">
          {`${productName ? productName : "__"}`}
        </p>

        <div className="flex items-center mb-2 space-x-2">
          <div className="flex flex-col sm:flex-row gap-2 items-center">
            <h2 className="font-semibold text-black text-lg sm:text-2xl ">
              {`₦${
                Number(variantProduct?.price || 0)?.toLocaleString() || "₦0.00"
              }`}
            </h2>
            {detaildata?.quantity > 0 ? (
              <span className="text-green-600 font-semibold flex items-center gap-1">
                <GoDotFill className="text-xl" /> <span>In Stock</span>
              </span>
            ) : (
              <span className="flex items-center gap-1 font-semibold text-red-600">
                <GoDotFill className="text-xl" /> <span> Out of Stock</span>
              </span>
            )}
          </div>
          {detaildata?.discount?.isdiscount && (
            <span className="text-gray-500 line-through font-medium">
              {`₦${detaildata?.discount?.discount_price?.toLocaleString()}`}
            </span>
          )}
          {detaildata?.discount?.isdiscount && (
            <span className="px-2 py-1 bg-gray-100 shadow rounded-lg text-[11px] sm:text-[13px]">
              {percentageDiscount(
                variantProduct?.price,
                detaildata?.discount?.discount_price
              )}
            </span>
          )}
        </div>
        <div className="flex items-center mb-3 space-x-2">
          <Rating rating={detaildata?.rating || 0} />
          <div className="text-gray-500 text-[11px] sm:text-[13px]">
            {"(422 reviews)"}
          </div>
        </div>

        <div className="flex flex-col items-start justify-start gap-y-2 sm:gap-y-3">
          <AddItemToCart product={addProductToCart} isVariant={true} />

          <button
            onClick={onClose}
            className="text-altinsmart font-medium underline"
          >
            View Variations
          </button>
          <div className="hidden px-4 flex-wrap items-center gap-2">
            {Array.isArray(detaildata?.variants) &&
              detaildata?.variants?.length > 0 &&
              detaildata?.variants?.map((item) => (
                <button
                  onClick={onClose}
                  className={`w-[60px] h-[60px] overflow-hidden rounded-md ${
                    variantProduct?.image === item?.image
                      ? "border border-altinsmart"
                      : ""
                  }`}
                >
                  <img
                    src={item?.image}
                    className="w-full h-full object-cover"
                    alt=""
                  />
                </button>
              ))}
          </div>
        </div>
      </div>

      {isOpen && (
        <div
          onClick={onClose}
          className="w-full h-full fixed inset-0 z-[200] bg-black/30"
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="absolute let swipeIn  right-0 bg-white inset-y-0 h-full w-full sm:w-[450px]"
          >
            <div className="w-full h-[250px] relative">
              <button
                onClick={onClose}
                className="absolute right-3 top-3 bg-black/20 rounded-lg flex items-center justify-center p-1"
              >
                <MdClose className="text-[22px]" />
              </button>
              <img
                src={detaildata?.coverimage}
                alt=""
                className="w-full h-full object-cover"
              />
            </div>
            <div className="w-full h-[60vh] pb-20 overflow-y-auto">
              <p className="font-medium text-sm sm:text-base p-4">Variations</p>
              <div className="hidden p-4  flex-wrap items-center gap-2">
                {restructuredVariant?.variantImages?.map((item) => (
                  <button
                    className={`w-[60px] h-[60px] overflow-hidden rounded-md ${
                      variantProduct?.image === item?.image
                        ? "border border-altinsmart"
                        : ""
                    }`}
                  >
                    <img
                      src={item?.img}
                      className="w-full h-full object-cover"
                      alt=""
                    />
                  </button>
                ))}
              </div>
              <div className=" px-4 flex w-full flex-col items-start justify-start">
                {restructuredVariant?.variantAttributes?.map((item, idx) => {
                  return (
                    <div className="my-3 space-y-3" key={idx}>
                      <p className="font-medium capitalize text-sm sm:text-base ">
                        {item?.name}
                      </p>
                      <div className="flex  flex-wrap items-center gap-x-2">
                        {Array.from(new Set(item?.value))?.map((val, index) => (
                          <button
                            onClick={() => {
                              updateAttribute(val, item?.name);
                            }}
                            key={index}
                            className={`bg-altinsmart/20 rounded-lg h-9 px-4 ${
                              checkedAttribute
                                .map((v) => v?.value)
                                .includes(val)
                                ? "border-altinsmart border"
                                : ""
                            }`}
                          >
                            {val}
                          </button>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full absolute z-50 bottom-2 inset-x-0 px-6 grid grid-cols-1 items-center">
              <button
                disabled={activePrice === 0}
                onClick={(e) => {
                  e.stopPropagation();
                  add();
                }}
                className="bg-[#b847fe] h-11 sm:h-12 text-white font-medium rounded-2xl py-2 px-4 flex items-center gap-x-2 justify-center"
              >
                <div className="flex items-center font-medium gap-x-2">
                  <AiOutlineShoppingCart className="text-[22px]" />
                  <span>Add to Cart</span>
                </div>
                <p className="border-l-2 px-2 border-white text-white font-medium">
                  {`₦${activePrice?.toLocaleString()}`}
                </p>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
