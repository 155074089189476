import React from "react";
import { LuPlusCircle } from "react-icons/lu";
import { ChatList } from "./ChatList";
import { CurrentChat } from "./CurrentChat";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useForm } from "react-hook-form";
import smart from "../../assets/png/smart.png"
import { MdClose } from "react-icons/md";
import { Loader2Icon } from "lucide-react";
import { createTicket, retrieveTickets } from "../../Utils/api";
import { useNavigate } from "react-router-dom";
export default function Messaging() {
  const [islist, setList] = useState(false);
  const [, setSideBar] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const { token, loggedInUser } = useSelector((state) => state.user);
  const [currentChatId, setCurrentChatId] = useState("");
  const [data, setData] = useState([]);
  const navigate = useNavigate()
  const [name, userName] = useState("");

  function toggleSideBar() {
    setSideBar((prev) => !prev);
  }

  function setId(id) {
    setCurrentChatId(id);
  }

  function setUserName(name) {
    userName(name);
  }

  function onClose() {
    setOpen((prev) => !prev);
  }

  async function ticketCreation(payload) {
    setLoading(true);
    await createTicket(payload, token)
      .then((res) => {
        //  const {data} = res?.data
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
        fetchData();
      });
  }

  async function fetchData() {

    if (!loggedInUser) return 
    setFetching(true);
    await retrieveTickets(
      { customerid: loggedInUser?.id || loggedInUser.customer?._id },
      token
    )
      .then((res) => {
        const { data } = res?.data;
        setData(data);
      })
      .finally(() => {
        setFetching(false);
      });
  }

  return (
    <div className="w-full min-h-screen  bg-gray-50 fixed inset-0">
    <div className="flex mb-3 px-4 py-2 bg-white w-full items-center justify-between">
          <div
            onClick={() => navigate("/")}
            role="button"
            className="w-[120px] h-[50px]"
          >
            <img src={smart} alt="" className="w-full h-full object-cover" />
          </div>
        <button
          onClick={onClose}
          className="rounded-md flex px-4 items-center justify-center h-10 bg-altinsmart gap-x-2 text-gray-50"
        >
          <LuPlusCircle className="text-xl" />
          <p>Create New Ticket</p>
        </button>
      </div>
    
      <div className="w-full flex h-[89vh] gap-4">
      <ChatList
        toggleSideBar={toggleSideBar}
        islist={islist}
        setList={setList}
        fetchData={fetchData}
        data={data}
        loading={fetching}
        currentChatId={currentChatId}
        setUserName={setUserName}
        setId={setId}
      />
      <CurrentChat
        name={name}
        islist={islist}
        setList={setList}
        currentChatId={currentChatId}
      />

        
      </div>


      {isOpen && (
        <CreateTicket
          close={onClose}
          loading={loading}
          ticketCreation={ticketCreation}
        />
      )}
    </div>
  );
}

function CreateTicket({ close, loading, ticketCreation }) {
  const form = useForm({});
  const { loggedInUser } = useSelector((state) => state.user);

  async function onSubmit(value) {
    const payload = {
      ...value,
      customerid: loggedInUser?.id || loggedInUser.customer?._id,
    };

    await ticketCreation(payload);
    close();
  }
  return (
    <div
      onClick={close}
      role="button"
      className="w-full h-full fixed inset-0 bg-black/50 z-[999999]"
    >
      <div
        role="button"
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="bg-white rounded-lg px-3 sm:px-4 py-4 sm:py-6 m-auto inset-0 w-[95%] sm:w-[600px] lg:w-[700px] h-fit  absolute"
      >
        <div className="flex w-full items-center justify-between">
        <h1 className=" font-semibold text-xl">Create Ticket</h1>
          <button onClick={close} className="px-2">
            <MdClose className="text-[22px]" />
          </button>
        </div>

        <div className="w-full  rounded-lg px-2 flex flex-col items-start justify-start gap-y-6 py-4 sm:px-4 ">
          

          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full  space-y-3"
          >
            <label className="font-semibold">Messsage</label>
            <textarea
              placeholder="Type your message"
              className="w-full  mt-2 relative h-32 border outline-none resize-none p-4 rounded-lg "
              {...form.register("request")}
            ></textarea>

            <div className="mt-4 flex items-end justify-end w-full ">
              <button
                disabled={loading}
                type="submit"
                className="w-32 px-4 h-11 rounded-md flex items-center justify-center gap-x-2 bg-altinsmart text-gray-50 font-medium"
              >
                {loading && <Loader2Icon className="animate-spin" />}
                <p>Create</p>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
