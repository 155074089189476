import { MdOutlineReply } from "react-icons/md";
import { IoIosHeart } from "react-icons/io";
import { useSelector } from "react-redux";
import { useState } from "react";
import toast from "react-hot-toast";
import { reactToComment, replyToComment } from "../../Utils/api";
import SingleReply from "./SingleReply";
import { useParams } from "react-router-dom";

const SingleComment = ({ comment, fetchData }) => {
  const { token, loggedInUser } = useSelector((state) => state.user);
  const [commentText, setCommentText] = useState("");
  const [replying, setReplying] = useState(false);

  const { id } = useParams();

  const likeComment = () => {
    if (!token ) {
      toast.error("Login is required");
      return
    }
    toast.loading("Liking Comment");
    reactToComment(
      {
        customerid: loggedInUser?.id || loggedInUser.customer?._id,
        commentid: comment._id,
      },
      token
    ).then(() => {
      toast.success("Comment Liked");
      fetchData(false)
    });
  };

  const replyToAComment = () => {
    if (!token) {
      toast.error("Login is required");
      return
    }
    toast.loading("Sending Reply");
    replyToComment(
      {
        customerid: loggedInUser?.id || loggedInUser.customer?._id,
        commentid: comment._id,
        reply: commentText,
        blogid: id,
      },
      token
    ).then(() => {
      toast.success("Reply Sent");
      setReplying(false);
      setCommentText("");
      fetchData( false)
    });
  };

  return (
    <div className="my-2">
      <div className="bg-slate-100 p-2 rounded-md">
        <div className="flex gap-4 items-center">
          <div className="w-11 h-11 rounded-full bg-gray-200">
            <img
              alt=""
              src={comment?.customerid?.photo}
              className="w-11 h-11 rounded-full"
            />
          </div>

          <div>
            <div className="text-sm  font-semibold capitalize">
              {comment?.customerid?.name}
            </div>
            <div className="hidden text-xs">23mins ago</div>
          </div>
        </div>

        <div className="pl-[3.7rem]">{comment?.comment}</div>

        <div className="pl-[3.8rem] flex gap-6 my-3 items-center">
          <div
            className="text-sm flex gap-2 items-center cursor-pointer"
            onClick={likeComment}
          >
            <IoIosHeart className="text-red-700 " />
            <div className="font-semibold">{comment.likes} Like</div>
          </div>
          <div className="font-semibold">
            {comment.replies.length ?? 0} Replies
          </div>
          <div
            className="text-sm flex gap-2 items-center cursor-pointer"
            onClick={() => setReplying(!replying)}
          >
            <MdOutlineReply />
            <div>Reply</div>
          </div>
        </div>
      </div>
      {comment.replies.map((reply) => (
        <SingleReply reply={reply} key={reply._id} />
      ))}

      {replying && (
        <div className="mt-2">
          <textarea
            className="w-full border border-slate-600 rounded-lg outline-none h-20 resize-none p-2"
            placeholder="Reply comes here"
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
          />
          <div className="flex justify-end gap-4 items-center">
            <button
            disabled={commentText === ""}
              className="text-white bg-info-700 py-2 px-6 rounded-lg w-28"
              onClick={replyToAComment}
            >
              Reply
            </button>
            <button
              className="text-slate-700 bg-slate-200 py-2 px-6 rounded-lg w-28"
              onClick={() => setReplying(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleComment;
