import React, { useEffect, useState } from "react";
import UserContainer from "../../myAccount";
import WalletWidget from "./widget/walletWidget";
import CardAndHistory from "./widget/cardAndHistory";
import { customerDashboard } from "../../../../Utils/api";
import { useSelector } from "react-redux";

export default function UserDashboard() {
  const [data, setData] = useState(null);
  const { token, loggedInUser } = useSelector((state) => state.user);

  useEffect(() => {
    (async () => {
      await customerDashboard(
        {
          customerid:
            loggedInUser?.id || loggedInUser.customer?._id || loggedInUser.customer._id,
        },
        token
      )
        .then((res) => {
          const { data } = res?.data;
          setData(data);
        })
        .catch((err) => {
          //   console.log(err)
        });
    })();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <UserContainer>
        <div className="">
          <WalletWidget data={data} />
          <CardAndHistory data={data} />
          {/* <Others/>*/}
        </div>
      </UserContainer>
    </>
  );
}
