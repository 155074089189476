import React, { useEffect } from "react";
import HeaderWidget from "../headerwidget/headerWidget";
import Layout from "../layout/layout";
import { getAllCategories } from "../../Utils/api";
import { useState } from "react";
import AllCatsSkeleton from "../groupwidget/skeletonsWidget/allCatsSkeleton";
import { Link } from "react-router-dom";
export default function Categories() {
  const [data, setdata] = useState(null);

  useEffect(() => {
    async function fetchData() {
      await getAllCategories()
        .then((res) => {
          //console.log(res.data);
          const { data } = res.data;
          setdata(data);
        })
        .catch((err) => {
          //console.log(err);
        });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout>
        <div className="my-2 sm:my-4">
          <HeaderWidget
            title={"All Categories"}
            text={"Choose from different categories."}
          />
          <div className="grid w-full px-4 py-3 sm:px-6 sm:py-4 items-center gap-6 grid-cols-1 sm:grid-cols-2">
            {data &&
              data?.length > 0 &&
              data?.map(
                ({ category, categoryurls, category_description, _id }, j) => {
                  return (
                    <Link
                      to={`/category-detail?n=${_id}`}
                      key={_id}
                      className="w-full h-full  rounded-md "
                    >
                      <div className="w-full h-[160px] sm:h-[200px] relative rounded-md overflow-hidden">
                        <div className="w-full  h-[160px] sm:h-[200px]">
                          {Array.isArray(categoryurls) && (
                            <img
                              src={categoryurls[0]?.categoryurl}
                              alt="i"
                              className=" w-full h-full object-cover rounded-md"
                            />
                          )}
                        </div>
                        <div className="absolute inset-0 z-10 w-full h-full bg-black/30">
                          <div className="absolute bottom-0 text-gray-50 left-0">
                            <div className="font-medium bg-black/40 px-2 py-2 rounded-tr-lg capitalize text-sm sm:text-lg">
                              {category}
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                }
              )}
            {!data &&
              [1, 2, 3, 4, 5, 6, 7, 8].map((j, i) => {
                return (
                  <div key={i}>
                    <AllCatsSkeleton />
                  </div>
                );
              })}
          </div>
        </div>
      </Layout>
    </>
  );
}
