import React, { useEffect, useState } from "react";
import flutterwave from "../../../../assets/png/flutterwave.png";
import Input from "../../../Composable/input";
import { LiaCheckCircle } from "react-icons/lia";
import { MdOutlineCancel } from "react-icons/md";
import { IoIosClose } from "react-icons/io";
import { useSelector } from "react-redux";
import { fundWallet, retreivePlatformNumber } from "../../../../Utils/api";

export default function Recharge({ close }) {
  const [amount, setAmount] = useState(0);
  const [showStatus, setShowStatus] = useState(false);
  const { loggedInUser, token } = useSelector((state) => state.user);
  const [status,] = useState(null);
  const [platformNumber, setPlatformNumber] = useState(null);

  // const { handlePayment, closePaymentModal } = useFlutterWavePaymentHook(
  //   Number(amount),
  //   "Add money to Wallet"
  // );

  // function addToWallet() {
  //   close();
  //   handlePayment({
  //     callback: async (response) => {
  //       console.log(response);

  //       const payload = {
  //         customerid: loggedInUser?.id || loggedInUser?.customer?._id,
  //         walletid: loggedInUser?.userwallet?._id,
  //         email: loggedInUser?.email || loggedInUser?.customer?.email,
  //         usertype: "user",
  //         cardpayment: true,
  //         amount,
  //         status: response?.status === "successful" ? true : false,
  //         platformNumber,
  //       };

  //       await fundWallet(payload, token)
  //         .then((res) => {
  //           toast.success("Wallet funded successfully");
  //           const { userwallet, ...restItems } = loggedInUser;

  //           dispatch({
  //             type: "FETCH_USER_SUCCESS",
  //             payload: {
  //               ...restItems,
  //               userwallet: {
  //                 ...userwallet,
  //                 balance: userwallet.balance + Number(amount),
  //               },
  //             },
  //           });

  //           closePaymentModal();
  //         })
  //         .catch((err) => {
  //           // console.log(err);
  //         });
  //       //
  //       hide();
  //     },
  //     onClose: () => {
  //       console.log("closed");
  //     },
  //   });
  // }

  const fundMyWallet = async () => {
    const payload = {
      customerid: loggedInUser?._id || loggedInUser?.id,
      email: loggedInUser?.email || loggedInUser?.email,
      usertype: "user",
      amount,
      platformNumber,
      cardpayment: true,
    };
   await fundWallet(payload, token)
      .then((res) => {
      //  console.log(res.data.data);
        window.open(res.data.data, "_blank");
        // window.location.href = res.data.data;
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    retreivePlatformNumber({
      customerid: loggedInUser?.id || loggedInUser?.customer?._id,
      walletid: loggedInUser?.userwallet?._id,
    })
      .then(({ data }) => {
        setPlatformNumber(data.data[0].platformNumber);
      })
      .catch(() => {});
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function hide() {
    setShowStatus(!showStatus);
  }

  return (
    <>
      <div
        onClick={close}
        className="w-full h-full bg-black bg-opacity-50 z-30 fixed inset-0"
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="p-3 sm:p-4 m-auto absolute w-[400px] rounded-lg bg-white inset-0 h-fit"
        >
          <div className="flex flex-col  w-full mb-6 items-start gap-8 justify-start">
            <div className="w-[150px] h-[50px] mb-1">
              <img src={flutterwave} alt="" className="w-full h-full" />
            </div>

            <div className="w-full">
              <Input
                type={"number"}
                placeholder={"Amount"}
                header={"Enter Amount (NGN)"}
                value={amount}
                setValue={setAmount}
              />
              <p className="text-red-600 text-xs">
                Note: Amount cannot be less than NGN200
              </p>
            </div>
          </div>

          <div className="flex space-x-2 pt-6 justify-end w-full items-end">
            <button
              onClick={close}
              className="px-4 py-2 bg-gray-300 font-semibold rounded-lg"
            >
              Cancel
            </button>
            <button
              disabled={Number(amount) <= 200}
              onClick={fundMyWallet}
              className={`${
                Number(amount) <= 0 ? "bg-opacity-50" : ""
              } px-4 py-2 bg-[#b847fe] text-white font-semibold rounded-lg`}
            >
              Recharge Wallet
            </button>
          </div>
        </div>
      </div>
      {showStatus && <PaymentStatusModal hide={hide} status={status} />}
    </>
  );
}
//successful
function PaymentStatusModal({ hide, status }) {
  return (
    <div
      onClick={hide}
      className="w-full h-full bg-white bg-opacity-50 z-40 fixed inset-0"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="p-3 sm:p-4 m-auto absolute w-[400px] shadow-lg rounded-lg bg-white inset-0 h-fit"
      >
        {status === "successful" ? (
          <div className="flex w-full flex-col gap-6 items-center justify-center">
            <LiaCheckCircle className="text-green-700 text-5xl" />
            <p>Payment Successful</p>

            <button onClick={hide} className="flex items-center gap-1">
              <IoIosClose className="text-xl" />
              <span>Close</span>
            </button>
          </div>
        ) : (
          <div className="flex w-full flex-col gap-6 items-center justify-center">
            <MdOutlineCancel className="text-red-700 text-5xl" />
            <p>Payment Failed. Try Again Later</p>

            <button onClick={hide} className="flex items-center gap-1">
              <IoIosClose className="text-xl" />
              <span>Close</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

// const payload = {
//   customerid: loggedInUser?.id || loggedInUser?.customer?._id,
//   walletid: loggedInUser?.userwallet?._id,
//   trx_type: "credit",
//   amount,
//   status: response?.status === "successful" ? true : false,
// };
