import React from "react";
import CustomerReview from "./customerReview";


export default function Reviews({customerReviews}) {
    return (
        <div className="sm:px-6 px-4 py-3 flex flex-col overflow-x-hidden my-2 sm:my-4 space-y-3 sm:space-y-5 text-gray-500  sm:py-4  w-full">
        <div className="py-2 border-b-2 border-gray-300 w-full ">
        <h2 className="text-zinc-800 font-semibold text-base sm:text-xl">
        Customer Reviews
      </h2>
        </div>

        <div className="w-full scroll-style overflow-x-auto  items-center">
                <div className="min-w-max py-3 flex items-center flex-row gap-4 sm:gap-6">
            {Array.isArray(customerReviews) && customerReviews.map((item) => {
                return (
                    <div key={item?._id}>
                    <CustomerReview item={item}/>
                    </div>
                )
            })}
            </div>
            </div>

        </div>
    )
}