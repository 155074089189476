import React from "react";

export default function OrderSummary({ order }) {
  const formatTime = (time) => {
    const newTime = new Date(time);

    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(newTime);
  };
  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

  return (
    <div className="w-full bg-altinsmart/20 text-zinc-600 flex items-center justify-between  border border-[#b847fe] rounded-lg p-2 sm:px-4 sm:py-6">
      <div className="space-y-3 sm:space-y-4">
        <div className="text-lg sm:text-2xl font-semibold">
          Order ID:  {order?.trackingid ?? ""}
        </div>
        <div className="text-xs sm:text-[15px] flex flex-col gap-2 sm:flex-row">
          <span>{order?.quantity} products</span>{" "}
          <span className="">
            Order placed on {order?.createdAt && formatTime(order?.createdAt)}
          </span>
        </div>
      </div>

      <div className="text-xl sm:text-3xl font-semibold">
        ₦{formatNumber(order?.price ?? 0)}
      </div>
    </div>
  );
}
