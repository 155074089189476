import React from "react";
import Inventories from "../../../../Composable/inventories";
import ShippingAddress from "./shippingAddress";
import { useSelector } from "react-redux";

export default function CardAndHistory({ data }) {
  const { numOfCartItems } = useSelector((state) => state.cart);

  return (
    <div className="w-full mt-4 h-fit grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
      <div className="w-full h-full min-h-[250px] max-h-[320px] flex flex-col gap-4">
        <Inventories
          num={numOfCartItems || 0}
          title={"Products in your cart"}
        />
      
        <Inventories num={data?.order ?? 0} title={"Products you ordered"} />
      </div>

      <div className="w-full border h-full min-h-[250px] max-h-[320px] border-gray-200 rounded-md ">
        <p className="border-b relative z-[10] font-medium border-gray-200 p-2 sm:p-3">
          Recent Purchase History
        </p>
        <div className="relative w-full h-[85%] scroll-style pb-10 overflow-y-auto">
          <div className="flex flex-col w-full h-full">
            {Array.isArray(data?.purchasehistory) &&
              data?.purchasehistory?.slice(0,3)?.map((i, j) => {
                return (
                  <div
                    key={j}
                    className="grid border-b py-2 p-2 sm:p-3 gap-2 w-full items-start grid-cols-4"
                  >
                    <img src={i?.image ?? ""} alt="" className="rounded-md"/>
                    <div className="w-full col-span-3">
                      <p className="font-medium text-gray-400 text-xs sm:text-sm w-full text-ellipsis overflow-hidden whitespace-nowrap">
                        {i?.productname ?? ""}
                      </p>
                      <p className="font-semibold text-xs sm:text-sm">
                       {`₦${Number(i?.price || 0)?.toLocaleString()}`}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <ShippingAddress />
    </div>
  );
}
