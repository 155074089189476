import React from "react";

export default function TrackerIcons({icon, text}) {
    return (
        <div className="flex flex-col items-center justify-center">
              
           {icon}
           <p className="text-xs hidden sm:block sm:text-sm">{text}</p>
          

        </div>
    )
} 