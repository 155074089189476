import React, { useState } from "react";
import Input from "../../../Composable/input";
import { createAddress, updateAddress } from "../../../../Utils/api";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { LoaderIcon } from "lucide-react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

export default function AddAddress({ close, data }) {
  const [address, setAddress] = useState(data?.address || "");
  const [city, setCity] = useState(data?.city || "");
  const [country, setCountry] = useState(data?.country || "");
  const [states, setState] = useState(data?.state || "");
  const [code, setCode] = useState(data?.postalcode || "");
  const { token, loggedInUser } = useSelector((state) => state.user);
  const [loading, setloading] = useState(false);
  const [latitude, setLatitude] = useState(0.0);
  const [longitude, setLongitude] = useState(0.0);
  const [autocomplete, setAutocomplete] = useState(null);
  const key = "AIzaSyCgcr6Ebgd6okFz0LE0Jj74kz1oXMnHlTM";

  async function add() {
    const payload = {
      customerid: loggedInUser?.id || loggedInUser?.customer?._id,
      address,
      country,
      state: states,
      city,
      postalcode: code,
      latitiude: String(latitude),
      longitude: String(longitude),
    };

    for (let i in payload) {
      if (payload[i] === "") {
        toast.error(`${i} is required`);
        return;
      }
    }

    if (code.length < 6 || code.length > 8) {
      toast.error("Postal code must be between 6 & 8 digits");
      return;
    }
    setloading(true);
   if (data) {
    await updateAddress(token, { ...payload, addressid: data?._id })
          .then((res) => {
            setloading(false);
            toast.success("Address updated successfully");
            window.location.reload();
          })
          .catch((err) => {
            //console.log(err);
            setloading(false);
          })
   }
   if (!data) {
    await createAddress(token, payload)
          .then((res) => {
            setloading(false);
            toast.success("Address saved successfully");
            window.location.reload();
          })
          .catch((err) => {
            //console.log(err);
            setloading(false);
          })
   }
  
  }

  const handlePlaceChanged = () => {
    if (autocomplete) {
      
      const place = autocomplete.getPlace();
      const { formatted_address } = place; // Get the formatted address

      // Update the address state with the selected address
      setAddress(formatted_address);
      const { geometry } = place;
      if (geometry) {
        const lat = geometry.location.lat();
        const lng = geometry.location.lng();
       // console.log(lat, lng);
        setLatitude(lat);
        setLongitude(lng);
      }
    }
  };

  return (
    <div>
      <div
        onClick={() => {
          close("");
        }}
        className="w-full h-full z-[40] bg-black bg-opacity-50 fixed inset-0"
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="p-3 sm:p-4 m-auto absolute w-[95%]  sm:w-[500px] overflow-y-auto rounded-lg bg-white inset-0 max-h-[500px]"
        >
          <div className="relative mb-3 w-full space-y-2">
            <label className="block font-medium text-gray-500" htmlFor="address">
              {"Address"}
            </label>
            <LoadScript googleMapsApiKey={key} libraries={["places"]}>
              <Autocomplete
                onLoad={(autocomplete) => setAutocomplete(autocomplete)}
                onPlaceChanged={handlePlaceChanged}
              >
                <input
                  type={"text"}
                  placeholder={"Enter your address"}
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  name={"address"}
                  autoComplete="on"
                  className="w-full h-11 sm:h-12  focus:outline-none px-4 bg-altinsmart/30 placeholder:text-gray-400 rounded-3xl border "
                />
              </Autocomplete>
            </LoadScript>
          </div>

          <Input
            placeholder={"Postal code"}
            type={"number"}
            value={code}
            setValue={setCode}
            header={"Postal Code"}
          />
          <Input
            placeholder={"Nigeria"}
            type={"country"}
            value={country}
            setValue={setCountry}
            header={"Country"}
          />
          <Input
            placeholder={"Lagos"}
            type={"state"}
            value={states}
            setValue={setState}
            header={"State"}
          />
          <Input
            placeholder={"Ikeja"}
            type={"city"}
            value={city}
            setValue={setCity}
            header={"City"}
          />
          {/* <Input
              placeholder={"Phone Number"}
              type={"number"}
              value={phone}
              setValue={setPhone}
              header={"Mobile or Phone Number"}
            /> */}

          <div className="flex space-x-2 justify-end w-full items-end">
            <button
              onClick={close}
              className="px-4 py-2 bg-gray-300 font-semibold rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={add}
              className="px-4 h-10 flex items-center justify-center w-[105px] bg-[#b847fe] text-white font-semibold rounded-lg"
            >
              {loading ? (
                <LoaderIcon className="text-xl animate-spin" />
              ) : (
                " Add new"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

