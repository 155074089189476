import React from "react";
import a from "../../assets/png/a.png";
import b from "../../assets/png/b.png";
import c from "../../assets/png/c.png";
import d from "../../assets/png/d.png";
import { CategoryWidget } from "./widget/catWidget";
import { TodayDealsWidget } from "./widget/todayWidget";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { NewDealsWidget } from "./widget/newDeals";
import CatSkeleton from "./skeletonsWidget/catSkeleton";
import { useNavigate } from "react-router-dom";
export function GroupWidget({ heading, type, more, data }) {

  const navigate = useNavigate();
  const cats = [
    { image: d, title: "Gaming Laptops" },
    { image: a, title: "Portable Electronics" },
    { image: c, title: "Mobile Phones" },
    { image: b, title: "Accessories" },
    { image: a, title: "Portable Electronics" },
    { image: c, title: "Mobile Phones" },
    { image: b, title: "Accessories" },
    { image: c, title: "Mobile Phones" },
    { image: b, title: "Accessories" },
  ];

/**
   useEffect(() => {
    let interval = setInterval(() => {
      if (slide.current) {
        slide.current?.scrollBy({
          left: 120,
          behavior: "smooth",
        });
      }
    }, 1000);
    function scrollEl() {
      if (
        slide.current?.scrollLeft + slide.current?.offsetWidth >=
        slide.current?.scrollWidth
      ) {
        clearInterval(interval);
        slide.current.scrollBy({
          left: -slide.current?.scrollWidth,
          behavior: "smooth",
        });
      }
    }

    slide.current?.addEventListener("scroll", scrollEl);

    return () => slide.current?.removeEventListener("scroll", scrollEl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slide.current?.scrollLeft]);
 */

  //  console.log({data})

  return (
    <div className="px-2 sm:px-0 sm:mx-4 py-3 flex flex-col overflow-x-hidden h-fit my-2 sm:my-4 space-y-3 sm:space-y-5 text-gray-500  sm:py-4  w-full">
      {Array.isArray(data) && data?.length > 0 && (
        <div className="flex justify-between items-center w-full pr-6">
          <h2 className="text-zinc-800 font-semibold text-base sm:text-xl">
            {heading}
          </h2>
          <button
            onClick={() => {
              navigate(`more?=name${heading}`, {
                state: {
                  data,
                  heading,
                },
              });
            }}
            className={`text-[#B847FE]  cursor-pointer items-center space-x-1 font-semibold ${
              more ? "flex" : "hidden"
            }`}
          >
            <span className="text-xs sm:text-sm">See All</span>
            <AiOutlineDoubleRight className="text-[22px]" />
          </button>
        </div>
      )}
      {type === "categories" && (
        <div className="hide-scroll w-full overflow-x-auto ">
          <div className="min-w-max  flex items-center gap-4  sm:gap-6">
            {data &&
              data?.length > 0 &&
              data?.map(({ categoryurls , category, _id }, idx) => {
                //  console.log({categoryurls})
                return (
                  <div key={idx}>
                    <CategoryWidget
                      id={_id}
                      images={categoryurls}
                      title={category}
                    />
                  </div>
                );
              })}
            {!data &&
              [1, 2, 3, 4, 5, 6, 7].map((_, i) => {
                return (
                  <div key={i}>
                    <CatSkeleton />
                  </div>
                );
              })}
          </div>
        </div>
      )}
      {type === "deals" && data?.length > 0 && (
        <div  className="hide-scroll w-full overflow-x-auto ">
          <div className="grid grid-cols-2 w-full sm:min-w-max  h-fit sm:flex items-center gap-4 sm:gap-6">
            {Array.isArray(data) &&
              data?.map((item) => {
                return (
                  <div key={item?._id}>
                    <NewDealsWidget
                      images={item?.coverimage}
                      name={item?.name}
                      price={item?.price}
                      id={item?._id}
                      isDiscount={item?.discount?.isdiscount}
                      discountPrice={item?.discount?.discount_price}
                      data={item}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      )}

   
         {type === "recentlyViewed" && data?.length > 0 && (
        <div  className="hide-scroll w-full overflow-x-auto ">
          <div className="grid grid-cols-2 sm:min-w-max w-full  sm:flex items-center gap-4 sm:gap-6">
            {Array.isArray(data) &&
              data?.map((item) => {
                return (
                  <div key={item?._id}>
                    <NewDealsWidget
                      images={item?.coverimage}
                      name={item?.name}
                      price={item?.price}
                      id={item?._id}
                      isDiscount={item?.discount?.isdiscount}
                      discountPrice={item?.discount?.discount_price}
                      data={item}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      )}

      {type === "top-deal" && (
        <div className="hide-scroll w-full overflow-x-auto ">
          <div className="grid grid-cols-2 w-full sm:min-w-max  sm:flex items-center gap-4 sm:gap-6">
            {cats.map(({ image, title }, idx) => {
              return (
                <div key={idx}>
                  <TodayDealsWidget image={image} />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
