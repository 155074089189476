import Layout from "../layout/layout";
import { IoSearch } from "react-icons/io5";
import { useEffect, useState } from "react";
import { retreiveAllBlogs } from "../../Utils/api";
import SingleBlog from "./SingleBlog";

const Blog = () => {
  const [blogs, setBlogs] = useState(null);
  const [loading, setLoading] = useState(false);

  async function fetchData() {
    setLoading(true);
    retreiveAllBlogs()
      .then(({ data }) => {
        setBlogs(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
       // console.log(err.response);
      });
  }

  useEffect(() => {
    fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  /**
    
   */
  return (
    <Layout>
      <div className="w-full h-52 sm:h-72 relative ">
        <img
          src="https://s3-alpha-sig.figma.com/img/b96e/5ae0/7cebb44f66197f63977224e8f430e858?Expires=1716163200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=hg2ZEj2stY26FNw2Ry0Pw0kEEnxLK0LRbqj9-ZIzjZ7JHPt~bkEExLXuEytnB2LZ~yOPugdWl53n1hgKbS84FC-UreDI7PhtZthMEVnYswoFse4W~KPWPRf0TROcsywD8RvZbn-yLXZ0SRwh5iiJmZZ39yBAQ3E3Nl7suPxYpcF0uSEcmfC5xOIZ4nXGolmM3O82-Lqy9ZfwdxZM0bqUh54fgWJnaHwMCunZz39r2xnDaMP6TMad3u2dIrIRZ37WccZNnQN~zmD~~zSVUa-~FD5Li9kZpYTFgVCK6z6niAowp01uSFlvpXRXPeAYMawnYbWEy0Xh6eRMJLh00iWUYw__"
          className="w-full h-full object-cover"
          alt="banner"
        />
       <div className="w-full h-full inset-0 flex items-end justify-start text-gray-50 bg-black/30 absolute m-auto">
          <div className="text-lg sm:text-2xl p-6 font-semibold">RECENT BLOGS</div>
        </div>
      </div>

      <div className="hidden justify-between items-center my-3">
        <div className="bg-gray-100 hidden sm:flex gap-2 items-center p-3 rounded-full">
          <IoSearch />
          <input
            type="text"
            className="bg-transparent outline-none text-sm"
            placeholder="Search by seller"
          />
        </div>
        <div className="bg-gray-100 sm:hidden items-center p-3 rounded-full">
          <IoSearch />
        </div>
      </div>

      <div className="max-w-4xl mt-8 w-11/12 mx-auto">
        {loading ? (
          <div className="text-xl text-center py-10">Loading...</div>
        ) : blogs?.length === 0 ? (
          <div className="text-xl text-center py-10">No Data</div>
        ) : (
          <div className="grid grid-cols-1 gap-6">
            {blogs?.map((blog) => (
              <SingleBlog key={blog._id} blog={blog} refetch={fetchData}/>
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Blog;
