import React from "react";
import shop from "../../../assets/png/shop.png";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { followStore, unfollowStore } from "../../../Utils/api";
import { useNavigate } from "react-router-dom";
export function FeaturedWidget({ logo, name, id, biography, data }) {
  const { token, loggedInUser } = useSelector((state) => state.user);
  const navigate = useNavigate();

  async function addtoFollowed() {
    if (!token) {
      toast.error("You have to log in to add to your favorites");
      return;
    }
    const payload = {
      customerid: loggedInUser?.id || loggedInUser?.customer?._id,
      sellerid: id,
    };
    await followStore(token, payload)
      .then((res) => {
        toast.success("Shop Followed");
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  }

  async function removefromFollowed() {
    if (!token) {
      toast.error("You have to log in to remove from your favorites");
      return;
    }
    const payload = {
      customerid: loggedInUser?.id || loggedInUser?.customer?._id,
      sellerid: id,
    };

    await unfollowStore(token, payload)
      .then((res) => {
        //console.log(res);
        toast.success("Shop Unfollowed");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        //console.log(err);
      });
  }
  return (
    <div className="w-[300px] h-[250px] sm:w-[350px] space-y-3 sm:space-x-3 flex flex-col sm:flex-row items-center justify-center  rounded-lg  border border-gray-300 p-4 hover:border-gray-600">
      <div className="flex gap-y-4 w-full flex-col items-center ">
        <div className="h-[100px] max-h-[100px] w-fit max-w-[100px] overflow-hidden">
          <img
            src={logo || shop}
            alt=""
            className="w-full h-full object-contain"
          />
        </div>

        <div className="w-full gap-y-3 items-center flex flex-col">
          <h2 className="font-semibold text-lg sm:text-2xl">{name ?? ""}</h2>
          <p className="text-gray-600 text-xs sm:text-sm w-full whitespace-nowrap text-ellipsis overflow-hidden">
            {biography}
          </p>
          <div className="flex items-center gap-x-2">
            <button
              onClick={() => navigate(`/brands-detail?n=${id}`)}
              className="rounded-lg border font-normal border-[#B847EF] bg-[#B847EF] hover:bg-opacity-70 bg-opacity-50 text-center w-[120px] py-2"
            >
              Visit Store
            </button>
            <button
              onClick={
                data?.some(({ _id }) => _id === id)
                  ? removefromFollowed
                  : addtoFollowed
              }
              className="rounded-lg border font-normal border-[#B847EF] bg-gray-300 hover:bg-gray-400 text-center w-[120px] py-2"
            >
              Follow
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
