import React from "react";
import UserContainer from "../../myAccount";
import user from "../../../../assets/png/customerpic.png"
export default function MyQueries() {
  return (
    <>
      <UserContainer>
        <div className="w-full rounded-md border border-gray-200">
          {[1, 2, 3, 4].map((i, j) => {
            return <MyQueriesWidget key={j} />;
          })}
        </div>
      </UserContainer>
    </>
  );
}

function MyQueriesWidget(key) {
  return (
    <div
      key={key}
      className="w-full grid grid-cols-1 gap-4 p-2 sm:p-3 border-b border-gray-200 hover:bg-gray-300 xl:grid-cols-7 items-start"
    >
      <div className="flex gap-6 xl:grid grid-cols-4 lg:col-span-2 items-center xl:items-start ">
        <div className="w-[70px] h-[70px] rounded-full ring-offset-2 ring-offset-gray-300">
          <img
            src={user}
            alt=""
            className="w-full h-full object-cover rounded-full"
          />
        </div>
        <div className="xl:col-span-3">
          <p className="font-semibold">Goodluck Kenneth</p>
          <p className="">01:26:05 17-05-2022</p>
        </div>
      </div>
      <div className="w-full md:col-span-5">
        <p className="font-semibold">Electric Generator</p>
        <div className="leading-6 sm:leading-7 flex flex-wrap justify-start w-full">
          Start simple. With this question, you can see which products your
          customers are using and how often they’re using them. This will make
          the following questions even more useful—you can see which products
          are making people happy, which ones arent, and which products your
          most dedicated customers are using
        </div>
      </div>
    </div>
  );
}
