import React from "react";
import { useNavigate } from "react-router-dom";

export default function Widget({
  nav,
  image,
  name,
  price,
  discount,
  isdiscount,
  id,
}) {
  const navigate = useNavigate();

  function percentageDiscount(original, discount) {
    const result = ((original - discount) / original) * 100;

    return `${result.toFixed(0)}%`;
  }

  return (
    <div
      onClick={() => {
        navigate(`/details?n=${id}`, {
          state: {
            navtitle: nav,
          },
        });
      }}
      className="cursor-pointer overflow-hidden relative w-full space-y-4 sm:space-y-5 flex flex-col items-center h-[250px] sm:h-[280px] rounded-lg font-semibold border border-gray-300  pb-4 shadow"
    >
      <div className="w-full overflow-hidden rounded-lg relative h-[189px]">
        <img src={image} alt="" className="w-full h-full object-cover" />
      </div>
      <div className="w-full px-4 text-[12px] sm:text-[13px] text-black">
        <p className="capitalize whitespace-nowrap text-ellipsis w-full overflow-hidden  ">
          {name}
        </p>
        <div className="flex  w-full items-center justify-between">
          <div className="flex items-center space-x-1">
            <span>{`₦${price?.toLocaleString()}`}</span>
            {isdiscount && (
              <span className="text-gray-500 line-through">{`₦${Number(
                discount
              )?.toLocaleString()}`}</span>
            )}
          </div>
          {isdiscount && (
            <div className="shadow px-2 py-1 bg-gray-100 rounded-lg">
              {percentageDiscount(price, Number(discount))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}


