import React from "react";

export default function AllCatsSkeleton() {
  return (
    <div className="animate-pulse w-full h-[160px] sm:h-[200px] border rounded-md border-gray-200 ">
      <div className=" w-full bg-gray-100 h-full">

      </div>
    </div>
  );
}
