import React from "react";

export default function CatPageWidgetSkeleton() {
  return (
    <div className="animate-pulse cursor-pointer relative w-full space-y-4 sm:space-y-5 flex flex-col items-center justify-center h-[310px] sm:h-[330px] rounded-lg">
      <div className="bg-gray-200 rounded-t-lg w-full h-[150px]"></div>
      <div className="w-full space-y-1">
        <div className="w-full h-7 bg-gray-200"></div>
        <div className="flex  w-full items-center justify-between">
          <div className="flex items-center space-x-1">
            <div className="bg-gray-200 h-6 w-[80px]"></div>
            <div className="bg-gray-200 h-6 w-[80px]"></div>
          </div>
          <div className="bg-gray-200 h-6 w-8 rounded-lg"></div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full">
        <div className="bg-gray-200  rounded-2xl h-[36px] w-[70%]"></div>
      </div>
    </div>
  );
}
