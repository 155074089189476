import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  decreaseSingleCartItems,
  increaseSingleCartItems,
  removeFromCart,
} from "../../Redux/Actions/ActionCreators";
import { RiDeleteBin6Line } from "react-icons/ri";

import { DiGitCompare } from "react-icons/di";
import { Link } from "react-router-dom";
import { removeWishlist } from "../../Utils/api";
import toast from "react-hot-toast";
import { FavouriteButton } from "../Composable/favouriteButton";
export default function OrderWidget({
  image,
  name,
  price,
  count,
  productid,
  data,
  seller,
  isSavedItem,
  wishlistid,
  refetch,
}) {
  const dispatch = useDispatch();
  const { cartItems, deliveryPrice } = useSelector((state) => state.cart);
  const { loggedInUser, token } = useSelector((state) => state.user);

  function incItem() {
    dispatch(increaseSingleCartItems(productid, cartItems));
  }

  function decItem() {
    dispatch(decreaseSingleCartItems(productid, cartItems));
  }

  function deleteItem() {
    dispatch(removeFromCart(productid, cartItems, deliveryPrice, data?.variantid));
  }

  async function removefromWishList() {
    const payload = {
      customerid: loggedInUser?.id || loggedInUser.customer?._id,
      itemid: data?._id,
      wishlistid,
    };
    await removeWishlist(token, payload)
      .then((res) => {
        refetch();
        toast.success("Item removed from wishlist");
      })
      .catch((err) => {});
  }


  /**
     <div
     onClick={deleteItem}
     className="cursor-pointer p-2 hover:bg-gray-200 rounded-full absolute top-2 right-3">
        <MdClose className="text-[22px]"/>
     </div>
   */
  return (
    <div className="relative w-full border my-2 sm:my-4 rounded-lg h-full">
      <div className="grid-cols-5  h-full grid  lg:grid-cols-5 xl:grid-cols-6 items-center gap-x-3">
        <div className=" col-span-2 w-full relative rounded-l-lg overflow-hidden h-full min-h-[144px] sm:min-h-[192px]">
          <img src={image} alt="" className="w-full h-full object-cover " />
          <button
            onClick={() => {
              if (isSavedItem) {
                removefromWishList();
              } else {
                deleteItem();
              }
            }}
            className="text-white absolute top-0 left-0 hover:bg-black/80 bg-black/40 p-2"
          >
            <RiDeleteBin6Line className="text-[22px]" />
          </button>
        </div>
        <div className="col-span-3 md:col-span-3  lg:col-span-3 xl:col-span-4 py-4 w-full flex flex-col items-start justify-start gap-y-2">
          <div className="w-full flex flex-col sm:flex-row sm:pr-4  items-start justify-start sm:justify-between gap-y-2">
            <p className="font-medium  sm:text-lg capitalize line-clamp-1 sm:line-clamp-2">{`${name}`}</p>
            <p className="font-semibold">{`₦${price?.toLocaleString()}`}</p>
          </div>
          <Link to={`/brands-detail?n=${seller}`} className="text-altinsmart">
            Visit Store
          </Link>
          {!isSavedItem && (
            <div className="flex items-center gap-x-3">
              <button
                onClick={decItem}
                className=" flex items-center justify-center text-altinsmart border border-altinsmart text-xl rounded-md h-6 w-6"
              >
                -
              </button>
              <span>{count}</span>
              <button
                onClick={incItem}
                className=" flex items-center justify-center text-altinsmart border border-altinsmart text-xl rounded-md h-6 w-6"
              >
                +
              </button>
            </div>
          )}

          <div className="flex items-center text-xs sm:text-sm gap-x-3">
            {!isSavedItem && (
             <FavouriteButton product={data} isCart={true}/>
            )}
            <button className="text-altinsmart border border-altinsmart px-2 justify-center h-9 rounded-lg flex items-center gap-x-2">
              <DiGitCompare className="hidden sm:block text-xl" />
              <p>Compare</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
