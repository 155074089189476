import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  MdClose,
  MdOutlineBrandingWatermark,
} from "react-icons/md";
import { LuLayoutDashboard } from "react-icons/lu";
import { AiOutlineHeart, AiOutlineWallet, AiOutlineUser } from "react-icons/ai";
import { TbFileDatabase } from "react-icons/tb";
import { BiMessageDetail, BiLogOutCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

export function UserNav({ close }) {
  const { loggedInUser, token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleLogout() {
    dispatch({ type: "LOGOUT" });
    navigate("/login");
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        close();
      }}
      className="fixed h-full w-full z-[60] min-[900px]:hidden bg-white bg-opacity-50  inset-0"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="absolute top-0 left-0 w-[220px] sm:w-[300px] overflow-y-auto h-full text-white bg-altinsmart let swipeInLeft shadow-md  pb-5"
      >
        <div onClick={close} className="absolute top-3 right-3">
          <MdClose className="text-[22px]" />
        </div>
        <div className="w-full relative mb-4 h-40 pb-2 border-b ">
          <div className="w-full inset-0- h-full absolute">
            <div className="w-full h-full  absolute inset-0 bg-black/50">
              <div className="flex items-center  m-auto justify-center h-full text-white font-medium gap-2">
                {!token ? (
                  <>
                    <Link to="login">Log in</Link>
                    <span>/</span>
                    <Link to="/register">Register</Link>
                  </>
                ) : (
                  <Link className="bg-black/30 rounded-md p-2" to="/user">
                    {loggedInUser?.name?.split(" ")[0] || loggedInUser.customer?.name}
                  </Link>
                )}
              </div>
            </div>
            <img
              src={`https://source.unsplash.com/1600x900/?smart,home,${Math.random()}`}
              alt="rand"
              className="w-full h-full object-cover"
            />
          </div>
        </div>

        <div className="flex w-full px-4 flex-col border-b pb-6 justify-start items-start">
          <IconsText
            icon={<LuLayoutDashboard className="text-xl" />}
            text={"All Categories"}
            link={"/categories"}
          />

          <IconsText
            icon={<MdOutlineBrandingWatermark className="text-xl" />}
            text={"All Brands"}
            link={"/brands"}
          />
        </div>

        <div
          className={`flex  px-4 flex-col w-full pb-6 border-b items-start justify-start ${
            token ? "block" : "hidden"
          }`}
        >
          <IconsText
            icon={<LuLayoutDashboard className="text-xl" />}
            text={"Dashboard"}
            link={"/user"}
          />
          <IconsText
            icon={<TbFileDatabase className="text-xl" />}
            text={"Purchase History"}
            link={"/user-purchase-history"}
          />
          <IconsText
            icon={<BiMessageDetail className="text-xl" />}
            text={"Product Queries"}
            link={"/user-my-queries"}
          />
          <IconsText
            icon={<AiOutlineHeart className="text-xl" />}
            text={"WishList"}
            link={"/cart"}
          />
          <IconsText
            icon={<AiOutlineWallet className="text-xl" />}
            text={"My Wallet"}
            link={"/user-my-wallet"}
          />
          <IconsText
            icon={<AiOutlineUser className="text-xl" />}
            text={"Manage Profile"}
            link={"/user-my-profile"}
          />
          <button
            onClick={handleLogout}
            className="w-full bg-white text-altinsmart  rounded-lg flex items-center gap-x-2 p-2"
          >
            <BiLogOutCircle className="text-[22px]" />
            <p>LogOut</p>
          </button>
        </div>
      </div>
    </div>
  );
}

function IconsText({ icon, text, link }) {
  const { pathname } = useLocation();
  return (
    <Link
      to={link}
      className={`${
        pathname === link ? "text-[#b847fe] bg-white " : ""
      } gap-x-2 flex my-1  w-full p-2 rounded-xl items-center`}
    >
      {icon}
      <p>{text}</p>
    </Link>
  );
}
