import React, { useEffect, useState } from "react";

import HeaderWidget from "../headerwidget/headerWidget";
import Layout from "../layout/layout";
import { getBrands } from "../../Utils/api";
import { Link } from "react-router-dom";
export default function Brands() {
  const [data, setData] = useState([])
  useEffect(() => {
    async function fetchData() {
      await getBrands()
        .then((res) => {
         // console.log(res.data);
          const { data} = res?.data
          setData(data)
        })
        .catch((err) => {
          //console.log(err);
        });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 
  return (
    <>
      <Layout>
        <div className="my-2 sm:my-4">
          <HeaderWidget
            title={"All Brands"}
            text={"Shop quality products from the best brands."}
          />
          <div className="grid w-full px-4 py-3 sm:px-6 sm:py-4 items-center justify-center gap-6 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4">
            {Array.isArray(data) && data.map(({logo, _id}, j) => {
              return (
                <Link to={`/brands-detail?n=${_id}`} key={j} className="mx-auto">
                  <img src={logo} alt="" />
                </Link>
              );
            })}
          </div>
        </div>
      </Layout>
    </>
  );
}
