import React, { useEffect, useState } from "react";
import topa from "../../../assets/png/topb.jpg";
import { useLocation } from "react-router-dom";

export default function AccountTopNav() {
  const [nav, setNav] = useState("");
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === "/user") {
      setNav("Dashboard");
    }
    if (pathname.includes("purchase-history")) {
      setNav("Purchase History");
    }
    if (pathname.includes("followed-shops")) {
      setNav("Followed Shops");
    }
    if (pathname.includes("refund-request")) {
      setNav("Refund Request");
    }
    if (pathname.includes("my-queries")) {
      setNav("My Queries");
    }
    if (pathname.includes("my-wishlist")) {
      setNav("My Wishlist");
    }
    if (pathname.includes("my-wallet")) {
      setNav("My Wallet");
    }
    if (pathname.includes("my-profile")) {
      setNav("My Profile");
    }
  }, [pathname]);

  return (
    <div className="w-full h-fit ">
      <div className=" hidden md:block w-full h-[100px]">
        <img src={topa} alt="" className="w-full h-full object-cover" />
      </div>
      <p className="px-2 py-3 border-b sm:px-3 sm:py-4 text-base sm:text-lg font-semibold">
        {nav}
      </p>
    </div>
  );
}
